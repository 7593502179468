//企業管理者ページ
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import config from '../../Config/key';
import {
  Table,
  Divider,
  Tag,
  Button,
  message,
  Typography,
  Modal,
  Select,
  Space,
  Spin,
} from 'antd';
import { userRole } from '../../Config/userRole';
import { useSelector } from 'react-redux';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { surveyCode } from '../../Config/surveyCode';
import { surveyStatus } from '../../Config/surveyStatus';
const { Column, ColumnGroup } = Table;
const ExcelJS = require('exceljs');
// const { confirm } = Modal;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
function CorpMgrPage() {
  const [Users, setUsers] = useState([]);
  const [tUsers, setTUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAnswerd, setIsAnswerd] = useState(9);
  // const [user, setUser] = useState([]);
  const [userToken, setUserToken] = useState([]);
  const [allToken, setAllToken] = useState([]);
  // const [downloadToken, setDownloadToken] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  const [surveySeason, setSurveySeason] = useState([]);
  const [selSurveySeason, setSelSurveySeason] = useState([]);
  // const [selGroupInfo, setSelGroupInfo] = useState([]); //グループ名とグループコード
  const [role360, setRole360] = useState(1); //選択した360度関係：１：本人、２：上司、３：部下・同僚
  const [groupSeason, setGroupSeason] = useState([]);
  const [questionSetFilter, setQuestionSetFilter] = useState([]); //全ての設問セット
  const [selQuestionSetFilterId, setSelQuestionSetFilterId] = useState(''); //選択した設問セットID
  const [selSurveySeasonIdFilter, setSelSurveySeasonIdFilter] = useState(''); //選択したサーベイID
  const usr = useSelector((state) => state.user);
  // let selGroupInfo = {};
  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      const [surveyInfo, res, seasonInfo, res2] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get(`${config.USER_SERVER}/group/${usr.userData.groupCode}`), //user全体
        Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        Axios.get(`/api/surveyToken/g/${usr.userData.groupCode}`), //グループの全てのトークン情報
        // Axios.get(`/api/group/${usr.userData.groupCode}`), //ログインユーザのグループ情報を取得
      ]);
      if (!surveyInfo.data.survey) throw 'サーベイ取得に失敗しました。';
      if (!seasonInfo.data.surveySeason)
        throw 'サーベイシーズン取得に失敗しました。';
      // console.log({ seasonInfo });
      setSurvey(surveyInfo.data.survey);
      setSurveySeason(seasonInfo.data.surveySeason);
      // setSelGroupInfo(groupInfo.data.group.survey_season);
      setGroupSeason(
        seasonInfo.data.surveySeason.filter(
          (item) => item.group_code === usr.userData.groupCode
        )
      );
      // selGroupInfo = groupInfo.data.group;
      if (res2.data.success) {
        // console.log(res2);
        setAllToken(res2.data.tokenInfo);
        // setDownloadToken(
        //   res2.data.tokenInfo.filter(function (tokenInfo) {
        //     return (
        //       tokenInfo.survey_status === 5 && tokenInfo.survey_code === '10010'
        //     ); //実施済み：5
        //   })
        // );
      }
      let userData = res.data.users;
      // console.log({ res });
      if (res.data.success) {
        await userData.map((user) => {
          user.fullName = user.lastname + ' ' + user.name;
          user.roleText = userRole[user.role];
          // console.log(user.survey_token);
          if (user.survey_token.length === 0) {
            user.takeTokenCnt = 0;
            user.usedTokenCnt = 0;
            user.surveyProgress = 0;
            // console.log('here');
          } else {
            user.takeTokenCnt = user.survey_token.length;
            user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
              return tokenInfo.survey_status === 5; //実施済み：5
            }).length;
            user.surveyProgress = parseInt(
              (user.usedTokenCnt / user.takeTokenCnt) * 100
            );
          }
          return user;
        });
        // console.log(userData);
        setUsers(userData);
        setTUsers(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  //選択したユーザのPDFリストのモーダルを表示
  const viewTicketHandler = async (user, e) => {
    e.preventDefault();
    // console.log({ user });
    // console.log({ survey });
    try {
      // console.log({ usedToken });
      // const survey_status = 5;
      // const res = await Axios.get(
      //   // `/api/surveyToken/s/${userId}/${survey_status}`
      //   `${config.USER_SERVER}/${userId}`
      // );
      // console.log(res);
      // if (res.data.success) {
      // await readDB();
      // message.success('論理削除しました。');
      // setUser(res.data.user);
      let ut = [];
      allToken.forEach((element) => {
        if (element.user === user._id) {
          let element2 = element;
          element2.groupCode = element.group_code;
          // element2.surveyName = surveyCode[element.survey_code];
          element2.surveyStatusName = surveyStatus[element.survey_status];
          //実施済みの時だけ日付を表示
          if (element.survey_status === 5) {
            element2.update = ymdhms(element.updatedAt);
          } else {
            element2.update = '-';
          }
          let surveyName = '';

          //シーズンIDが存在するならシーズン名を表示
          if (element.survey_season_id) {
            let surveySeasonInfo = surveySeason.find(
              (item) => item._id === element.survey_season_id
            );
            surveyName = surveySeasonInfo.season_name;
          } else {
            surveyName = surveyCode[element.survey_code];
          }
          //Question Set Idが存在するならQuestionSet名を表示
          if (element.question_set_id) {
            let surveyInfo = survey.find(
              (item) => item._id === element.survey_id
            );
            // console.log({ surveyInfo });
            surveyName =
              surveyName +
              ' : ' +
              surveyInfo.question_set.find(
                (item) => item._id === element.question_set_id
              ).set_name;
          }

          // if (element.question_set_id) {
          //   let surveyInfo = survey.find(
          //     (item) => item._id === element.survey_id
          //   );
          //   // console.log({ surveyInfo });
          //   element2.surveyName =
          //     surveyCode[element.survey_code] +
          //     '(' +
          //     surveyInfo.question_set.find(
          //       (item) => item._id === element.question_set_id
          //     ).set_name +
          //     ')';
          // } else {
          //   element2.surveyName = surveyCode[element.survey_code];
          // }
          element2.surveyName = surveyName;
          ut.push(element2);
        }
      });
      setUserToken(ut);
      setViewModal(true);
      // }
    } catch (error) {
      alert('サーベイ情報が読み込めませんでした。');
      console.log(error);
    }
  };

  const viewSurveyResultHandler = async (tokenInfo, e) => {
    e.preventDefault();
    // console.log({ tokenInfo });
    try {
      let url = '';
      if (tokenInfo.groupCode) {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${tokenInfo.groupCode}/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      } else {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      }
      window.open(url, '_blank');
    } catch (error) {
      alert('サーベイ結果確認ができません。');
      console.log(error);
    }
  };

  const handleOk = (e) => {
    // console.log(e);
    setViewModal(false);
  };

  const handleCancel = (e) => {
    // console.log(e);
    setViewModal(false);
  };

  const onChangeSeasonFilter = async (value) => {
    // console.log(`selected ${value}`);
    setIsAnswerd(9);
    setSelSurveySeasonIdFilter(value);
    setSelQuestionSetFilterId('');
    setQuestionSetFilter([]);
    const selSeasonInfo = surveySeason.find((item) => item._id === value);
    setSelSurveySeason(selSeasonInfo);
    const arrQuestionSet = selSeasonInfo.question_set;
    setQuestionSetFilter(arrQuestionSet);
    const gUserArr = [];
    if (selSeasonInfo.survey_code === '40010') {
      //360度診断の場合
      // console.log({ selSeasonInfo });
      selSeasonInfo.relation_360.map((element) => {
        // userIdArr.push(element.user_id);
        // bossArr.push(element.boss);
        // partnerArr.push(element.partner);
        gUserArr.push(tUsers.find((item) => item._id === element.user_id)); //本人のみをFilter
        // console.log({ gUserArr });
      });
      setUsers(computCnt(gUserArr, value));
    } else {
      for (let i = 0; i < tUsers.length; i++) {
        const element = tUsers[i];
        let token = {};
        if (element.survey_token) {
          token = element.survey_token.find(
            (item) => item.survey_season_id === value
          );
        }
        if (token) {
          gUserArr.push(element);
        }
      }
      setUsers(computCnt(gUserArr, value));
      // console.log({ value });
      // console.log({ selSeasonInfo });
      // console.log({ gUserArr });
    }
  };

  //設問セットを変更した場合
  const onChangeQuestionFilter = (value) => {
    setSelQuestionSetFilterId(value); //選択した設問セットIDを格納
    // const selQuestionSet = questionSetFilter.find((item) => item._id === value);
    // setSelQuestionSetFilter(selQuestionSet); //現在選択したQuestionSet
    // console.log({ questionSetFilter });

    setIsAnswerd(9);
    // console.log({ gUsers });
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        // if (isAnswerd === 9) {
        token = element.survey_token.find(
          (item) =>
            item.question_set_id === value &&
            item.survey_season_id === selSurveySeasonIdFilter
        );
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setUsers(gUserArr);
  };

  //実施有無
  const onChangeAnswer = (value) => {
    // console.log({ value });
    setIsAnswerd(value);
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        if (value === 9) {
          if (selQuestionSetFilterId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetFilterId &&
                item.survey_season_id === selSurveySeasonIdFilter
            );
          } else {
            token = element.survey_token.find(
              (item) => item.survey_season_id === selSurveySeasonIdFilter
            );
          }
        } else {
          if (selQuestionSetFilterId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetFilterId &&
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          } else {
            token = element.survey_token.find(
              (item) =>
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          }
        }
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setUsers(gUserArr);
  };

  const computCnt = (gUserArr, value) => {
    gUserArr.map((user) => {
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.filter(
          (tokenInfo) => tokenInfo.survey_season_id === value
        ).length;
        user.usedTokenCnt = user.survey_token.filter(
          (tokenInfo) =>
            tokenInfo.survey_season_id === value &&
            tokenInfo.survey_status === 5
          //実施済み：5
        ).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    return gUserArr;
  };
  // const downloadPdfHandler = async () => {
  //   try {
  //     // console.log({ usr });
  //     //zipのFileStreamを持ってくる
  //     const res = await Axios.get(
  //       `/api/downloadZipReport/${usr.userData.groupCode}`,
  //       {
  //         responseType: 'arraybuffer',
  //         headers: { Accept: 'application/zip' },
  //       }
  //     );
  //     // console.log({ res });
  //     //持ってきたzipのFileStreamをblobに格納し仮想のリンクでブラウザdownloadさせる
  //     if (res.data) {
  //       const blob = new Blob([res.data], { type: 'application/zip' });
  //       const fileDownloadUrl = URL.createObjectURL(blob);
  //       // console.log(fileDownloadUrl);
  //       let tempLink = document.createElement('a');
  //       tempLink.href = fileDownloadUrl;
  //       tempLink.download = 'Recurrent_Survey_Report.zip';
  //       tempLink.click();
  //     }
  //     return true;
  //   } catch (error) {
  //     console.log(error);
  //     return false;
  //   }
  // };

  // const showConfirm = async () => {
  //   confirm({
  //     title: 'サーベイ結果をダウンロードしますか？',
  //     content: 'サーベイ結果がZIP形式ファイルでダウンロードされます。',
  //     async onOk() {
  //       const result = await downloadPdfHandler();
  //       if (result) {
  //         message.success('ダウンロード完了しました。');
  //       } else {
  //         console.log('サーベイ結果ダウンロードで問題が発生しました。');
  //         message.success('サーベイ結果ダウンロードで問題が発生しました。');
  //       }
  //       // return new Promise((resolve, reject) => {
  //       //   // setTimeout(Math.random() > 0 ? resolve : reject, 3000);
  //       //   const result = downloadPdfHandler();
  //       //   if (result) {
  //       //     resolve();
  //       //   } else {
  //       //     reject();
  //       //   }
  //       // }).catch(() =>
  //       //   console.log('サーベイ結果ダウンロードで問題が発生しました。')
  //       // );
  //     },
  //     async onCancel() {},
  //   });
  // };

  const showConfirm = async (tokenI, type) => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        tokenI.type = type; //1:個人用、2:共有用, 30010:E3-Survey
        let result = {};
        if (type === 30010) {
          result = await Axios.post(
            '/api/dbModel100/makeReport30010Personal',
            tokenI
          );
        }
        // console.log({ result });
        if (result.data.success) {
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  const csvDownloadHandler = async (event) => {
    event.preventDefault();
    // console.log({ gUsers });
    try {
      message.loading({
        content: 'データ作成中...',
        key: 'makecsv',
        duration: 20,
      });
      const tempData = {};
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      let columns = [
        { header: 'ID', key: 'user_id' },
        { header: '会社名', key: 'company' },
        { header: '部署名', key: 'department' },
        { header: 'eメール', key: 'email' },
        { header: '名前', key: 'fullname' },
        { header: '社員番号', key: 'empNo' },
        { header: 'ロール', key: 'roletext' },
      ];
      worksheet.columns = columns;
      for (let i = 0; i < Users.length; i++) {
        const element = Users[i];
        tempData.user_id = element._id;
        tempData.company = element.company;
        tempData.department = element.department;
        tempData.email = element.email;
        tempData.fullname = element.fullName;
        tempData.empNo = element.emp_no;
        tempData.roletext = element.roleText;

        worksheet.addRow(tempData);
      }
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (selQuestionSetFilterId) {
        let selQuestionSetInfo = questionSetFilter.find(
          (item) => item._id === selQuestionSetFilterId
        );
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeason.season_name}_${selQuestionSetInfo.set_name}_${isAnswerd}.xlsx`;
      } else {
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeason.season_name}_${isAnswerd}.xlsx`;
      }
      a.click();
      // ダウンロード後は不要なのでaタグを除去
      a.remove();
      // console.log({ excelData });
      // console.log(excelData.length);
      message.success({
        content: 'ダウンロード完了！',
        key: 'makecsv',
        duration: 2,
      });
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'ダウンロード失敗！',
        key: 'makecsv',
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          ■絞り込み｜サーベイシーズン選択：
          <Select
            showSearch
            style={{ marginLeft: 5, marginRight: 5, width: 400 }}
            placeholder="シーズン選択"
            optionFilterProp="items"
            onChange={onChangeSeasonFilter}
            value={selSurveySeasonIdFilter}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {groupSeason.map((element) => {
              return (
                <Option value={element._id} key={element._id}>
                  {element.season_name}
                </Option>
              );
            })}
          </Select>
          {questionSetFilter.length > 0 && (
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 200,
              }}
              placeholder="設問セット選択"
              optionFilterProp="items"
              onChange={onChangeQuestionFilter}
              value={selQuestionSetFilterId}
              defaultValue={questionSetFilter[0].set_name}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {questionSetFilter.map((element) => {
                return (
                  <Option value={element._id} key={element._id}>
                    {element.set_name}
                  </Option>
                );
              })}
            </Select>
          )}
          <Fragment>
            実施選択：
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 100,
              }}
              placeholder="対象選択"
              optionFilterProp="items"
              onChange={onChangeAnswer}
              value={isAnswerd}
              disabled={!selSurveySeasonIdFilter}
            >
              <Option value={9} key={9}>
                全員
              </Option>
              <Option value={0} key={0}>
                未回答
              </Option>
              <Option value={5} key={5}>
                回答済み
              </Option>
            </Select>
          </Fragment>
          <Button
            type="primary"
            onClick={csvDownloadHandler}
            disabled={!selSurveySeasonIdFilter}
          >
            CSVダウンロード
          </Button>{' '}
          <Fragment>対象者：{Users.length}名</Fragment>
        </div>
        <Table dataSource={Users} size="middle" rowKey="email">
          <Column
            title="名前"
            dataIndex="fullName"
            key="fullName"
            aligh="right"
          />
          <Column title="メール" dataIndex="email" key="email" aligh="center" />
          <Column
            title="会社"
            dataIndex="company"
            key="company"
            aligh="center"
          />
          <Column
            title="部署"
            dataIndex="department"
            key="department"
            aligh="center"
          />

          <Column
            title="チケット発行"
            key="action"
            render={(text, record) => (
              <span>
                {record.takeTokenCnt !== 0 &&
                record.takeTokenCnt === record.usedTokenCnt ? (
                  <Text mark>
                    {'チケット発行数：' +
                      record.takeTokenCnt +
                      ' | 実施済：' +
                      record.usedTokenCnt}
                  </Text>
                ) : record.takeTokenCnt !== 0 ? (
                  <Text type="danger">
                    {' チケット発行数：' +
                      record.takeTokenCnt +
                      ' | 実施済：' +
                      record.usedTokenCnt}
                  </Text>
                ) : (
                  <Text>
                    {' チケット発行数：' +
                      record.takeTokenCnt +
                      ' | 実施済：' +
                      record.usedTokenCnt}
                  </Text>
                )}
              </span>
            )}
          />
          <Column
            title="進捗率"
            key="ticket"
            render={(text, record) => (
              <span>
                <Text>{record.surveyProgress.toFixed(1) + '%'}</Text>
              </span>
            )}
          />
          <Column
            title="サーベイチケット確認"
            key="viewPdf"
            render={(text, record) => (
              <span>
                <Button
                  type="primary"
                  size="small"
                  onClick={(e) => viewTicketHandler(record, e)}
                  disabled={
                    record.role === 0 || record.takeTokenCnt < 1 ? true : false
                  }
                >
                  チケット詳細
                </Button>
              </span>
            )}
          />
        </Table>

        {/* <Button
          type="primary"
          size="small"
          onClick={showConfirm}
          disabled={downloadToken.length < 1 ? true : false}
        >
          結果PDFファイル一括ダウンロード({downloadToken.length}件)
        </Button> */}
      </div>

      <div>
        <Modal
          title="サーベイチケット確認"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={900}
        >
          <div style={{ width: '100%', margin: '0rem auto' }}>
            <Table dataSource={userToken} size="middle" rowKey="_id">
              <Column
                title="サーベイ名"
                dataIndex="surveyName"
                key="surveyName"
                aligh="right"
              />
              <Column
                title="状態"
                dataIndex="surveyStatusName"
                key="surveyStatusName"
                aligh="center"
              />
              <Column
                title="サーベイ実施日"
                dataIndex="update"
                key="update"
                aligh="center"
              />
              <Column
                title="結果確認"
                key="action"
                render={(text, record) => (
                  <span>
                    {record.survey_code === '10010' ||
                    record.survey_code === '90101' ||
                    record.survey_code === '50010' ||
                    record.survey_code === '90501' ||
                    record.survey_code === '60010' ||
                    record.survey_code === '90601' ? (
                      <Button
                        type={'primary'}
                        size="small"
                        onClick={(e) => viewSurveyResultHandler(record, e)}
                        disabled={record.survey_status === 5 ? false : true}
                      >
                        サーベイ結果確認 &nbsp;{' '}
                        <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                      </Button>
                    ) : (record.survey_code === '30010' ||
                        record.survey_code === '90301') &&
                      record.e3_option ? ( //E3-Survey単体orコース　 + パーソナル診断Optionあり
                      <Space>
                        <Button
                          type={'primary'}
                          size="small"
                          onClick={(e) => showConfirm(record, 30010)}
                          disabled={record.survey_status === 5 ? false : true}
                        >
                          サーベイ結果確認 &nbsp;{' '}
                          <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                        </Button>
                      </Space>
                    ) : (
                      <Button type={'primary'} size="small" disabled={true}>
                        個別結果はありません
                      </Button>
                    )}
                  </span>
                )}
              />
            </Table>
          </div>
        </Modal>
        <Modal
          title="サーベイ結果ファイル生成中..."
          open={isModalVisible}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: true }}
          bodyStyle={{ textAlign: 'center' }}
        >
          <Spin size="large" />
        </Modal>
      </div>
    </Fragment>
  );
}

export default CorpMgrPage;
