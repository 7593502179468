//モチベーション・トレンド診断PDF一括ダウンロード
import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Button, Form, message, Select, Modal } from 'antd';
import Axios from 'axios';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { confirm } = Modal;
const { Title, Text } = Typography;

const PDF60010DowlnoadPage = () => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  // const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  // const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  // const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [selTokenInfo, setSelTokenInfo] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const usr = useSelector((state) => state.user);

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      setUserInfo(usr.userData);
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);

      setSurvey(surveyInfo.data.survey);
      setGroups(groupInfo.data.groups);

      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      if (usr.userData.role === 3) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        // setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(
          sgi.survey_season.filter(
            (item) =>
              item.survey_code === '60010' || item.survey_code === '90601'
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log({ sgi });
    setSelGroupCode(value);
    // setSelGroupInfo(sgi);
    setGroupSurveySeasonInfo(
      sgi.survey_season.filter(
        (item) => item.survey_code === '60010' || item.survey_code === '90601'
      )
    );
    setSelSurveySeasonId('');
    setSelTokenInfo([]);
  };

  const onChangeSurveySeason = async (value) => {
    // const sssi = groupSurveySeasonInfo.find((item) => item._id === value);
    setSelSurveySeasonId(value);
    // setSelSurveySeasonInfo(sssi);
    // console.log({ sssi });
    // const ssi = survey.find((item) => item._id === sssi.survey_id);
    // console.log({ ssi });
    // setSelSurveyInfo(ssi);
    const res = await Axios.get(
      `/api/surveyToken/g/${selGroupCode}/s/${value}`
    );
    if (res) {
      // console.log(res.data.tokenInfo);
      setSelTokenInfo(res.data.tokenInfo);
    }
  };

  const downloadPdfHandler = async () => {
    try {
      // console.log({ usr });
      //zipのFileStreamを持ってくる
      const res = await Axios.get(
        `/api/downloadZipReport/${selGroupCode}/${selSurveySeasonId}`,
        {
          responseType: 'arraybuffer',
          headers: { Accept: 'application/zip' },
        }
      );
      // console.log({ res });
      //持ってきたzipのFileStreamをblobに格納し仮想のリンクでブラウザdownloadさせる
      if (res.data) {
        const blob = new Blob([res.data], { type: 'application/zip' });
        const fileDownloadUrl = URL.createObjectURL(blob);
        // console.log(fileDownloadUrl);
        let tempLink = document.createElement('a');
        tempLink.href = fileDownloadUrl;
        tempLink.download = 'Recurrent_Survey_Report.zip';
        tempLink.click();
        tempLink.remove();
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const showConfirm = async () => {
    confirm({
      title: 'サーベイ結果をダウンロードしますか？',
      content: 'サーベイ結果がZIP形式ファイルでダウンロードされます。',
      async onOk() {
        const result = await downloadPdfHandler();
        if (result) {
          message.success('ダウンロード完了しました。');
        } else {
          console.log('サーベイ結果ダウンロードで問題が発生しました。');
          message.success('サーベイ結果ダウンロードで問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '800px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>フォロワーシップ診断結果一括ダウンロード</Title>
        </div>
        <Form onSubmit={showConfirm}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={userInfo.role === 3}
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイシーズンを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button
            type="primary"
            size="large"
            onClick={showConfirm}
            disabled={
              selTokenInfo.length < 1 ? true : false || !selSurveySeasonId
            }
          >
            ダウンロード({selTokenInfo.length}件)
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default PDF60010DowlnoadPage;
