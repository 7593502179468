import React, { Fragment, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { UserContext } from '../TakeSurveyPage';
import { Card, Typography, Button, message, Modal } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import Axios from 'axios';

const { Title } = Typography;
const { Text } = Typography;
const { confirm } = Modal;

function ContentsPage3(props) {
  // console.log(props);
  // const history = useHistory();
  const { answer, setAnswer } = useContext(UserContext);
  const { tokenInfo } = props;
  const { studyStartDate } = props;

  let ansLen = Object.keys(answer).length;
  // console.log({ ansLen });

  const ansSendHandler = async () => {
    try {
      // console.log({ tokenInfo });
      let ansArr = [];
      for (let i = 1; i < ansLen + 1; i++) {
        ansArr.push(answer[i]);
      }
      const data = {
        user: tokenInfo.user._id,
        survey_token: tokenInfo._id,
        study_end_date: Date.now(),
        study_start_date: studyStartDate,
        answers: ansArr,
      };
      // console.log({ data });
      const res1 = await Axios.post('/api/dbModel100', data);

      // console.log({ res1 });
      if (res1.data.success) {
        const data2 = {
          tokenInfo: tokenInfo,
          answers: ansArr,
        };
        const res2 = await Axios.post('/api/dbModel100/makeReport', data2);
        if (res2.data.success) {
          const [res3, res4] = await Promise.all([
            Axios.patch(`/api/surveyToken/${tokenInfo._id}/status`, {
              survey_status: 5,
              answer_id: res1.data.dbModel100._id,
            }),
            Axios.patch(`/api/dbModel100/${tokenInfo._id}/reportDate`), //なぜかアップデートされない→確認が必要
          ]);

          if (res3.data.success && res4.data.success) {
            // message.success('回答を提出しました。マイ・サーベイに戻ります。');
            // setTimeout(() => {
            //   props.props.history.push('/mysurvey');
            // }, 3000);
            return true;
          } else {
            return false;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showConfirm = async () => {
    confirm({
      title: 'サーベイ結果を最終提出しますか？',
      content:
        'サーベイ結果の提出処理が完了し、マイ・サーベイページへ自動遷移されるまで、そのままお待ちください。',
      async onOk() {
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0 ? resolve : reject, 3000);
        //   ansSendHandler();
        // }).catch(() => console.log('サーベイ結果提出でエラーが発生しました。'));
        const result = await ansSendHandler();
        if (result) {
          message.success('回答を提出しました。マイ・サーベイに戻ります。');
          props.props.history.push('/mysurvey');
        } else {
          console.log('サーベイ結果提出で問題が発生しました。');
          message.success('サーベイ結果提出で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Card
        title="回答提出案内"
        style={{
          margin: '0px',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        headStyle={{
          color: 'white',
          backgroundColor: 'rgba(83, 131, 236, 1)',
          border: 1,
        }}
      >
        <Title level={4}>診断の回答を提出してください。</Title>
        <Text>
          診断の設問に全て回答いただいた方は下記のボタンを押して結果を提出してください。
        </Text>
        <br />
        <br />
        <br />
        {ansLen > 79 ? (
          <Fragment>
            <Text>全ての設問に回答が出来ましたので提出が可能です。→ </Text>

            <Button
              type="primary"
              shape="round"
              icon={<CloudUploadOutlined />}
              size="large"
              onClick={showConfirm}
            >
              回答提出
            </Button>
          </Fragment>
        ) : (
          <Text type="danger" strong>
            全ての設問に回答してください。
            <br />
            全80問中 - 回答中：{ansLen}問、未回答：{80 - ansLen}問
          </Text>
        )}
        <br />
        <br />
      </Card>
      <br />
      <br />
      <Card
        title="結果確認案内"
        style={{
          margin: '0px',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        headStyle={{
          color: 'white',
          backgroundColor: 'rgba(83, 131, 236, 1)',
          border: 1,
        }}
      >
        <Title level={4}>診断結果を確認してください。</Title>
        <Text>
          診断の結果がの提出が完了した方は「マイ・サーベイ」メニューから結果を確認することができます。
        </Text>
      </Card>
    </div>
  );
}

export default ContentsPage3;
