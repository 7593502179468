import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';

const { TextArea } = Input;
const { Title, Text } = Typography;

function CSVUploadPage(props) {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [projectKeyValue, setProjectKeyValue] = useState('');

  const onDrop = (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          const courseInfo = courseMerge(results.data);
          setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const courseMerge = (fileJSON) => {
    let courseInfos = []; //単純分類用
    fileJSON.map((record) => {
      // courseを分離して配列を作る
      courseInfos.push({
        course_id: record.course_id,
        course_name: record.course_name,
        lesson_id: record.lesson_id,
        lesson_name: record.lesson_name,
        chapter_id: record.chapter_id,
        chapter_name: record.chapter_name,
      });
    });

    //course_idを基準に重複をなくす
    const courseInfo = [
      ...new Map(courseInfos.map((info) => [info.course_id, info])).values(),
    ];

    //上位の１個だけをReturn
    return courseInfo[0];
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let evaluationPower = []; //最終分類用
    try {
      const res = await Axios.post('/api/projectKey', {
        project_key: projectKeyValue,
        project_description: projectDescriptionValue,
        user_cnt: fileJSON.length,
      });
      // console.log({ res });

      if (res.data.success) {
        console.log({ res });
        await fileJSON.map((record) => {
          evaluationPower.push({
            project_id: res.data.projectKey._id,
            course_id: record.course_id,
            course_name: record.course_name,
            lesson_id: record.lesson_id,
            lesson_name: record.lesson_name,
            chapter_id: record.chapter_id,
            chapter_name: record.chapter_name,
            login_id: record.login_id,
            user_name: record.user_name,
            user_department: record.Q081_answer,
            study_session: record.study_session,
            study_status: record.study_status,
            study_start_date: record.study_start_date,
            study_end_date: record.study_end_date,
            answers: [
              record.Q001_answer,
              record.Q002_answer,
              record.Q003_answer,
              record.Q004_answer,
              record.Q005_answer,
              record.Q006_answer,
              record.Q007_answer,
              record.Q008_answer,
              record.Q009_answer,
              record.Q010_answer,
              record.Q011_answer,
              record.Q012_answer,
              record.Q013_answer,
              record.Q014_answer,
              record.Q015_answer,
              record.Q016_answer,
              record.Q017_answer,
              record.Q018_answer,
              record.Q019_answer,
              record.Q020_answer,
              record.Q021_answer,
              record.Q022_answer,
              record.Q023_answer,
              record.Q024_answer,
              record.Q025_answer,
              record.Q026_answer,
              record.Q027_answer,
              record.Q028_answer,
              record.Q029_answer,
              record.Q030_answer,
              record.Q031_answer,
              record.Q032_answer,
              record.Q033_answer,
              record.Q034_answer,
              record.Q035_answer,
              record.Q036_answer,
              record.Q037_answer,
              record.Q038_answer,
              record.Q039_answer,
              record.Q040_answer,
              record.Q041_answer,
              record.Q042_answer,
              record.Q043_answer,
              record.Q044_answer,
              record.Q045_answer,
              record.Q046_answer,
              record.Q047_answer,
              record.Q048_answer,
              record.Q049_answer,
              record.Q050_answer,
              record.Q051_answer,
              record.Q052_answer,
              record.Q053_answer,
              record.Q054_answer,
              record.Q055_answer,
              record.Q056_answer,
              record.Q057_answer,
              record.Q058_answer,
              record.Q059_answer,
              record.Q060_answer,
              record.Q061_answer,
              record.Q062_answer,
              record.Q063_answer,
              record.Q064_answer,
              record.Q065_answer,
              record.Q066_answer,
              record.Q067_answer,
              record.Q068_answer,
              record.Q069_answer,
              record.Q070_answer,
              record.Q071_answer,
              record.Q072_answer,
              record.Q073_answer,
              record.Q074_answer,
              record.Q075_answer,
              record.Q076_answer,
              record.Q077_answer,
              record.Q078_answer,
              record.Q079_answer,
              record.Q080_answer,
            ],
          });
        });
        console.log({ evaluationPower });
        const res3 = await Axios.post('/api/evaluationPower', evaluationPower);
        console.log({ res3 });
        if (res3.data.success) {
          setFileData('CSVアップロードが完了しました。');
          message.success('CSVのアップロードが完了しました。');
          setTimeout(() => {
            props.history.push('/');
          }, 3000);
        }
      }
    } catch (error) {
      console.log({ error });
      /*
      ＜課題＞
      projectKeyがUniqueでない場合の処理を追加
      MongoDB error code 11000 
      */
    }
  };

  const onChangeProjectKey = (e) => {
    setProjectKeyValue(e.currentTarget.value);
  };
  const onChangeProjectDescription = (e) => {
    setProjectDescriptionValue(e.currentTarget.value);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>評価力診断結果CSV アップロード</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>プロジェクトキーを入力してください（必須）</Text>
            <Input
              placeholder="一意になるプロジェクトキーを入力してください。"
              allowClear
              onChange={onChangeProjectKey}
              value={projectKeyValue}
            />
            <br />
            <br />
            <Text strong>プロジェクト説明を入力してください（任意）</Text>
            <Input
              placeholder="プロジェクトの説明を入力してください。"
              allowClear
              onChange={onChangeProjectDescription}
              value={projectDescriptionValue}
            />
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button type="primary" size="large" onClick={onSubmit}>
            アップロード
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
}

export default CSVUploadPage;
