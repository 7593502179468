//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function CSVUserUploadPage(props) {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [groups, setGroups] = useState([]); //グループ名とグループコード

  let department = [];
  let position = [];
  let empType = [];
  let levelCode = [];
  let goOut = [];

  // const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onDrop = async (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          //const courseInfo = courseMerge(results.data);
          // setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          // console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
    const group = await Axios.get('/api/group/');
    setGroups(group.data.groups);
    // console.log(group.data.groups);
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const ymd = (tdate) => {
    let date = new Date(tdate);

    // console.log({ tdate });
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':' +
      ('0' + date.getSeconds()).slice(-2) +
      '.000Z'
      // date.getMilliseconds()
    );
  };

  // const courseMerge = (fileJSON) => {
  //   let courseInfos = []; //単純分類用
  //   fileJSON.map((record) => {
  //     // courseを分離して配列を作る
  //     courseInfos.push({
  //       course_id: record.course_id,
  //       course_name: record.course_name,
  //       lesson_id: record.lesson_id,
  //       lesson_name: record.lesson_name,
  //       chapter_id: record.chapter_id,
  //       chapter_name: record.chapter_name,
  //     });
  //   });

  //   //course_idを基準に重複をなくす
  //   const courseInfo = [
  //     ...new Map(courseInfos.map((info) => [info.course_id, info])).values(),
  //   ];

  //   //上位の１個だけをReturn
  //   return courseInfo[0];
  // };

  const onSubmit = async (event) => {
    event.preventDefault();
    let userData = []; //最終分類用
    const today = new Date(Date.now());
    try {
      message.loading({
        content: 'CSVアップロード中...',
        key: 'csvupload',
        duration: 20,
      });
      // const res = await Axios.post('/api/projectKey', {
      //   project_key: projectKeyValue,
      //   project_description: projectDescriptionValue,
      //   user_cnt: fileJSON.length,
      // });
      // console.log({ res });

      // if (res.data.success) {
      // console.log({ fileJSON });
      if (!groupCode) {
        message.error({
          content: '企業コードを入力してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('企業コードがありません。');
      }
      const res = await Axios.get(`/api/group/${groupCode}`);
      if (res.data.success) {
        // console.log(res.data.group);
        if (res.data.group.department) {
          department = res.data.group.department;
        }
        if (res.data.group.position) {
          position = res.data.group.position;
        }
        if (res.data.group.emp_type) {
          empType = res.data.group.emp_type;
        }
        if (res.data.group.level_code) {
          levelCode = res.data.group.level_code;
        }
        if (res.data.group.go_out) {
          goOut = res.data.group.go_out;
        }
      } else {
        message.error({
          content: '企業コードを確認してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('企業情報の取得ができませんでした。');
      }
      await fileJSON.map((record) => {
        // let pwhash;
        // bcrypt.genSalt(saltRounds, function (err, salt) {
        //   bcrypt.hash(record.password, salt, function (err, hash) {
        //     // Store hash in your password DB.
        //     pwhash = hash;
        //   });
        // });
        // let dp = department.find((item) => item === record.department);
        // console.log({ dp });
        let dpString = '';
        if (record.department) {
          dpString = record.department;
          if (!department.find((item) => item === dpString)) {
            // setDepartment([...department, record.department]);
            department.push(dpString);
            // console.log(`dpString:${dpString}`);
          }
          if (record.department2) {
            dpString = record.department + '／' + record.department2;
            if (!department.find((item) => item === dpString)) {
              // setDepartment([...department, record.department]);
              department.push(dpString);
              // console.log(`dpString:${dpString}`);
            }
            if (record.department3) {
              dpString =
                record.department +
                '／' +
                record.department2 +
                '／' +
                record.department3;
              if (!department.find((item) => item === dpString)) {
                // setDepartment([...department, record.department]);
                department.push(dpString);
                // console.log(`dpString:${dpString}`);
              }
              if (record.department4) {
                dpString =
                  record.department +
                  '／' +
                  record.department2 +
                  '／' +
                  record.department3 +
                  '／' +
                  record.department4;
                if (!department.find((item) => item === dpString)) {
                  // setDepartment([...department, record.department]);
                  department.push(dpString);
                  // console.log(`dpString:${dpString}`);
                }
                if (record.department5) {
                  dpString =
                    record.department +
                    '／' +
                    record.department2 +
                    '／' +
                    record.department3 +
                    '／' +
                    record.department4 +
                    '／' +
                    record.department5;
                  if (!department.find((item) => item === dpString)) {
                    // setDepartment([...department, record.department]);
                    department.push(dpString);
                    // console.log(`dpString:${dpString}`);
                  }
                }
              }
            }
          }
        }
        if (record.position) {
          dpString = record.position;
          if (!position.find((item) => item === dpString)) {
            position.push(dpString);
          }
        }
        if (record.emp_type) {
          dpString = record.emp_type;
          if (!empType.find((item) => item === dpString)) {
            empType.push(dpString);
          }
        }
        if (record.level_code) {
          dpString = record.level_code;
          if (!levelCode.find((item) => item === dpString)) {
            levelCode.push(dpString);
          }
        }
        if (record.go_out) {
          dpString = record.go_out;
          if (!goOut.find((item) => item === dpString)) {
            goOut.push(dpString);
          }
        }
        let change_password = false;
        if (record.change_password) change_password = record.change_password;
        userData.push({
          lastname: record.lastname,
          lastname_yomi: record.lastname_yomi,
          name: record.name,
          name_yomi: record.name_yomi,
          email: record.email,
          password: record.password,
          company: record.company,
          department: record.department,
          emp_no: record.emp_no,
          role: record.role,
          group_code: groupCode,
          department2: record.department2,
          department3: record.department3,
          department4: record.department4,
          department5: record.department5,
          position: record.position,
          manager: record.manager,
          level_code: record.level_code,
          go_out: record.go_out,
          sex: record.sex,
          emp_type: record.emp_type,
          birthday: record.birthday,
          join_company: record.join_company,
          change_password: change_password,
          age_range: record.age_range,
          work_location: record.work_location,
          work_time_rule: record.work_time_rule,
          work_time: record.work_time,
          join_type: record.join_type,
          init_pass: record.password,
          inactive: record.inactive,
          change_history: [
            {
              input_date: today,
              department: record.department,
              department2: record.department2,
              department3: record.department3,
              department4: record.department4,
              department5: record.department5,
              position: record.position,
              level_code: record.level_code,
              emp_type: record.emp_type,
              go_out: record.go_out,
              inactive: record.inactive,
            },
          ],
        });
      });
      // console.log({ userData });
      // console.log({ department });
      // const res3 = await Axios.post('/api/users/csvuserupload', userData);
      const groupData = { department, position, empType, levelCode, goOut };
      const [res2, res3] = await Promise.all([
        Axios.patch(`/api/group/${groupCode}`, groupData),
        Axios.post('/api/users/csvuserupload', userData),
      ]);
      // console.log({ res3 });
      if (res3.data.success && res2.data.success) {
        // if (false) {
        setFileData('CSVアップロードが完了しました。');
        message.success({
          content: 'アップロード完了！',
          key: 'csvupload',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/');
        }, 1000);
      } else {
        message.error({
          content: 'CSVアップロードに失敗しました。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('CSVアップロードに失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      /*
      ＜課題＞
      projectKeyがUniqueでない場合の処理を追加
      MongoDB error code 11000 
      */
      message.error({
        content: 'CSVアップロードに失敗しました。',
        key: 'csvupload',
        duration: 2,
      });
    }
  };

  const onChangeGroup = (value) => {
    if (value) {
      setGroupCode(value);
      setSubmitButtonDisable(false);
    }
    // console.log(e);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>受講者 CSV アップロード</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>企業を選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeGroup}
                value={groupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groups.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.group_code} key={element._id}>
                      {element.group_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <br />
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={submitButtonDisable}
          >
            アップロード
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
}

export default CSVUserUploadPage;
