import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
// import Vimeo from '@vimeo/vimeo';
import {
  Card,
  Col,
  Row,
  Tooltip,
  Modal,
  Spin,
  Typography,
  message,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from '../../Config/key';
import Vimeo from '@u-wave/react-vimeo';
const { Title, Text } = Typography;
const { confirm } = Modal;
// var Vimeo = require('vimeo').Vimeo;
function Course90301Page(props) {
  const [viewModal, setViewModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paused, setPaused] = useState(false);
  const [videoId, setVideoId] = useState('');
  const thumbnailURL = `${config.S3_URL}/${config.S3_ENV}/videos/`;
  const videos = [
    '883886883',
    '883887429',
    '883887814',
    '883889004',
    '883889470',
  ];
  const thumbnails = [
    '%E3%80%90%E3%82%B5%E3%83%A0%E3%83%8D%E3%80%91STEP1_%E6%A5%BD%E3%81%97%E3%81%8F%E5%83%8D%E3%81%8F%E6%AF%8E%E6%97%A5%E3%82%92%E3%82%81%E3%81%95%E3%82%99%E3%81%9D%E3%81%86.jpeg',
    '%E3%80%90%E3%82%B5%E3%83%A0%E3%83%8D%E3%80%91STEP2_%E3%81%82%E3%82%8A%E3%81%9F%E3%81%84%E5%A7%BF%E3%82%92%E6%8F%8F%E3%81%93%E3%81%86%EF%BC%88%E8%81%B7%E5%A0%B4%E3%83%BB%E3%83%81%E3%83%BC%E3%83%A0%E7%B7%A8%EF%BC%89.jpeg',
    '%E3%80%90%E3%82%B5%E3%83%A0%E3%83%8D%E3%80%91STEP3_%E9%A0%85%E7%9B%AE%E3%81%AE%E6%84%8F%E5%91%B3%E3%82%92%E7%9F%A5%E3%82%8D%E3%81%86.jpeg',
    '%E3%80%90%E3%82%B5%E3%83%A0%E3%83%8D%E3%80%91STEP4_%E3%82%B9%E3%82%B3%E3%82%A2%E3%82%92%E6%8D%89%E3%81%88%E3%82%88%E3%81%86%EF%BC%88%E8%81%B7%E5%A0%B4%E3%83%BB%E3%83%81%E3%83%BC%E3%83%A0%E7%B7%A8%EF%BC%89.jpeg',
    '%E3%80%90%E3%82%B5%E3%83%A0%E3%83%8D%E3%80%91STEP5_%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E8%80%83%E3%81%88%E3%82%88%E3%81%86%EF%BC%88%E3%83%9E%E3%83%8D%E3%82%B7%E3%82%99%E3%83%A3%E3%83%BC%E7%B7%A8%EF%BC%89.jpeg',
  ];
  let sendData = props.location.state;
  let { tokenInfo } = props.location.state;
  let { user } = props;
  let surveyStatus = sendData.tokenInfo.survey_status === 5 ? true : false;
  // console.log({ props });
  //ModalでのOK
  const handleOk = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  const handleCancel = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  //ModalでのOK
  const handleCardClick = (v) => {
    // console.log(v);
    if (v === 'takeSurvey') {
      sendData.tokenInfo.survey_code = '30010';
      // console.log({ sendData });
      props.history.push({
        pathname: '/newtakesurvey',
        state: sendData,
      });
    } else {
      setVideoId(v);
      setPaused(false);
      setViewModal(true);
    }
  };

  // const viewSurveyResultHandler = async () => {
  //   // console.log({ sendData });
  //   try {
  //     // console.log({ user });

  //     let url = '';
  //     if (sendData.tokenInfo.group_code) {
  //       url = `${config.S3_URL}/${config.S3_ENV}/user/${sendData.tokenInfo.group_code}/${sendData.tokenInfo.user}/rcep_${sendData.tokenInfo._id}.pdf`;
  //     } else {
  //       url = `${config.S3_URL}/${config.S3_ENV}/user/${sendData.tokenInfo.user}/rcep_${sendData.tokenInfo._id}.pdf`;
  //     }
  //     window.open(url, '_blank');
  //   } catch (error) {
  //     alert('サーベイ結果確認ができません。');
  //     console.log(error);
  //   }
  // };

  const showConfirm = async () => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        const [seasonInfo, group] = await Promise.all([
          Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
          Axios.get(`/api/group/${user.userData.groupCode}`),
        ]);
        tokenInfo.group_name = group.group_name;
        tokenInfo.training_start_date = seasonInfo.training_start_date;
        tokenInfo.training_end_date = seasonInfo.training_end_date;
        tokenInfo.survey_start_date = seasonInfo.survey_start_date;
        tokenInfo.survey_end_date = seasonInfo.survey_end_date;
        tokenInfo.user_name = `${user.userData.lastname} ${user.userData.name}`;
        console.log(tokenInfo);
        let dep = '';
        if (user.userData.department) dep = user.userData.department;
        if (user.userData.department2)
          dep = dep + ' ' + user.userData.department2;
        if (user.userData.department3)
          dep = dep + ' ' + user.userData.department3;
        if (user.userData.department4)
          dep = dep + ' ' + user.userData.department4;
        if (user.userData.department5)
          dep = dep + ' ' + user.userData.department5;
        tokenInfo.department = dep;
        const result = await Axios.post(
          '/api/dbModel100/makeReport30010Personal',
          tokenInfo
        );
        // console.log({ result });
        if (result.data.success) {
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '1rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>E³-Surveyパーソナルコース</Title>
        </div>
        <Card
          title="STEP1 楽しく働く毎日をめざそう"
          bodyStyle={{ backgroundColor: '#EEEEEE' }}
          headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
          extra={
            <Tooltip
              placement="bottomRight"
              title={
                'ここでは、E³-Surveyの受検および、STEP1を解説しています。\n【学習時間の目安：30分】'
              }
              color={'orange'}
              key={'orange'}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '25px', color: '#FFF' }}
              />
            </Tooltip>
          }
        >
          <Row gutter={16}>
            <Col span={8}>
              <Card
                hoverable
                cover={
                  <div style={{ position: 'relative' }}>
                    <img
                      style={{ width: '100%' }}
                      alt="STEP1 楽しく働く毎日をめざそう"
                      src={thumbnailURL + videos[0] + '/' + thumbnails[0]}
                    />
                    <div className="duration">
                      <span>
                        {('00' + 8).slice(-2)} : {('00' + 22).slice(-2)}
                      </span>
                    </div>
                  </div>
                }
                onClick={() => handleCardClick(videos[0])}
              >
                STEP1 楽しく働く毎日をめざそう
              </Card>
            </Col>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable={false}
                  cover={
                    <img
                      alt="診断受検完了"
                      src={thumbnailURL + 'fixedThumbnail/105_d.png'}
                    />
                  }
                >
                  診断受検完了
                </Card>
              ) : (
                <Card
                  hoverable={true}
                  cover={
                    <img
                      alt="診断受検はこちら"
                      src={thumbnailURL + 'fixedThumbnail/105.png'}
                      onClick={() => handleCardClick('takeSurvey')}
                    />
                  }
                >
                  診断受検はこちら
                </Card>
              )}
            </Col>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable={true}
                  cover={
                    <img
                      alt="パーソナル診断結果PDFダウンロード"
                      src={thumbnailURL + 'fixedThumbnail/104.png'}
                      onClick={showConfirm}
                    />
                  }
                >
                  診断結果PDFダウンロード
                </Card>
              ) : (
                <Card
                  hoverable={false}
                  cover={
                    <img
                      alt="パーソナル診断結果PDFダウンロード"
                      src={thumbnailURL + 'fixedThumbnail/104_d.png'}
                    />
                  }
                >
                  パーソナル診断結果PDFダウンロード
                </Card>
              )}
            </Col>
          </Row>
        </Card>

        <br />
        <Card
          title="STEP2 ありたい姿を描こう"
          headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
          bodyStyle={{ backgroundColor: '#EEEEEE' }}
          extra={
            <Tooltip
              placement="bottomRight"
              title={
                'ここでは、E³-SurveyのSTEP2 「ありたい姿を描こう」を解説しています。\n【学習時間の目安：9分】'
              }
              color={'orange'}
              key={'orange'}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '25px', color: '#FFF' }}
              />
            </Tooltip>
          }
        >
          <Row gutter={16}>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP2 ありたい姿を描こう（職場・チーム編）"
                        src={thumbnailURL + videos[1] + '/' + thumbnails[1]}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 8).slice(-2)} : {('00' + 44).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick(videos[1])}
                >
                  STEP2 ありたい姿を描こう（職場・チーム編）
                </Card>
              ) : (
                <Card
                  hoverable={false}
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP2 ありたい姿を描こう（職場・チーム編）"
                        src={thumbnailURL + videos[1] + '/' + thumbnails[1]}
                      />
                    </div>
                  }
                >
                  STEP2 ありたい姿を描こう（職場・チーム編）
                </Card>
              )}
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          title="STEP3 項目の意味を知ろう"
          headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
          bodyStyle={{ backgroundColor: '#EEEEEE' }}
          extra={
            <Tooltip
              placement="bottomRight"
              title={
                'ここでは、E³-SurveyのSTEP3「項目の意味を知ろう」を解説しています。\n【学習時間の目安：9分】'
              }
              color={'orange'}
              key={'orange'}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '25px', color: '#FFF' }}
              />
            </Tooltip>
          }
        >
          <Row gutter={16}>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP3 項目の意味を知ろう"
                        src={thumbnailURL + videos[2] + '/' + thumbnails[2]}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 8).slice(-2)} : {('00' + 50).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick(videos[2])}
                >
                  STEP3 項目の意味を知ろう
                </Card>
              ) : (
                <Card
                  hoverable={false}
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP3 項目の意味を知ろう"
                        src={thumbnailURL + videos[2] + '/' + thumbnails[2]}
                      />
                    </div>
                  }
                >
                  STEP3 項目の意味を知ろう
                </Card>
              )}
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          title="STEP4 スコアを捉えよう"
          headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
          bodyStyle={{ backgroundColor: '#EEEEEE' }}
          extra={
            <Tooltip
              placement="bottomRight"
              title={
                'ここでは、E³-SurveyのSTEP4「スコアを捉えよう」を解説しています。\n【学習時間の目安：8分】'
              }
              color={'orange'}
              key={'orange'}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '25px', color: '#FFF' }}
              />
            </Tooltip>
          }
        >
          <Row gutter={16}>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP4 スコアを捉えよう（職場・チーム編）"
                        src={thumbnailURL + videos[3] + '/' + thumbnails[3]}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 7).slice(-2)} : {('00' + 2).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick(videos[3])}
                >
                  STEP4 スコアを捉えよう（職場・チーム編）
                </Card>
              ) : (
                <Card
                  hoverable={false}
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP4 スコアを捉えよう（職場・チーム編）"
                        src={thumbnailURL + videos[3] + '/' + thumbnails[3]}
                      />
                    </div>
                  }
                >
                  STEP4 スコアを捉えよう（職場・チーム編）
                </Card>
              )}
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          title="STEP5 アクションを考えよう"
          headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
          bodyStyle={{ backgroundColor: '#EEEEEE' }}
          extra={
            <Tooltip
              placement="bottomRight"
              title={
                'ここでは、E³-SurveyのSTEP5「アクションを考えよう」を解説しています。\n【学習時間の目安：6分】'
              }
              color={'orange'}
              key={'orange'}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '25px', color: '#FFF' }}
              />
            </Tooltip>
          }
        >
          <Row gutter={16}>
            <Col span={8}>
              {surveyStatus ? (
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP5 アクションを考えよう（マネジャー編）"
                        src={thumbnailURL + videos[4] + '/' + thumbnails[4]}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 5).slice(-2)} : {('00' + 9).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick(videos[4])}
                >
                  STEP5 アクションを考えよう（マネジャー編）
                </Card>
              ) : (
                <Card
                  hoverable={false}
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="STEP5 アクションを考えよう（マネジャー編）"
                        src={thumbnailURL + videos[4] + '/' + thumbnails[4]}
                      />
                    </div>
                  }
                >
                  STEP5 アクションを考えよう（マネジャー編）
                </Card>
              )}
            </Col>
          </Row>
        </Card>
      </div>
      <div>
        <Modal
          title="動画再生"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={1000}
          footer={null}
        >
          <Vimeo
            video={videoId}
            className="vimeo pc"
            responsive
            autoplay={true}
            speed={true}
            paused={paused}
          />
        </Modal>
        <Modal
          title="サーベイ結果ファイル生成中..."
          open={isModalVisible}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: true }}
          bodyStyle={{ textAlign: 'center' }}
        >
          <Spin size="large" />
        </Modal>
      </div>
    </Fragment>
  );
}

export default Course90301Page;
