import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Card,
  Input,
  Select,
  Tabs,
  Radio,
  Space,
  Row,
  Col,
  Modal,
  Spin,
} from 'antd';
import Axios from 'axios';
import config from '../../Config/key';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const PDF10010CompanyDowlnoadPage = () => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  // const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  // const [selQuestionSetId, setSelQuestionSetId] = useState(''); //全てのサーベイ情報
  // const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [submitSW, setSubmitSW] = useState(true); //SubmitボタンDisable調整 true => 表示
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const usr = useSelector((state) => state.user);

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      setUserInfo(usr.userData);
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      // setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);

      if (!groups) throw new Error('グループ取得に失敗しました。');
      if (!survey) throw new Error('サーベイ取得に失敗しました。');
      if (usr.userData.role === 3 || usr.userData.role === 4) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(
          sgi.survey_season.filter(
            (item) =>
              item.survey_code === '10010' || item.survey_code === '90101'
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log(sgi.department);
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setGroupSurveySeasonInfo(
      sgi.survey_season.filter(
        (item) => item.survey_code === '10010' || item.survey_code === '90101'
      )
    );
    setSelSurveySeasonId('');
    // setSelQuestionSetId('');
    // setQuestionSet([]);
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsModalVisible(true);
      setSubmitSW(false);
      const sendData = {
        selGroupInfo: selGroupInfo,
        selSurveySeasonInfo: selSurveySeasonInfo,
      };
      // console.log({ sendData });
      const res = await Axios.post(
        '/api/dbModel100/makeReport10010_company',
        sendData
      );
      const url = `${config.S3_URL}/${res.data.s3Path}`;
      // console.log({ url });
      setTimeout(() => {
        message.success({
          content: 'ファイル生成完了！',
          key: 'makecsv',
          duration: 2,
        });
        setSubmitSW(true);
        setIsModalVisible(false);

        window.open(url, '_blank');
      }, 3000);
    } catch (error) {
      console.log(error.message);
      setIsModalVisible(false);
      message.error({
        content: error.message,
        key: 'makecsv',
        duration: 4,
      });
      setSelSurveySeasonId('');
      setSelGroupCode('');
      setSelGroupInfo([]);
      setSubmitSW(true);

      // window.location.reload(false);
    }
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '1100px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>人事評価力組織診断結果ダウンロード(PDF)</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={userInfo.role === 3 || userInfo.role === 4}
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="サーベイ選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <br />
          <br />

          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!(submitSW && selSurveySeasonId)}
          >
            ダウンロード
          </Button>
        </Form>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
};

export default PDF10010CompanyDowlnoadPage;
