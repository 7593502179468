import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, message } from 'antd';
import config from '../../Config/key';
// import { surveyCode } from '../../Config/surveyCode';

const { Column } = Table;

const VideoDeliveryMgrPage = (props) => {
  // console.log({ props });
  const { user } = props;
  const [VDInfo, setVDInfo] = useState([]);

  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      ('0' + date.getFullYear()).slice(-2) +
      '年' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '月' +
      ('0' + date.getDate()).slice(-2) +
      '日'
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const res = await Axios.get('/api/videoDelivery/');
        // console.log(res.data.groups);
        if (res.data.success) {
          let vdArr = res.data.videoDelivery;
          vdArr.map((element) => {
            element.delivery_s = ymd(element.delivery_start);
            element.delivery_e = ymd(element.delivery_end);
            return element;
          });
          // console.log(vdArr);
          setVDInfo(vdArr);
        } else {
          throw new Error('動画データ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  //クリップボードにコピー関数
  const copyToClipboard = async (vdInfo, e) => {
    // console.log(vdInfo);
    await global.navigator.clipboard.writeText(
      `${config.BACKEND_SERVER}/videolist/videoview/${vdInfo.token}`
    );
    message.success({
      content: 'OneTimeURLがクリップボードにコピーできました。',
      key: 'ccopy',
      duration: 3,
    });
  };
  //クリップボードにコピー関数
  const openVideoPage = async (vdInfo, e) => {
    // console.log(vdInfo);
    window.open(
      `${config.BACKEND_SERVER}/videolist/videoview/${vdInfo.token}`,
      '_blank'
    );
  };

  const editVDHandler = async (vdInfo, e) => {
    e.preventDefault();
    try {
      vdInfo.fg = 'edit';
      props.history.push({
        pathname: '/videodelivery',
        state: vdInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const newVDHandler = async (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/videodelivery',
        state: { fg: 'new' },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={VDInfo} size="middle" rowKey="_id">
        <Column
          title="タイトル"
          dataIndex="main_title"
          key="main_title"
          aligh="left"
        />
        <Column
          title="視聴開始"
          dataIndex="delivery_s"
          key="delivery_s"
          aligh="left"
        />
        <Column
          title="視聴終了"
          dataIndex="delivery_e"
          key="delivery_e"
          aligh="left"
        />
        <Column
          title="納品説明（管理向け）"
          dataIndex="delivery_description"
          key="delivery_description"
          aligh="left"
          width="40%"
        />
        <Column
          title="納品用URL"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}
              <Button
                type={'primary'}
                size="small"
                onClick={(e) => copyToClipboard(record, e)}
              >
                URLコピー
              </Button>
            </span>
          )}
        />
        <Column
          title="納品用ページ"
          key="action"
          render={(text, record) => (
            <span>
              <Button
                type={'primary'}
                size="small"
                onClick={(e) => openVideoPage(record, e)}
              >
                納品ページを開く
              </Button>
            </span>
          )}
        />
        <Column
          title="編集"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}

              <Button
                type={'primary'}
                size="small"
                onClick={(e) => editVDHandler(record, e)}
                disabled={false}
              >
                編集
              </Button>
            </span>
          )}
        />
        {/* <Column
          title="サーベイ削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => surveyDeleteHandler(record._id, e)}
                disabled={record.survey_status === 5 ? false : true}
              >
                サーベイ削除
              </Button>
            </span>
          )}
        /> */}
      </Table>

      <Button type={'primary'} size="small" onClick={(e) => newVDHandler(e)}>
        動画納品新規作成
      </Button>
    </div>
  );
};

export default VideoDeliveryMgrPage;
