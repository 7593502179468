import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Popover,
  DatePicker,
  Space,
  Divider,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ja_JP';
// import type { DatePickerProps } from 'antd';
import Axios from 'axios';
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const VideoDeliveryPage = (props) => {
  const [value, setValue] = useState('');
  const [mode, setMode] = useState('new');
  const [groupInfo, setGroupInfo] = useState([]);
  // const [videoInfo, setVideoInfo] = useState([]);
  const [vList, setVList] = useState([]);
  const vdInfo = props.location.state;
  const { user } = props;

  const [form] = Form.useForm();
  // let vList = [
  // {
  //   value: '63ed78f2a559ed0c057c2d49',
  //   label: '評価の基本：人事評価制度の基本的な知識',
  // },
  // {
  //   value: '63edaf8ca559ed0c057c2d4a',
  //   label: '人事評価力診断：概要紹介',
  // },
  // {
  //   value: '63edc63ca559ed0c057c2d54',
  //   label: '評価面談：評価面談～場作り～',
  // },
  // {
  //   value: '63ed7824a559ed0c057c2d48',
  //   label: '評価の基本：人事評価制度の目的',
  // },
  // {
  //   value: '63edbd4ba559ed0c057c2d4c',
  //   label: '評価エラー解説：ハロー効果',
  // },
  // ];

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const [group, video] = await Promise.all([
          Axios.get('/api/group/'),
          Axios.get('/api/video/'),
        ]);
        // console.log(group.data.groups);
        // console.log(survey.data.survey);
        if (group.data.success && video.data.success) {
          setGroupInfo(group.data.groups);
          // setVideoInfo(video.data.video);
          let vl = [];
          video.data.video.forEach((element) => {
            vl.push({ value: element._id, label: element.title });
          });
          setVList(vl);
          // console.log({ vl });
        } else {
          throw new Error('グループ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user.userData) return null;
    // console.log({ user });
    readDB();
    // console.log(vdInfo);
    if (vdInfo.fg === 'edit') {
      // setMode('edit');
      if (vdInfo.group_id) {
        setValue({
          group_code: vdInfo.group_id.group_code,
          group_id: vdInfo.group_id._id,
          main_title: vdInfo.main_title,
          sub_title: vdInfo.sub_title,
          delivery_description: vdInfo.delivery_description,
          delivery_explanation: vdInfo.delivery_explanation,
          delivery_end: vdInfo.delivery_end,
          delivery_start: vdInfo.delivery_start,
          show_limit: vdInfo.show_limit,
          show_title: vdInfo.show_title,
        });
      } else {
        setValue({
          main_title: vdInfo.main_title,
          sub_title: vdInfo.sub_title,
          delivery_description: vdInfo.delivery_description,
          delivery_explanation: vdInfo.delivery_explanation,
          delivery_end: vdInfo.delivery_end,
          delivery_start: vdInfo.delivery_start,
          show_limit: vdInfo.show_limit,
          show_title: vdInfo.show_title,
        });
      }
    } else {
      // setMode('new');
      setValue({
        show_limit: 1,
      });
    }
    window.scrollTo(0, 0);
  }, [user]);

  function createUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (a) {
        let r = (new Date().getTime() + Math.random() * 16) % 16 | 0,
          v = a == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const onChangeMainTitle = (e) => {
    setValue({ ...value, main_title: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeSubTitle = (e) => {
    setValue({ ...value, sub_title: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeGroup = (v) => {
    setValue({
      ...value,
      group_code: v,
      group_id: groupInfo.find((item) => item.group_code === v)._id,
    });
    // console.log(value);
  };
  const onChangeShowLimit = (v) => {
    setValue({
      ...value,
      show_limit: v,
    });
    // console.log(value);
  };
  const onChangeShowTitle = (v) => {
    setValue({
      ...value,
      show_title: v,
    });
    // console.log(value);
  };
  //研修日程をStart→Endの形に変更 20221215
  const onChangeStartDate = (date, dateString) => {
    // console.log(date, dateString);
    setValue({
      ...value,
      delivery_start: dateString[0] + ' 00:00:00',
      delivery_end: dateString[1] + ' 14:59:59',
    });
  };

  const onChangeExpl = (e) => {
    setValue({ ...value, delivery_explanation: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeDesc = (e) => {
    setValue({ ...value, delivery_description: e.currentTarget.value });
    // console.log(value);
  };

  const onSubmit = async () => {
    try {
      let res = {};
      // console.log({ mode });
      if (vdInfo.fg === 'edit') {
        // res = await Axios.put('/api/surveySeason', value);   //APIの追加が必要
      } else {
        // res = await Axios.post('/api/surveySeason', value);
        // console.log({ value });
      }
      // console.log({ res });
      // if (res.data.success) {
      //   message.success({
      //     content: 'シーズン登録完了！',
      //     key: 'submit',
      //     duration: 2,
      //   });
      //   setTimeout(() => {
      //     props.history.push('/videodeliverymgr');
      //   }, 1000);
      // } else {
      //   throw new Error('シーズン登録に失敗しました。');
      // }
    } catch (error) {
      message.error({
        content: 'シーズン登録に失敗しました。',
        key: 'submit',
        duration: 2,
      });
      console.log({ error });
    }
  };
  const onCancel = async () => {
    props.history.push('/videodeliverymgr');
  };
  const onFinish = async (values) => {
    try {
      if (!value.delivery_start) {
        message.error({
          content: '動画提供期間を入力してください。',
          key: 'submit',
          duration: 5,
        });
        return;
      }
      if (!value.main_title) {
        message.error({
          content: '企業名を入力してください。',
          key: 'submit',
          duration: 5,
        });
        return;
      }
      if (
        (!values.VideoContents || values.VideoContents.length === 0) &&
        vdInfo.fg === 'new'
      ) {
        message.error({
          content: '動画コンテンツを追加してください。',
          key: 'submit',
          duration: 5,
        });
        return;
      }
      //UUID作成
      // const tokenData = {
      //   token: createUuid(),
      //   token_exp: value.delivery_end,
      //   token_description: value.main_title + ' / ' + value.sub_title,
      // };

      // const res = await Axios.post('/api/token', tokenData);
      // console.log({ res });
      // if (res.data.success) {
      //   message.success({
      //     content: '動画閲覧トークン登録完了！',
      //     key: 'submit',
      //     duration: 2,
      //   });
      let sendData = {
        ...value,
        delivery_video: values.VideoContents,
        token: createUuid(),
        fg: vdInfo.fg,
      };
      let res2 = {};
      if (vdInfo.fg === 'new') {
        res2 = await Axios.post('/api/videoDelivery', sendData);
      } else {
        res2 = await Axios.patch(`/api/videoDelivery/${vdInfo._id}`, sendData);
      }

      if (res2.data.success) {
        message.success({
          content: '動画納品ページ登録完了！',
          key: 'submit',
          duration: 5,
        });
        setTimeout(() => {
          props.history.push('/videodeliverymgr');
        }, 1000);
      } else {
        throw new Error('動画閲覧トークン登録に失敗しました。');
      }
      // } else {
      //   throw new Error('動画閲覧トークン登録に失敗しました。');
      // }
    } catch (error) {
      message.error({
        content: '動画納品登録に失敗しました。',
        key: 'submit',
        duration: 2,
      });
      console.log({ error });
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '800px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>動画納品ページ作成</Title>
        </div>
        <Form
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinish}
          style={{
            maxWidth: 800,
          }}
          autoComplete="off"
        >
          <br />
          <div>
            <Text strong>■ 企業選択（省略可）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Select
            showSearch
            style={{
              width: 600,
            }}
            placeholder="企業選択"
            optionFilterProp="items"
            onChange={onChangeGroup}
            value={value['group_code']}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {groupInfo.map((element) => {
              // console.log({ element });
              return (
                <Option value={element.group_code} key={element._id}>
                  {element.group_name}
                </Option>
              );
            })}
          </Select>
          <br />
          <br />
          <div>
            <Text strong>■ 動画提供期間（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          {vdInfo.fg === 'edit' ? (
            <RangePicker
              onChange={onChangeStartDate}
              locale={locale}
              value={[
                dayjs(value['delivery_start'], dateFormat),
                dayjs(value['delivery_end'], dateFormat),
              ]}
            />
          ) : (
            <RangePicker onChange={onChangeStartDate} locale={locale} />
          )}
          <br />
          <br />
          <div>
            <Text strong>■ 企業名（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="企業名"
            name="main_title"
            allowClear
            onChange={onChangeMainTitle}
            value={value['main_title']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ タイトル</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="タイトル"
            name="sub_title"
            allowClear
            onChange={onChangeSubTitle}
            value={value['sub_title']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 説明文（公開用）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <TextArea
            rows={3}
            onChange={onChangeExpl}
            value={value['delivery_explanation']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 管理向け説明（非公開）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <TextArea
            rows={3}
            onChange={onChangeDesc}
            value={value['delivery_description']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 動画閲覧期間表示（既定値：表示）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Select
            showSearch
            style={{
              width: 400,
            }}
            placeholder="表示選択"
            optionFilterProp="items"
            onChange={onChangeShowLimit}
            value={value.show_limit}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0} key={0}>
              非表示
            </Option>
            <Option value={1} key={1}>
              表示
            </Option>
          </Select>
          <br />
          <br />
          <div>
            <Text strong>■ 動画タイトル表示選択</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Select
            showSearch
            style={{
              width: 400,
            }}
            placeholder="動画情報表示選択"
            optionFilterProp="items"
            onChange={onChangeShowTitle}
            value={value.show_title}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0} key={0}>
              何も表示しない
            </Option>
            <Option value={1} key={1}>
              動画タイトルを表示
            </Option>
            <Option value={2} key={2}>
              動画説明を表示
            </Option>
            <Option value={3} key={3}>
              動画タイトル＋説明を表示
            </Option>
          </Select>
          <br />
          <br />
          <div>
            <Text strong>■ 自動生成ページ構成</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <br />
          <br />
          {vdInfo.fg === 'new' ? (
            <Form.List name="VideoContents">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', size: 'larage' }}
                      align="baseline"
                      direction="vertical"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'category']}
                        label="カテゴリー名"
                        rules={[
                          {
                            required: false,
                            message: 'カテゴリー名を入力してください。',
                          },
                        ]}
                        style={{ width: 600 }}
                      >
                        <Input placeholder="カテゴリー名" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'category_help']}
                        label="カテゴリー・ヘルプ"
                        rules={[
                          {
                            required: false,
                            message: 'カテゴリー・ヘルプを入力してください',
                          },
                        ]}
                        style={{ width: 600 }}
                      >
                        <Input placeholder="カテゴリー・ヘルプ" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'video_id']}
                        label="動画選択"
                        rules={[
                          { required: true, message: '動画を選んでください' },
                        ]}
                        style={{ width: 600 }}
                      >
                        <Select
                          mode="multiple"
                          style={{
                            width: '100%',
                          }}
                          onChange={handleChange}
                          tokenSeparators={[',']}
                          options={vList}
                        />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ color: 'red', fontSize: '24px' }}
                      />
                      <Divider />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      動画カテゴリー追加
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : (
            <Text type="warning">
              動画構成の編集はできません
              <br />
              <br />
              <br />
              <br />
            </Text>
          )}
          <Space>
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit">
                登録
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                onClick={onCancel}
                disabled={false}
              >
                キャンセル
              </Button>
            </Form.Item>
          </Space>
          <br />
          <br />
          {/* <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={false}
          >
            登録
          </Button> */}
          　
        </Form>
      </div>
    </Fragment>
  );
};

export default VideoDeliveryPage;
