import React, { useState, Fragment, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { UserContext } from '../NewTakeSurveyPage';
import { Card, Typography, Button, message, Modal } from 'antd';
import Axios from 'axios';

const { Title } = Typography;
const { Text } = Typography;
const { confirm } = Modal;

function SubmitSurveyPage(props) {
  // console.log({ props });
  // const history = useHistory();
  // const [answerOK, setAnswerOK] = useState(true);
  const { answer, setAnswer } = useContext(UserContext);
  const { tokenInfo } = props.props.location.state;
  const { questionInfo } = props.props.location.state;
  const { stepArr } = props.props.location.state;
  const { studyStartDate } = props;
  const queLen = questionInfo.length;
  // console.log({ answer });
  let newAnswer = {};
  for (let key in answer) {
    if (answer[key]) {
      newAnswer[key] = answer[key];
    }
  }

  // setAnswer(newAnswer);
  let arr = Object.keys(newAnswer);
  const unreqQNo = []; //任意の設問番号を配列に格納
  let noAnswerFreeAfter = 0; //選択＋自由記述型の返事結果

  // console.log({ questionInfo });
  for (let i = 0; i < questionInfo.length; i++) {
    const element = questionInfo[i];
    if (!element.required) {
      unreqQNo.push(element.no.toString());
    }
    if (element.free_after && element.required) {
      //必須の選択＋自由記述形を絞る
      if (answer[element.no] === element.free_after) {
        //その答えがその他を選択した場合
        if (answer[element.no + 1000]) {
          // console.log(`回答あり：${answer[element.no + 1000]}`);
        } else {
          noAnswerFreeAfter += 1;
          // console.log({ noAnswerFreeAfter });
        }
      }
    }
  }
  // console.log({ questionInfo });
  const arr2 = arr.filter(
    (item) => !unreqQNo.includes(item) && Number(item) < 1000 //任意の設問番号が一致しない場合のみカウント
  );

  // if (queLen - unreqQNo.length > arr2.length) {
  //   //必須設問に全て回答してないか？
  //   // return true; //提出ボタン非表示
  //   setAnswerOK(false);
  // } else {
  //   // return false;
  //   setAnswerOK(true);
  // }

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Card
        title="回答提出案内"
        style={{
          margin: '0px',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        headStyle={{
          color: 'white',
          backgroundColor: 'rgba(83, 131, 236, 1)',
          border: 1,
        }}
      >
        {tokenInfo.survey_code === '30010' ? (
          <Fragment>
            <Title level={4}>アンケートへの回答を提出してください</Title>
            <Text>
              アンケートの設問に全て回答いただいた方は、下記のボタンを押して回答を提出してください
              <br />
              ＊選択式の設問に全て回答いただくと、ページ下部に「回答提出」という赤いボタンが出ます
            </Text>
          </Fragment>
        ) : (
          <Fragment>
            <Title level={4}>診断の回答を提出してください。</Title>
            <Text>
              診断の設問に全て回答いただいた方は下記のボタンを押して回答を提出してください。
            </Text>
          </Fragment>
        )}
        <br />
        <br />
        <br />
        {queLen - unreqQNo.length > arr2.length - noAnswerFreeAfter ? (
          <Text type="danger" strong>
            未回答の設問があります。
            <br />全{queLen - unreqQNo.length}問中 - 回答：
            {arr2.length - noAnswerFreeAfter}
            問、未回答：
            {queLen - unreqQNo.length - arr2.length + noAnswerFreeAfter}問
          </Text>
        ) : (
          <Fragment>
            <Text>全ての設問に回答できましたので提出が可能です。</Text>

            {/* <Button
              type="primary"
              shape="round"
              icon="cloud-upload"
              size="large"
              onClick={showConfirm}
            >
              回答提出
            </Button> */}
          </Fragment>
        )}
        <br />
        <br />
      </Card>
      <br />
      <br />
      {(tokenInfo.survey_code === '10010' ||
        tokenInfo.survey_code === '50010' ||
        tokenInfo.survey_code === '60010') && (
        <Card
          title="結果確認案内"
          style={{
            margin: '0px',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
          headStyle={{
            color: 'white',
            backgroundColor: 'rgba(83, 131, 236, 1)',
            border: 1,
          }}
        >
          <Title level={4}>診断結果を確認してください。</Title>
          <Text>
            回答の提出が完了した方は「マイ・サーベイ」メニューから診断結果を確認することができます。
          </Text>
        </Card>
      )}
    </div>
  );
}

export default SubmitSurveyPage;
