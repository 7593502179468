import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, message } from 'antd';

const { Column } = Table;

const GroupListPage = (props) => {
  // console.log({ props });
  const { user } = props;
  const [groupInfo, setGroupInfo] = useState([]);

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const res = await Axios.get('/api/group/');
        // console.log(res.data.groups);
        if (res.data.success) {
          setGroupInfo(res.data.groups);
        } else {
          throw new Error('グループ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  const editGroupHandler = async (gInfo, e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/makegroup',
        state: gInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const newGroupHandler = async (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/makegroup',
        state: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={groupInfo} size="middle" rowKey="_id">
        <Column
          title="グループコード"
          dataIndex="group_code"
          key="group_code"
          aligh="right"
        />
        <Column
          title="グループ名"
          dataIndex="group_name"
          key="group_name"
          aligh="right"
        />
        <Column
          title="グループ説明"
          dataIndex="group_description"
          key="group_description"
          aligh="center"
        />
        <Column
          title="編集"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}
              <Button
                type={'primary'}
                size="small"
                onClick={(e) => editGroupHandler(record, e)}
                disabled={true}
              >
                編集
              </Button>
            </span>
          )}
        />
        {/* <Column
          title="サーベイ削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => surveyDeleteHandler(record._id, e)}
                disabled={record.survey_status === 5 ? false : true}
              >
                サーベイ削除
              </Button>
            </span>
          )}
        /> */}
      </Table>

      <Button type={'primary'} size="small" onClick={(e) => newGroupHandler(e)}>
        新規テンプレート作成
      </Button>
    </div>
  );
};

export default GroupListPage;
