//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function CSVUserUpdatePage(props) {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [groups, setGroups] = useState([]); //グループ名とグループコード

  let department = [];
  let position = [];
  let empType = [];
  let levelCode = [];
  let goOut = [];
  // const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onDrop = async (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          //const courseInfo = courseMerge(results.data);
          // setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          // console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
    const group = await Axios.get('/api/group/');
    setGroups(group.data.groups);
    // console.log(group.data.groups);
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let userData = []; //最終分類用
    try {
      message.loading({
        content: 'CSVアップロード中...',
        key: 'csvupload',
        duration: 20,
      });

      if (!groupCode) {
        message.error({
          content: '企業コードを入力してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('企業コードがありません。');
      }
      const [res, res4] = await Promise.all([
        Axios.get(`/api/group/${groupCode}`),
        Axios.get(`/api/users/group/${groupCode}`),
      ]);
      // console.log({ res4 });
      let users;
      // const res = await Axios.get(`/api/group/${groupCode}`);
      if (res.data.success && res4.data.success) {
        users = res4.data.users;
        // console.log(res.data.group);
        if (res.data.group.department) {
          department = res.data.group.department;
        }
        if (res.data.group.position) {
          position = res.data.group.position;
        }
        if (res.data.group.emp_type) {
          empType = res.data.group.emp_type;
        }
        if (res.data.group.level_code) {
          levelCode = res.data.group.level_code;
        }
        if (res.data.group.go_out) {
          goOut = res.data.group.go_out;
        }
      } else {
        message.error({
          content: '企業コードを確認してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('企業情報の取得ができませんでした。');
      }
      await fileJSON.map((record) => {
        let dpString = '';
        if (record.department) {
          dpString = record.department;
          if (!department.find((item) => item === dpString)) {
            // setDepartment([...department, record.department]);
            department.push(dpString);
            // console.log(`dpString:${dpString}`);
          }
          if (record.department2) {
            dpString = record.department + '／' + record.department2;
            if (!department.find((item) => item === dpString)) {
              // setDepartment([...department, record.department]);
              department.push(dpString);
              // console.log(`dpString:${dpString}`);
            }
            if (record.department3) {
              dpString =
                record.department +
                '／' +
                record.department2 +
                '／' +
                record.department3;
              if (!department.find((item) => item === dpString)) {
                // setDepartment([...department, record.department]);
                department.push(dpString);
                // console.log(`dpString:${dpString}`);
              }
              if (record.department4) {
                dpString =
                  record.department +
                  '／' +
                  record.department2 +
                  '／' +
                  record.department3 +
                  '／' +
                  record.department4;
                if (!department.find((item) => item === dpString)) {
                  // setDepartment([...department, record.department]);
                  department.push(dpString);
                  // console.log(`dpString:${dpString}`);
                }
                if (record.department5) {
                  dpString =
                    record.department +
                    '／' +
                    record.department2 +
                    '／' +
                    record.department3 +
                    '／' +
                    record.department4 +
                    '／' +
                    record.department5;
                  if (!department.find((item) => item === dpString)) {
                    // setDepartment([...department, record.department]);
                    department.push(dpString);
                    // console.log(`dpString:${dpString}`);
                  }
                }
              }
            }
          }
        }
        if (record.position) {
          dpString = record.position;
          if (!position.find((item) => item === dpString)) {
            position.push(dpString);
          }
        }
        if (record.emp_type) {
          dpString = record.emp_type;
          if (!empType.find((item) => item === dpString)) {
            empType.push(dpString);
          }
        }
        if (record.level_code) {
          dpString = record.level_code;
          if (!levelCode.find((item) => item === dpString)) {
            levelCode.push(dpString);
          }
        }
        if (record.go_out) {
          dpString = record.go_out;
          if (!goOut.find((item) => item === dpString)) {
            goOut.push(dpString);
          }
        }

        let element = {};
        element.email = record.email;
        if (record['lastname'] !== undefined)
          element.lastname = record.lastname;
        if (record['lastname_yomi'] !== undefined)
          element.lastname_yomi = record.lastname_yomi;
        if (record['name'] !== undefined) element.name = record.name;
        if (record['name_yomi'] !== undefined)
          element.name_yomi = record.name_yomi;
        if (record['role'] !== undefined) element.role = record.role;
        if (record['department'] !== undefined)
          element.department = record.department;
        if (record['company'] !== undefined) element.company = record.company;
        if (record['department'] !== undefined)
          element.department = record.department;
        if (record['department2'] !== undefined)
          element.department2 = record.department2;
        if (record['department3'] !== undefined)
          element.department3 = record.department3;
        if (record['department4'] !== undefined)
          element.department4 = record.department4;
        if (record['department5'] !== undefined)
          element.department5 = record.department5;
        if (record['position'] !== undefined)
          element.position = record.position;
        if (record['manager'] !== undefined) element.manager = record.manager;
        if (record['level_code'] !== undefined)
          element.level_code = record.level_code;
        if (record['go_out'] !== undefined) element.go_out = record.go_out;
        if (record['inactive'] !== undefined) {
          element.inactive = record.inactive;
        } else {
          element.inactive = false;
        }
        if (record['age'] !== undefined) element.age = record.age;
        if (record['sex'] !== undefined) element.sex = record.sex;
        if (record['work_term'] !== undefined)
          element.work_term = record.work_term;
        if (record['change_password'] !== undefined)
          element.change_password = record.change_password;
        if (record['emp_no'] !== undefined) element.emp_no = record.emp_no;
        if (record['emp_type'] !== undefined)
          element.emp_type = record.emp_type;
        if (record['birthday'] !== undefined)
          element.birthday = record.birthday;
        if (record['join_company'] !== undefined)
          element.join_company = record.join_company;
        if (record['age_range'] !== undefined)
          element.age_range = record.age_range;
        if (record['work_location'] !== undefined)
          element.work_location = record.work_location;
        if (record['work_time_rule'] !== undefined)
          element.work_time_rule = record.work_time_rule;
        if (record['work_time'] !== undefined)
          element.work_time = record.work_time;
        if (record['join_type'] !== undefined)
          element.join_type = record.join_type;
        if (record['init_pass'] !== undefined)
          element.init_pass = record.init_pass;
        if (record['e3_department'] !== undefined) {
          element.e3_department = [];
          element.e3_department.push(record.e3_department);
        }
        if (record['input_date'] !== undefined && record.input_date) {
          const u = users.find((item) => item.email === record.email);
          if (u.change_history.length > 0) {
            element.change_history = u.change_history;
          } else {
            element.change_history = [];
          }
          element.change_history.push({
            input_date: record.input_date,
            department:
              record['department'] !== undefined ? record.department : null,
            department2:
              record['department2'] !== undefined ? record.department2 : null,
            department3:
              record['department3'] !== undefined ? record.department3 : null,
            department4:
              record['department4'] !== undefined ? record.department4 : null,
            department5:
              record['department5'] !== undefined ? record.department5 : null,
            position: record['position'] !== undefined ? record.position : null,
            level_code:
              record['level_code'] !== undefined ? record.level_code : null,
            emp_type: record['emp_type'] !== undefined ? record.emp_type : null,
            go_out: record['go_out'] !== undefined ? record.go_out : null,
            inactive:
              record['inactive'] !== undefined ? record.inactive : false,
          });
          // console.log(record);
        }
        // if (record.lastname) element.lastname = record.lastname;
        // if (record.name) element.name = record.name;
        // if (record.department) element.department = record.department;
        // if (record.company) element.company = record.company;
        // if (record.department) element.department = record.department;
        // if (record.department2) element.department2 = record.department2;
        // if (record.department3) element.department3 = record.department3;
        // if (record.department4) element.department4 = record.department4;
        // if (record.department5) element.department5 = record.department5;
        // if (record.position) element.position = record.position;
        // if (record.manager) element.manager = record.manager;
        // if (record.level_code) element.level_code = record.level_code;
        // if (record.go_out) element.go_out = record.go_out;
        // if (record.age) element.age = record.age;
        // if (record.sex) element.sex = record.sex;
        // if (record.work_term) element.work_term = record.work_term;
        // if (record.change_password)
        //   element.change_password = record.change_password;
        // if (record.emp_no) element.emp_no = record.emp_no;
        // if (record.emp_type) element.emp_type = record.emp_type;
        // if (record.birthday) element.birthday = record.birthday;
        // if (record.join_company) element.join_company = record.join_company;
        // if (record.age_range) element.age_range = record.age_range;
        // if (record.work_location) element.work_location = record.work_location;
        // if (record.work_time_rule)
        //   element.work_time_rule = record.work_time_rule;
        // if (record.work_time) element.work_time = record.work_time;
        // if (record.join_type) element.join_type = record.join_type;
        // if (record.init_pass) element.init_pass = record.init_pass;
        userData.push(element);
      });

      const groupData = { department, position, empType, levelCode, goOut };
      const [res2, res3] = await Promise.all([
        Axios.patch(`/api/group/${groupCode}`, groupData),
        Axios.patch('/api/users/csvuserupdate', userData),
      ]);

      // Axios.patch(`/api/group/${groupCode}`, department),

      // console.log({ res3 });
      if (res3.data.success && res2.data.success) {
        setFileData('CSVアップロードが完了しました。');
        message.success({
          content: 'アップロード完了！',
          key: 'csvupload',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/');
        }, 1000);
      } else {
        message.error({
          content: 'CSVアップロードに失敗しました。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('CSVアップロードに失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'CSVアップロードに失敗しました。',
        key: 'csvupload',
        duration: 2,
      });
    }
  };

  const onChangeGroup = (value) => {
    if (value) {
      setGroupCode(value);
      setSubmitButtonDisable(false);
    }
    // console.log(e);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>受講者 CSV Update with GSD + VUD</Title>
          <Title level={4}>
            GSD : Group Summery Data, VUD : Variable User Data
          </Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>企業を選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeGroup}
                value={groupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groups.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.group_code} key={element._id}>
                      {element.group_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <br />
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={submitButtonDisable}
          >
            アップデート
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
}

export default CSVUserUpdatePage;
