import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Popover,
} from 'antd';
import Axios from 'axios';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const MakeTemplatePage = (props) => {
  const [value, setValue] = useState('');
  const [mode, setMode] = useState('new');
  const [surveyInfo, setSurveyInfo] = useState([]);
  const tInfo = props.location.state;
  const { user } = props;

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const survey = await Axios.get('/api/survey/');
        // console.log(group.data.groups);
        // console.log(survey.data.survey);
        if (survey.data.success) {
          setSurveyInfo(survey.data.survey);
        } else {
          throw new Error('サーベイ情報取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user.userData) return null;
    // console.log({ user });
    readDB();
    if (tInfo.body) {
      setMode('edit');
      setValue({
        survey_code: tInfo.survey_code,
        survey_id: tInfo.survey_id,
        mt_name: tInfo.mt_name,
        mt_description: tInfo.mt_description,
        subject: tInfo.subject,
        from_mail: tInfo.from_mail,
        from_name: tInfo.from_name,
        body: tInfo.body,
        // _id: tInfo._id,
        user: user.userData._id,
      });
    } else {
      setValue({
        user: user.userData._id,
      });
    }
  }, [user]);

  const onChangeTemplateName = (e) => {
    setValue({ ...value, mt_name: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeTemplateDescription = (e) => {
    setValue({ ...value, mt_description: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeTitle = (e) => {
    setValue({ ...value, subject: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeSenderMail = (e) => {
    setValue({ ...value, from_mail: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeSenderName = (e) => {
    setValue({ ...value, from_name: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeBody = (e) => {
    setValue({ ...value, body: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeSurvey = (v) => {
    setValue({
      ...value,
      survey_code: v,
      survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
    });
    // console.log(surveyInfo.find((item) => item.survey_code === v)._id);
    // console.log(value);
  };
  const onSubmit = async () => {
    try {
      let res = {};
      // console.log({ value });
      // console.log({ mode });
      if (mode === 'edit') {
        res = await Axios.put('/api/mailTemplate', {
          ...value,
          _id: tInfo._id,
        });
      } else {
        res = await Axios.post('/api/mailTemplate', value);
      }
      // console.log({ res });
      if (res.data.success) {
        message.success({
          content: 'テンプレート登録完了！',
          key: 'submit',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/mailTemplate');
        }, 1000);
      } else {
        message.error({
          content: 'テンプレート登録に失敗しました。',
          key: 'submit',
          duration: 2,
        });
        throw new Error('テンプレート登録に失敗しました。');
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const onSubmitCopy = async () => {
    try {
      let res = {};
      // console.log({ value });
      // console.log({ mode });
      if (mode === 'edit') {
        res = await Axios.post('/api/mailTemplate', value);
      }
      // console.log({ res });
      if (res.data.success) {
        message.success({
          content: 'テンプレート登録完了！',
          key: 'submit',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/mailTemplate');
        }, 1000);
      } else {
        message.error({
          content: 'テンプレート登録に失敗しました。',
          key: 'submit',
          duration: 2,
        });
        throw new Error('テンプレート登録に失敗しました。');
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onCancel = async () => {
    props.history.push('/mailTemplate');
  };

  const popOver1Handler = (
    <div>
      <p>
        システム変数はこちらに列挙されているものに限って利用できます。<br></br>
        {'※変数は {$変数名$} の形で記述ください。'}
      </p>
      <ul>
        <li>社名：company</li>
        <li>フルネーム：fullname</li>
        <li>苗字：familyname</li>
        <li>メール：email</li>
        <li>研修名：tname</li>
        <li>社員番号：emp_no</li>
        <li>初期パスワード：init_pass</li>
      </ul>
      <p>※360度診断の「協力者」に配信する場合のみ</p>
      <ul>
        <li>対象者の所属と氏名：target</li>
      </ul>
    </div>
  );
  const popOver2Handler = (
    <div>
      <p>
        CSVファイルの第１列には「email」を必ず記入する必要があります。<br></br>
        それ以外はCSVファイルに定義されているフィールド名（英数）をそのまま変数に使うことができます。
        <br></br>
        {'※変数は {$変数名$} の形で記述ください。'}
      </p>
      <ul>
        <li>メール：email</li>
        <li>個別参照：cc　→ユーザ毎に別のccを入れたい場合</li>
      </ul>
    </div>
  );

  return (
    <Fragment>
      <div style={{ maxWidth: '800px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>メールテンプレート作成</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <div>
            <br />

            <Text strong>■ サーベイ選択</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Select
            showSearch
            style={{
              width: 600,
            }}
            placeholder="サーベイ選択"
            optionFilterProp="items"
            onChange={onChangeSurvey}
            value={value['survey_code']}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {surveyInfo.map((element) => {
              // console.log({ element });
              return (
                <Option value={element.survey_code} key={element._id}>
                  {element.survey_name}
                </Option>
              );
            })}
          </Select>
          <br />
          <br />
          <Text strong>■ テンプレート名（必須）</Text>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
          ></div>
          <Input
            placeholder="テンプレート名（必須）"
            name="mt_name"
            allowClear
            onChange={onChangeTemplateName}
            value={value['mt_name']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ テンプレート説明</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="テンプレート説明"
            name="mt_description"
            allowClear
            onChange={onChangeTemplateDescription}
            value={value['mt_description']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 件名（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="件名（必須）"
            name="subject"
            allowClear
            onChange={onChangeTitle}
            value={value['subject']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 差出人メール（システム事前登録が必要）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="差出人メール"
            name="from_mail"
            allowClear
            onChange={onChangeSenderMail}
            value={value['from_mail']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ 差出人名</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="差出人名"
            name="from_name"
            allowClear
            onChange={onChangeSenderName}
            value={value['from_name']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ メール本文（html）</Text>
            <Popover
              content={popOver1Handler}
              title="サーベイお知らせ配信で使えるシステム変数"
            >
              <Button type="primary" size="small">
                サーベイお知らせ配信用システム変数
              </Button>
            </Popover>
            　
            <Popover
              content={popOver2Handler}
              title="CSVから配信で使えるシステム変数"
            >
              <Button type="primary" size="small">
                CSVから配信用システム変数
              </Button>
            </Popover>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <TextArea
            showCount
            name="body"
            rows={20}
            placeholder="メール本文（html：改行は自動反映されます）"
            onChange={onChangeBody}
            value={value['body']}
          />
          <br />
          <br />
          <br />
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={false}
          >
            保存
          </Button>
          　
          <Button
            type="primary"
            size="large"
            onClick={onCancel}
            disabled={false}
          >
            キャンセル
          </Button>
          　
          <Button
            type="primary"
            danger
            size="large"
            onClick={onSubmitCopy}
            disabled={mode !== 'edit'}
          >
            複写
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default MakeTemplatePage;
