import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Card,
  Input,
  Select,
  Tabs,
  Radio,
  Space,
  Row,
  Col,
  Modal,
  TreeSelect,
  Spin,
} from 'antd';
import Axios from 'axios';
import config from '../../Config/key';
import { useSelector } from 'react-redux';
import CheckboxMenu from './CheckboxMenu';
import { CarryOutOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
let dpObj = {
  //所属部署の全ての経路を含むリスト、段階別に区別
  dp1: [],
  dp2: [],
  dp3: [],
  dp4: [],
  dp5: [],
};
let dpText = {
  //所属部署の全ての経路を含むリスト、段階別に区別
  dp1: '',
  dp2: '',
  dp3: '',
  dp4: '',
  dp5: '',
};

const PDF30010DowlnoadPage = () => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupDepartment, setSelGroupDepartment] = useState([]); //選択されたグループの部署のRadio Option、第一部署
  const [depArr, setDepArr] = useState([]); //選択されたグループの部署配列、第一部署
  const [selGroupDepartmentLong, setSelGroupDepartmentLong] = useState([]); //選択されたグループの部署のRadio Option、Full
  const [depArrLong, setDepArrLong] = useState([]); //選択されたグループの部署配列、Full
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  const [selQuestionSetId, setSelQuestionSetId] = useState(''); //全てのサーベイ情報
  const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [submitSW, setSubmitSW] = useState(true); //SubmitボタンDisable調整 true => 表示
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [positionArr, setPositionArr] = useState([]);
  const [levelCodeArr, setLevelCodeArr] = useState([]);
  const [goOutArr, setGoOutArr] = useState([]);
  const [depAll, setDepAll] = useState(0); //詳細、部署選択、0:全体、1:部署選択
  const [treeData, setTreeData] = useState([]);
  const [selTree, setSelTree] = useState('');
  // const [selDep, setSelDep] = useState([]);

  const [radio, setRadio] = useState({
    tabs: '1', //1:詳細、3:3軸比較, 7:7軸比較
    radio01: 0, //詳細、役職選択、0:全体、1:役職選択
    radio02: 0, //詳細、性別選択、0:全体、1:男性、2:女性
    radio03: depAll, //詳細、部署選択、0:全体、1:部署選択
    radio04: 0, //詳細、年齢指定,0:全体、1:年齢指定
    radio05: 0, //詳細、等級選択、0:全体、1:等級選択
    radio06: 0, //詳細、出向状態選択、0:全体、1:出向状態選択
    radio07: 19, //詳細、終了年齢指定
    radio08: 75, //詳細、終了年齢指定
    radio11: 0, //比較選択１、役職選択、0:全体、1:一般職、2：管理職
    radio12: 0, //比較選択１、性別選択、0:全体、1:男性、2:女性
    radio13: 0, //比較選択１、部署選択、0:全体、1:部署選択
    radio21: 0, //比較選択2、役職選択、0:全体、1:一般職、2：管理職
    radio22: 0, //比較選択2、性別選択、0:全体、1:男性、2:女性
    radio23: 0, //比較選択2、部署選択、0:全体、1:部署選択
    radio31: 0, //比較選択3、役職選択、0:全体、1:一般職、2：管理職
    radio32: 0, //比較選択3、性別選択、0:全体、1:男性、2:女性
    radio33: 0, //比較選択3、部署選択、0:全体、1:部署選択
    department0: '', //詳細、選択部署Text
    department1: '', //比較選択１、選択部署Text
    department2: '', //比較選択１、選択部署Text
    department3: '', //比較選択１、選択部署Text
    department11: '', //比較選択7、選択部署Text
    department12: '', //比較選択7、選択部署Text
    department13: '', //比較選択7、選択部署Text
    department14: '', //比較選択7、選択部署Text
    department15: '', //比較選択7、選択部署Text
    department16: '', //比較選択7、選択部署Text
    department17: '', //比較選択7、選択部署Text
    position: '',
    levelCode: '',
    goOut: '',
  });

  const usr = useSelector((state) => state.user);

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      setUserInfo(usr.userData);
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/30010'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      // setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);

      if (!groups) throw new Error('グループ取得に失敗しました。');
      if (!survey) throw new Error('サーベイ取得に失敗しました。');
      if (
        usr.userData.role === 3 ||
        usr.userData.role === 4 ||
        usr.userData.role === 6
      ) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );
        // console.log({ sgi });
        if (usr.userData.role === 4 || usr.userData.role === 6) {
          const d = Object.values(usr.userData.e3Department);
          // console.log({ d });
          let dd = [];
          let cnt = 0;
          for (let i = 0; i < d.length; i++) {
            if (d[i] === '') {
              cnt++;
            } else {
              const ddd = sgi.department.filter((item) => item.includes(d[i]));
              dd = dd.concat(ddd);
            }
          }
          // console.log({ dd });
          // console.log({ cnt });
          if (cnt < 10 && d.length > 0) {
            // setSelDep(dd);
            setDepAll(1);
            setTreeData(genTree2(sgi.department, dd));
            setSelTree(dd[0]);
            setRadio({
              ...radio,
              radio03: 1,
            });
          } else {
            setDepAll(0);
            setTreeData(genTree(sgi.department));
            setRadio({
              ...radio,
              radio03: 0,
            });
          }
        } else {
          setTreeData(genTree(sgi.department));
        }
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(
          sgi.survey_season.filter(
            (item) =>
              item.survey_code === '30010' || item.survey_code === '90301'
          )
        );
        initGroup(usr.userData.groupCode, sgi);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const initGroup = (value, sgi) => {
    // let sgi = groups.find((item) => item.group_code === value);
    // console.log({ groups });
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setPositionArr(sgi.position);
    setLevelCodeArr(sgi.level_code);
    setGoOutArr(sgi.go_out);
    setRadio({
      ...radio,
      department11: '',
      department12: '',
      department13: '',
      department14: '',
      department15: '',
      department16: '',
      department17: '',
      radio03: depAll,
    });
    dpText = {
      dp1: '',
      dp2: '',
      dp3: '',
      dp4: '',
      dp5: '',
    };
    // setGroupSurveySeasonInfo(sgi.survey_season);
    dpObj = {
      dp1: [],
      dp2: [],
      dp3: [],
      dp4: [],
      dp5: [],
    };
    // setGroupSurveySeasonInfo(sgi.survey_season);
    const dp = [];
    for (let i = 0; i < sgi.department.length; i++) {
      if (sgi.department[i].split('／').length < 2) {
        dp.push(sgi.department[i]);
      }
    }

    for (let i = 0; i < sgi.department.length; i++) {
      let dArr = sgi.department[i].split('／');
      if (dArr.length === 1) {
        dpObj.dp1.push(sgi.department[i]);
      }
      if (dArr.length === 2) {
        dpObj.dp2.push(sgi.department[i]);
      }
      if (dArr.length === 3) {
        dpObj.dp3.push(sgi.department[i]);
      }
      if (dArr.length === 4) {
        dpObj.dp4.push(sgi.department[i]);
      }
      if (dArr.length === 5) {
        dpObj.dp5.push(sgi.department[i]);
      }
    }
    let selectArr = [];
    for (let i = 0; i < dpObj.dp1.length; i++) {
      const element = dpObj.dp1[i];
      selectArr.push(<Option key={element}>{element}</Option>);
    }

    // console.log(dpObj);
    const child = [];
    const dep = [];
    const childLong = [];
    const depLong = [];

    for (let i = 0; i < dp.length; i++) {
      const element = dp[i];
      child.push(<Option key={element}>{element}</Option>);
      dep.push(element);
    }
    for (let i = 0; i < sgi.department.length; i++) {
      const element = sgi.department[i];
      childLong.push(<Option key={element}>{element}</Option>);
      depLong.push(element);
    }
    setSelGroupDepartmentLong(childLong); //部署選択RadioのOption、Longバージョン
    setDepArrLong(depLong); //部署名の配列、Longバージョン
    setSelGroupDepartment(child); //部署選択RadioのOption、第一部署
    setDepArr(dep); //部署名の配列、第一部署
  };

  const genTree = (depArr) => {
    const dpObj = {
      dp1: [],
      dp2: [],
      dp3: [],
      dp4: [],
      dp5: [],
    };
    let treeData = [];
    for (let i = 0; i < depArr.length; i++) {
      let dArr = depArr[i].split('／');
      if (dArr.length === 1) {
        dpObj.dp1.push(depArr[i]);
      }
      if (dArr.length === 2) {
        dpObj.dp2.push(depArr[i]);
      }
      if (dArr.length === 3) {
        dpObj.dp3.push(depArr[i]);
      }
      if (dArr.length === 4) {
        dpObj.dp4.push(depArr[i]);
      }
      if (dArr.length === 5) {
        dpObj.dp5.push(depArr[i]);
      }
    }
    for (let i = 0; i < dpObj.dp1.length; i++) {
      treeData.push({
        value: dpObj.dp1[i],
        title: dpObj.dp1[i],
        icon: <CarryOutOutlined />,
        children: [],
      });
    }
    for (let i = 0; i < dpObj.dp2.length; i++) {
      const dArr = dpObj.dp2[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.push({
            value: dpObj.dp2[i],
            title: dArr[1],
            icon: <CarryOutOutlined />,
            children: [],
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp3.length; i++) {
      const dArr = dpObj.dp3[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp3[i].includes(oo.value)) {
              oo.children.push({
                value: dpObj.dp3[i],
                title: dArr[2],
                icon: <CarryOutOutlined />,
                children: [],
              });
            }
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp4.length; i++) {
      const dArr = dpObj.dp4[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp4[i].includes(oo.value)) {
              oo.children.map((ooo) => {
                if (dpObj.dp4[i].includes(ooo.value)) {
                  ooo.children.push({
                    value: dpObj.dp4[i],
                    title: dArr[3],
                    icon: <CarryOutOutlined />,
                    children: [],
                  });
                }
              });
            }
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp5.length; i++) {
      const dArr = dpObj.dp5[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp5[i].includes(oo.value)) {
              oo.children.map((ooo) => {
                if (dpObj.dp5[i].includes(ooo.value)) {
                  ooo.children.map((oooo) => {
                    if (dpObj.dp5[i].includes(oooo.value)) {
                      oooo.children.push({
                        value: dpObj.dp5[i],
                        title: dArr[4],
                        icon: <CarryOutOutlined />,
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    // console.log(dpObj);
    // console.log(treeData);
    return treeData;
  };
  const genTree2 = (depArr, dd) => {
    const dpObj = {
      dp1: [],
      dp2: [],
      dp3: [],
      dp4: [],
      dp5: [],
    };
    let treeData = [];
    for (let i = 0; i < depArr.length; i++) {
      let dArr = depArr[i].split('／');
      if (dArr.length === 1) {
        dpObj.dp1.push(depArr[i]);
      }
      if (dArr.length === 2) {
        dpObj.dp2.push(depArr[i]);
      }
      if (dArr.length === 3) {
        dpObj.dp3.push(depArr[i]);
      }
      if (dArr.length === 4) {
        dpObj.dp4.push(depArr[i]);
      }
      if (dArr.length === 5) {
        dpObj.dp5.push(depArr[i]);
      }
    }
    for (let i = 0; i < dpObj.dp1.length; i++) {
      if (dd.includes(dpObj.dp1[i])) {
        treeData.push({
          value: dpObj.dp1[i],
          title: dpObj.dp1[i],
          icon: <CarryOutOutlined />,
          children: [],
          disabled: false,
        });
      } else {
        treeData.push({
          value: dpObj.dp1[i],
          title: dpObj.dp1[i],
          icon: <CarryOutOutlined />,
          children: [],
          disabled: true,
        });
      }
    }
    for (let i = 0; i < dpObj.dp2.length; i++) {
      if (dd.includes(dpObj.dp2[i])) {
        const dArr = dpObj.dp2[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.push({
              value: dpObj.dp2[i],
              title: dArr[1],
              icon: <CarryOutOutlined />,
              children: [],
              disabled: false,
            });
          }
        });
      } else {
        const dArr = dpObj.dp2[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.push({
              value: dpObj.dp2[i],
              title: dArr[1],
              icon: <CarryOutOutlined />,
              children: [],
              disabled: true,
            });
          }
        });
      }
    }
    for (let i = 0; i < dpObj.dp3.length; i++) {
      if (dd.includes(dpObj.dp3[i])) {
        const dArr = dpObj.dp3[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp3[i].includes(oo.value)) {
                oo.children.push({
                  value: dpObj.dp3[i],
                  title: dArr[2],
                  icon: <CarryOutOutlined />,
                  children: [],
                  disabled: false,
                });
              }
            });
          }
        });
      } else {
        const dArr = dpObj.dp3[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp3[i].includes(oo.value)) {
                oo.children.push({
                  value: dpObj.dp3[i],
                  title: dArr[2],
                  icon: <CarryOutOutlined />,
                  children: [],
                  disabled: true,
                });
              }
            });
          }
        });
      }
    }
    for (let i = 0; i < dpObj.dp4.length; i++) {
      if (dd.includes(dpObj.dp4[i])) {
        const dArr = dpObj.dp4[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp4[i].includes(oo.value)) {
                oo.children.map((ooo) => {
                  if (dpObj.dp4[i].includes(ooo.value)) {
                    ooo.children.push({
                      value: dpObj.dp4[i],
                      title: dArr[3],
                      icon: <CarryOutOutlined />,
                      children: [],
                      disabled: false,
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        const dArr = dpObj.dp4[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp4[i].includes(oo.value)) {
                oo.children.map((ooo) => {
                  if (dpObj.dp4[i].includes(ooo.value)) {
                    ooo.children.push({
                      value: dpObj.dp4[i],
                      title: dArr[3],
                      icon: <CarryOutOutlined />,
                      children: [],
                      disabled: true,
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    for (let i = 0; i < dpObj.dp5.length; i++) {
      if (dd.includes(dpObj.dp5[i])) {
        const dArr = dpObj.dp5[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp5[i].includes(oo.value)) {
                oo.children.map((ooo) => {
                  if (dpObj.dp5[i].includes(ooo.value)) {
                    ooo.children.map((oooo) => {
                      if (dpObj.dp5[i].includes(oooo.value)) {
                        oooo.children.push({
                          value: dpObj.dp5[i],
                          title: dArr[4],
                          icon: <CarryOutOutlined />,
                          disabled: false,
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        const dArr = dpObj.dp5[i].split('／');
        treeData.map((o) => {
          if (o.value === dArr[0]) {
            o.children.map((oo) => {
              if (dpObj.dp5[i].includes(oo.value)) {
                oo.children.map((ooo) => {
                  if (dpObj.dp5[i].includes(ooo.value)) {
                    ooo.children.map((oooo) => {
                      if (dpObj.dp5[i].includes(oooo.value)) {
                        oooo.children.push({
                          value: dpObj.dp5[i],
                          title: dArr[4],
                          icon: <CarryOutOutlined />,
                          disabled: true,
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    // console.log(dpObj);
    // console.log(treeData);
    return treeData;
  };

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log({ groups });
    setTreeData(genTree(sgi.department));
    setSelTree('');
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setPositionArr(sgi.position);
    setLevelCodeArr(sgi.level_code);
    setGoOutArr(sgi.go_out);
    setRadio({
      ...radio,
      department11: '',
      department12: '',
      department13: '',
      department14: '',
      department15: '',
      department16: '',
      department17: '',
      radio03: depAll,
    });
    dpText = {
      dp1: '',
      dp2: '',
      dp3: '',
      dp4: '',
      dp5: '',
    };
    // setGroupSurveySeasonInfo(sgi.survey_season);
    dpObj = {
      dp1: [],
      dp2: [],
      dp3: [],
      dp4: [],
      dp5: [],
    };
    // setGroupSurveySeasonInfo(sgi.survey_season);
    const dp = [];
    for (let i = 0; i < sgi.department.length; i++) {
      if (sgi.department[i].split('／').length < 2) {
        dp.push(sgi.department[i]);
      }
    }

    for (let i = 0; i < sgi.department.length; i++) {
      let dArr = sgi.department[i].split('／');
      if (dArr.length === 1) {
        dpObj.dp1.push(sgi.department[i]);
      }
      if (dArr.length === 2) {
        dpObj.dp2.push(sgi.department[i]);
      }
      if (dArr.length === 3) {
        dpObj.dp3.push(sgi.department[i]);
      }
      if (dArr.length === 4) {
        dpObj.dp4.push(sgi.department[i]);
      }
      if (dArr.length === 5) {
        dpObj.dp5.push(sgi.department[i]);
      }
    }
    let selectArr = [];
    for (let i = 0; i < dpObj.dp1.length; i++) {
      const element = dpObj.dp1[i];
      selectArr.push(<Option key={element}>{element}</Option>);
    }

    // setDpState1(...dpState1, dpObj.dp1 )

    // console.log(dpObj);
    const child = [];
    const dep = [];
    const childLong = [];
    const depLong = [];

    for (let i = 0; i < dp.length; i++) {
      const element = dp[i];
      child.push(<Option key={element}>{element}</Option>);
      dep.push(element);
    }
    for (let i = 0; i < sgi.department.length; i++) {
      const element = sgi.department[i];
      childLong.push(<Option key={element}>{element}</Option>);
      depLong.push(element);
    }
    setSelGroupDepartmentLong(childLong); //部署選択RadioのOption、Longバージョン
    setDepArrLong(depLong); //部署名の配列、Longバージョン
    setSelGroupDepartment(child); //部署選択RadioのOption、第一部署
    setDepArr(dep); //部署名の配列、第一部署

    setGroupSurveySeasonInfo(
      sgi.survey_season.filter(
        (item) => item.survey_code === '30010' || item.survey_code === '90301'
      )
    );
    setSelSurveySeasonId('');
    setSelQuestionSetId('');
    setQuestionSet([]);
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);

    setRadio({
      tabs: '1', //1:詳細、3:3軸比較, 7:7軸比較
      radio01: 0, //詳細、役職選択、0:全体、1:役職選択
      radio02: 0, //詳細、性別選択、0:全体、1:男性、2:女性
      radio03: depAll, //詳細、部署選択、0:全体、1:部署選択
      radio04: 0, //詳細、年齢指定,0:全体、1:年齢指定
      radio05: 0, //詳細、等級選択、0:全体、1:等級選択
      radio06: 0, //詳細、出向状態選択、0:全体、1:出向状態選択
      radio07: 19, //詳細、終了年齢指定
      radio08: 75, //詳細、終了年齢指定
      radio11: 0, //比較選択１、役職選択、0:全体、1:一般職、2：管理職
      radio12: 0, //比較選択１、性別選択、0:全体、1:男性、2:女性
      radio13: 0, //比較選択１、部署選択、0:全体、1:部署選択
      radio21: 0, //比較選択2、役職選択、0:全体、1:一般職、2：管理職
      radio22: 0, //比較選択2、性別選択、0:全体、1:男性、2:女性
      radio23: 0, //比較選択2、部署選択、0:全体、1:部署選択
      radio31: 0, //比較選択3、役職選択、0:全体、1:一般職、2：管理職
      radio32: 0, //比較選択3、性別選択、0:全体、1:男性、2:女性
      radio33: 0, //比較選択3、部署選択、0:全体、1:部署選択
      department0: '', //詳細、選択部署Text
      department1: '', //比較選択１、選択部署Text
      department2: '', //比較選択１、選択部署Text
      department3: '', //比較選択１、選択部署Text
      department11: '', //比較選択7、選択部署Text
      department12: '', //比較選択7、選択部署Text
      department13: '', //比較選択7、選択部署Text
      department14: '', //比較選択7、選択部署Text
      department15: '', //比較選択7、選択部署Text
      department16: '', //比較選択7、選択部署Text
      department17: '', //比較選択7、選択部署Text
      position: '',
      levelCode: '',
      goOut: '',
    });

    // console.log({ sssi });
    const ssi = survey.find((item) => item._id === sssi.survey_id);
    setSelSurveyInfo(ssi);
    setQuestionSet(sssi.question_set);
    if (sssi.question_set.length > 0) {
      setSelQuestionSetId(sssi.question_set[0]._id);
    } else {
      setSelQuestionSetId('');
    }
    // console.log({ selQuestionSetId });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      // console.log({ radio });
      if (radio.tabs === '7') {
        const dp = [];
        if (radio.department11) dp.push(radio.department11);
        if (radio.department12) dp.push(radio.department12);
        if (radio.department13) dp.push(radio.department13);
        if (radio.department14) dp.push(radio.department14);
        if (radio.department15) dp.push(radio.department15);
        if (radio.department16) dp.push(radio.department16);
        if (radio.department17) dp.push(radio.department17);
        if (dp.length < 3) {
          throw new Error('部署を３個以上指定してください。');
        }
      }
      let sendData = {};
      if (radio.tabs === '1') {
        let dText = '';
        // console.log({ dpText });
        if (dpText.dp1) {
          dText = dpText.dp1;
        }
        if (dpText.dp2) {
          dText = `${dText}／${dpText.dp2}`;
        }
        if (dpText.dp3) {
          dText = `${dText}／${dpText.dp3}`;
        }
        if (dpText.dp4) {
          dText = `${dText}／${dpText.dp4}`;
        }
        if (dpText.dp5) {
          dText = `${dText}／${dpText.dp5}`;
        }
        // console.log({ radio });
        // console.log({ depAll });

        sendData = {
          selGroupInfo: selGroupInfo,
          selSurveySeasonInfo: selSurveySeasonInfo,
          searchInfo: { ...radio, department0: selTree },
        };
      } else {
        sendData = {
          selGroupInfo: selGroupInfo,
          selSurveySeasonInfo: selSurveySeasonInfo,
          searchInfo: radio,
        };
      }
      // console.log({ sendData });
      const res = await Axios.post('/api/dbModel100/makeReport30010', sendData);
      setIsModalVisible(true);
      setSubmitSW(false);
      const url = `${config.S3_URL}/${res.data.s3Path}`;
      // console.log({ url });
      setTimeout(() => {
        message.success({
          content: 'ファイル生成完了！',
          key: 'makecsv',
          duration: 2,
        });
        setSubmitSW(true);
        setIsModalVisible(false);

        window.open(url, '_blank');
      }, 3000);
    } catch (error) {
      console.log(error.message);
      setIsModalVisible(false);
      message.error({
        content: '対象者が少ないため相関関係の計算ができません。',
        key: 'makecsv',
        duration: 5,
      });

      setSelTree('');
      setRadio({
        tabs: '1', //1:詳細、3:3軸比較, 7:7軸比較
        radio01: 0, //詳細、役職選択、0:全体、1:役職選択
        radio02: 0, //詳細、性別選択、0:全体、1:男性、2:女性
        radio03: depAll, //詳細、部署選択、0:全体、1:部署選択
        radio04: 0, //詳細、年齢指定,0:全体、1:年齢指定
        radio05: 0, //詳細、等級選択、0:全体、1:等級選択
        radio06: 0, //詳細、出向状態選択、0:全体、1:出向状態選択
        radio07: 19, //詳細、終了年齢指定
        radio08: 75, //詳細、終了年齢指定
        radio11: 0, //比較選択１、役職選択、0:全体、1:一般職、2：管理職
        radio12: 0, //比較選択１、性別選択、0:全体、1:男性、2:女性
        radio13: 0, //比較選択１、部署選択、0:全体、1:部署選択
        radio21: 0, //比較選択2、役職選択、0:全体、1:一般職、2：管理職
        radio22: 0, //比較選択2、性別選択、0:全体、1:男性、2:女性
        radio23: 0, //比較選択2、部署選択、0:全体、1:部署選択
        radio31: 0, //比較選択3、役職選択、0:全体、1:一般職、2：管理職
        radio32: 0, //比較選択3、性別選択、0:全体、1:男性、2:女性
        radio33: 0, //比較選択3、部署選択、0:全体、1:部署選択
        department0: '', //詳細、選択部署Text
        department1: '', //比較選択１、選択部署Text
        department2: '', //比較選択１、選択部署Text
        department3: '', //比較選択１、選択部署Text
        department11: '', //比較選択7、選択部署Text
        department12: '', //比較選択7、選択部署Text
        department13: '', //比較選択7、選択部署Text
        department14: '', //比較選択7、選択部署Text
        department15: '', //比較選択7、選択部署Text
        department16: '', //比較選択7、選択部署Text
        department17: '', //比較選択7、選択部署Text
        position: '',
        levelCode: '',
        goOut: '',
      });
    }
  };
  const onChangeTabs = async (key) => {
    // console.log('Tabbed', key);
    setRadio({ ...radio, tabs: key });
  };
  const onChangeRadio01 = async (e) => {
    // console.log('radio01 checked', e.target.value);
    setRadio({ ...radio, radio01: e.target.value });
  };
  const onChangeRadio02 = async (e) => {
    // console.log('radio02 checked', e.target.value);
    setRadio({ ...radio, radio02: e.target.value });
  };
  const onChangeRadio03 = async (e) => {
    // console.log('radio03 checked', e.target.value);
    if (e.target.value === 0) {
      setRadio({
        ...radio,
        radio03: e.target.value,
      });
      setSelTree('');
      dpText = {
        dp1: '',
        dp2: '',
        dp3: '',
        dp4: '',
        dp5: '',
      };
    } else {
      setRadio({ ...radio, radio03: e.target.value });
    }
  };
  const onChangeRadio04 = async (e) => {
    //年齢Option
    // console.log('radio13 checked', e.target.value);
    setRadio({ ...radio, radio04: e.target.value });
  };
  const onChangeRadio07 = async (e) => {
    //開始年代
    // console.log('radio13 checked', e.target.value);
    setRadio({ ...radio, radio07: e.target.value });
  };
  const onChangeRadio08 = async (e) => {
    //終了年代
    // console.log('radio13 checked', e.target.value);
    setRadio({ ...radio, radio08: e.target.value });
  };
  const onChangeRadio05 = async (e) => {
    //等級
    // console.log('radio13 checked', e.target.value);
    setRadio({ ...radio, radio05: e.target.value });
  };
  const onChangeRadio06 = async (e) => {
    //出向状態
    // console.log('radio13 checked', e.target.value);
    setRadio({ ...radio, radio06: e.target.value });
  };

  const onChangePosition = async (value) => {
    // console.log('Selected', value);
    setRadio({ ...radio, position: value });
  };
  const onChangeLevelCode = async (value) => {
    // console.log('Selected', value);
    setRadio({ ...radio, levelCode: value });
  };
  const onChangeGoOut = async (value) => {
    // console.log('Selected', value);
    setRadio({ ...radio, goOut: value });
  };

  const handleChangeTree0 = (value, label, extra) => {
    // console.log({ value });
    setSelTree(value);
    if (value) {
      setRadio({
        ...radio,
        radio03: 1,
      });
    } else {
      setRadio({
        ...radio,
        radio03: 0,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '1100px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>E³-Survey結果ダウンロード(PDF)</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          {(userInfo.role === 9 || userInfo.role === 2) && (
            <Text strong>企業を選択してください。（必須）</Text>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={
                userInfo.role === 3 ||
                userInfo.role === 4 ||
                userInfo.role === 6
              }
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <br />
          <br />
          <Title>❸</Title>
          {/* <Text strong>結果フォーマットを選択してください。（必須）</Text> */}
          <Tabs
            onChange={onChangeTabs}
            type="card"
            size="large"
            style={{ marginBottom: 30 }}
          >
            <TabPane tab="E³-Survey詳細結果" key="1">
              <Card
                type="inner"
                style={{ marginTop: 16, width: 350 }}
                title="役職選択"
              >
                <Radio.Group onChange={onChangeRadio01} value={radio.radio01}>
                  <Space direction="vertical">
                    <Radio value={0}>全体（選択クリア）</Radio>
                    {/* <Radio value={1}>一般職クラス</Radio>
                    <Radio value={2}>管理職クラス</Radio> */}
                    <Radio value={1}>
                      役職選択 ▶︎
                      {radio.radio01 === 1 ? (
                        <CheckboxMenu
                          options={positionArr}
                          value={[]}
                          onChange={onChangePosition}
                        />
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Card>
              <Card
                type="inner"
                style={{ marginTop: 16, width: 350 }}
                title="等級選択"
              >
                <Radio.Group onChange={onChangeRadio05} value={radio.radio05}>
                  <Space direction="vertical">
                    <Radio value={0}>全体（選択クリア）</Radio>
                    {/* <Radio value={1}>一般職クラス</Radio>
                    <Radio value={2}>管理職クラス</Radio> */}
                    <Radio value={1}>
                      等級選択 ▶︎
                      {radio.radio05 === 1 ? (
                        <CheckboxMenu
                          options={levelCodeArr}
                          value={[]}
                          onChange={onChangeLevelCode}
                        />
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Card>
              <Card
                type="inner"
                style={{ marginTop: 16, width: 350 }}
                title="出向状態選択"
              >
                <Radio.Group onChange={onChangeRadio06} value={radio.radio06}>
                  <Space direction="vertical">
                    <Radio value={0}>全体（選択クリア）</Radio>
                    <Radio value={1}>
                      出向状態選択 ▶︎
                      {radio.radio06 === 1 ? (
                        <CheckboxMenu
                          options={goOutArr}
                          value={[]}
                          onChange={onChangeGoOut}
                        />
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Card>
              <Card
                type="inner"
                style={{ marginTop: 16, width: 350 }}
                title="性別選択"
              >
                <Radio.Group onChange={onChangeRadio02} value={radio.radio02}>
                  <Space direction="vertical">
                    <Radio value={0}>全体</Radio>
                    <Radio value={1}>男性</Radio>
                    <Radio value={2}>女性</Radio>
                  </Space>
                </Radio.Group>
              </Card>
              <Card
                title="年代選択"
                type="inner"
                style={{ marginTop: 16, width: 350 }}
              >
                <Radio.Group onChange={onChangeRadio04} value={radio.radio04}>
                  <Space direction="vertical">
                    <Radio value={0}>全体</Radio>
                    <Radio value={1}>年齢指定</Radio>
                  </Space>
                </Radio.Group>
                <br />
                <Input
                  style={{ marginTop: 16, width: 80 }}
                  placeholder="年齢"
                  name="startAge"
                  allowClear
                  onChange={onChangeRadio07}
                  value={radio.radio07}
                  disabled={radio.radio04 !== 1}
                />
                　歳　〜　
                <Input
                  style={{ width: 80 }}
                  placeholder="年齢"
                  name="endAge"
                  allowClear
                  onChange={onChangeRadio08}
                  value={radio.radio08}
                  disabled={radio.radio04 !== 1}
                />
                　歳
              </Card>
              <Card
                type="inner"
                style={{ marginTop: 16, width: 700 }}
                title="部署選択"
                extra={
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => handleChangeTree0('', null, null)}
                    disabled={!selTree || depAll}
                  >
                    選択クリア
                  </Button>
                }
              >
                <Space direction="vertical">
                  <Text>部署選択 ▶︎ {selTree ? selTree : '全部署'} </Text>
                  <TreeSelect
                    treeLine={true}
                    style={{
                      width: 600,
                    }}
                    treeData={treeData}
                    onSelect={handleChangeTree0}
                    value={selTree}
                  />
                </Space>
              </Card>
            </TabPane>
          </Tabs>
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!(submitSW && selSurveySeasonId)}
          >
            ダウンロード
          </Button>
        </Form>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
};

export default PDF30010DowlnoadPage;
