import Axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import config from '../../Config/key';
import { userRole } from '../../Config/userRole';
import { Table, Divider, Tag, Button, message } from 'antd';
import { useSelector } from 'react-redux';
const { Column, ColumnGroup } = Table;

function UserMgrPage(props) {
  const [Users, setUsers] = useState([]);
  // const { usr } = props;
  const usr = useSelector((state) => state.user);

  // if (!usr.userData) return null;
  // const { isAdmin } = usr.userData;

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      const res = await Axios.get(config.USER_SERVER + '/manager');
      let userData = res.data;
      await userData.map((user) => {
        user.fullName = user.lastname + ' ' + user.name;
        user.roleText = userRole[user.role];
        return user;
      });
      setUsers(userData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //if (!usr) return null;
    readDB();
    // console.log({ user });
  }, [usr]);

  const roleChangeHandler = async (userId, role, e) => {
    e.preventDefault();
    try {
      const res = await Axios.patch(`${config.USER_SERVER}/${userId}/role`, {
        role: role,
      });
      // console.log(res);
      if (res.data.success) {
        await readDB();
        message.success('権限を変更しました。');
      }
    } catch (error) {
      alert('権限変更に失敗しました。');
      console.log(error);
    }
  };

  //削除機能は使わない
  const userDeleteHandler = async (userId, e) => {
    e.preventDefault();
    try {
      // let dataToSubmit = {
      //   userId: userId,
      // };
      const res = await Axios.delete(config.USER_SERVER + '/' + userId);
      // console.log(res);
      if (res.data.success) {
        await readDB();
        message.success('ユーザを削除しました。');
      }
    } catch (error) {
      alert('ユーザ削除に失敗しました。');
      console.log(error);
    }
  };

  // return <div>User Management Page2</div>;
  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={Users} size="middle" rowKey="email">
        <Column
          title="名前"
          dataIndex="fullName"
          key="fullName"
          aligh="right"
        />
        <Column title="メール" dataIndex="email" key="email" aligh="center" />
        <Column title="会社" dataIndex="company" key="company" aligh="center" />
        <Column
          title="部署"
          dataIndex="department"
          key="department"
          aligh="center"
        />

        <Column
          title="権限付与"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}
              <Button
                type={record.role === 0 ? 'primary' : 'default'}
                size="small"
                onClick={(e) => roleChangeHandler(record._id, 1, e)}
                disabled={record.role === 9 ? true : false}
              >
                管理者機能停止
              </Button>
              <Divider type="vertical" />
              <Button
                type={record.role === 2 ? 'primary' : 'default'}
                size="small"
                onClick={(e) => roleChangeHandler(record._id, 2, e)}
                disabled={record.role === 9 ? true : false}
              >
                管理者
              </Button>
              <Divider type="vertical" />
              <Button
                type={record.role === 9 ? 'primary' : 'default'}
                size="small"
                onClick={(e) => roleChangeHandler(record._id, 9, e)}
                disabled={usr.userData.role !== 9 ? true : false}
              >
                最高管理者
              </Button>
            </span>
          )}
        />
        {/* <Column
          title="削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => userDeleteHandler(record._id, e)}
                disabled={
                  record.role === 9 || usr.userData.role !== 9 ? true : false
                }
              >
                ユーザ削除
              </Button>
            </span>
          )}
        /> */}
      </Table>
    </div>
  );
}

export default UserMgrPage;
