import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, message } from 'antd';
import { surveyCode } from '../../Config/surveyCode';

const { Column } = Table;

const SeasonListPage = (props) => {
  // console.log({ props });
  const { user } = props;
  const [seasonInfo, setSeasonInfo] = useState([]);

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const res = await Axios.get('/api/surveySeason/');
        // console.log(res.data.groups);
        if (res.data.success) {
          let ssArr = res.data.surveySeason;
          ssArr.map((element) => {
            element.survey_name = surveyCode[element.survey_code];
            return element;
          });
          // console.log(ssArr);
          setSeasonInfo(ssArr);
        } else {
          throw new Error('グループ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  const editSeasonHandler = async (sInfo, e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/makeseason',
        state: sInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const newSeasonHandler = async (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/makeseason',
        state: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={seasonInfo} size="middle" rowKey="_id">
        <Column
          title="サーベイ・シーズン名"
          dataIndex="season_name"
          key="season_name"
          aligh="left"
        />
        <Column
          title="シーズン説明"
          dataIndex="season_description"
          key="season_description"
          aligh="left"
        />
        <Column
          title="グループコード"
          dataIndex="group_code"
          key="group_code"
          aligh="left"
        />
        <Column
          title="サーベイ種類"
          dataIndex="survey_name"
          key="survey_code"
          aligh="left"
        />
        <Column title="研修名" dataIndex="t_name" key="t_name" aligh="left" />
        <Column
          title="編集"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}
              <Button
                type={'primary'}
                size="small"
                onClick={(e) => editSeasonHandler(record, e)}
                disabled={false}
              >
                編集
              </Button>
            </span>
          )}
        />
        {/* <Column
          title="サーベイ削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => surveyDeleteHandler(record._id, e)}
                disabled={record.survey_status === 5 ? false : true}
              >
                サーベイ削除
              </Button>
            </span>
          )}
        /> */}
      </Table>

      <Button
        type={'primary'}
        size="small"
        onClick={(e) => newSeasonHandler(e)}
      >
        新規シーズン作成
      </Button>
    </div>
  );
};

export default SeasonListPage;
