//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { confirm } = Modal;

const CSVMailSendPage = (props) => {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [mtInfo, setMtInfo] = useState([]);
  const [ccMailValue, setCCMailValue] = useState('');
  const [bccMailValue, setBCCMailValue] = useState('');
  const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  const [selSurvey, setSelSurvey] = useState([]); //サーベイ名とサーベイコード

  // const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [selMtId, setSelMtId] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onDrop = async (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          //const courseInfo = courseMerge(results.data);
          // setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          // console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
    const [mtInfo, surveyInfo] = await Promise.all([
      Axios.get('/api/mailTemplate/'),
      Axios.get('/api/survey/'),
    ]);
    setMtInfo(mtInfo.data.mailTemplate);
    setSurvey(surveyInfo.data.survey);
    // console.log(res.data.mailTemplate);
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const sendMailHandler = async () => {
    let mailList = {}; //最終分類用
    try {
      message.loading({ content: 'メール送信中...', key: 'csvupload' });
      if (!selMtId) {
        message.error({
          content: 'メールテンプレートを選択してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('テンプレートIDがありません。');
      }

      // console.log({ fileJSON });
      const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      // console.log({ selMtInfo });

      // const ccString = ccMailValue
      //   .replace(/\s+/g, '') //文字列の中の空白を削除
      //   .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
      // const bccString = bccMailValue
      //   .replace(/\s+/g, '') //文字列の中の空白を削除
      //   .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
      // let cc = [];
      // let bcc = [];
      // let ccArr = [];
      // let bccArr = [];
      // if (ccString) {
      //   cc = ccString.split(';');
      //   cc.map((element) => {
      //     ccArr.push({ email: element });
      //   });
      // }
      // if (bccString) {
      //   bcc = bccString.split(';');
      //   bcc.map((element) => {
      //     bccArr.push({ email: element });
      //   });
      // }

      let personalizations = [];
      await fileJSON.map((record) => {
        let ccText;
        if (record.cc) {
          ccText = ccMailValue + ';' + record.cc;
        } else {
          ccText = ccMailValue;
        }
        const ccString = ccText
          .replace(/\s+/g, '') //文字列の中の空白を削除
          .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
        let bccText;
        if (record.bcc) {
          bccText = bccMailValue + ';' + record.bcc;
        } else {
          bccText = bccMailValue + ';';
        }
        // console.log(bccText);
        const bccString = bccText
          .replace(/\s+/g, '') //文字列の中の空白を削除
          .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
        let cc = [];
        let bcc = [];
        let ccArr = [];
        let bccArr = [];
        if (ccString) {
          cc = ccString.split(';');
          cc.map((element) => {
            ccArr.push({ email: element });
          });
        }
        // console.log(bccString);
        if (bccString) {
          bcc = bccString.split(';');
          bcc.map((element) => {
            bccArr.push({ email: element });
          });
        }
        personalizations.push({
          to: record.email,
          cc: ccArr,
          bcc: bccArr,
          substitutions: record,
        });
      });
      // console.log({ personalizations });
      // console.log({ mtInfo });
      mailList = {
        personalizations: personalizations,
        from: {
          email: selMtInfo.from_mail, // 送信元アドレス
          name: selMtInfo.from_name, // 送信者名
        },
        subject: selMtInfo.subject,
        html: selMtInfo.body.replace(/\r?\n/g, '<br>'),
        substitutionWrappers: ['{$', '$}'],
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      if (sendMailResult.data.success) {
        // if (false) {
        setFileData('メール送信を完了しました。');
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 3,
        });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log({ error });

      message.error({
        content: 'メール送信に失敗しました。',
        key: 'mail',
        duration: 3,
      });
    }
  };

  const onChangeMtInfo = (value) => {
    if (value) {
      setSelMtId(value);
      setSubmitButtonDisable(false);
    }
    // console.log(e);
  };

  const showConfirm = async () => {
    confirm({
      title: 'メールを本当に配信しますか？',
      content:
        'メール配信処理が完了し、ホームへ自動遷移されるまで、そのままお待ちください。',
      async onOk() {
        const result = await sendMailHandler();
        if (result) {
          message.success({
            content: 'メールの配信が完了しました。ホームに戻ります。',
            key: 'mail',
            duration: 2,
          });
          props.history.push('/');
        } else {
          message.error({
            content: 'メール送信に失敗しました。',
            key: 'mail',
            duration: 2,
          });
          throw new Error('メール送信に失敗しました。');
        }
      },
      async onCancel() {
        message.success({
          content: 'メール配信を取り消しました。',
          key: 'mail',
          duration: 2,
        });
      },
    });
  };
  const onChangeSurvey = (v) => {
    setSelSurvey(survey.find((item) => item.survey_code === v));
    setSelMtId('');
  };
  const onChangeCCHandler = (e) => {
    setCCMailValue(e.currentTarget.value);
  };

  const onChangeBCCHandler = (e) => {
    setBCCMailValue(e.currentTarget.value);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>CSVからメール送信</Title>
        </div>
        <Form onSubmit={showConfirm}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>メールテンプレートを選択してください。（必須）</Text>
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{
                  width: 400,
                }}
                placeholder="サーベイ選択"
                optionFilterProp="items"
                onChange={onChangeSurvey}
                value={selSurvey.survey_code}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {survey.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.survey_code} key={element._id}>
                      {element.survey_name}
                    </Option>
                  );
                })}
              </Select>
              <Select
                showSearch
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: 600,
                }}
                placeholder="テンプレート選択"
                optionFilterProp="items"
                onChange={onChangeMtInfo}
                value={selMtId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {mtInfo.map((element) => {
                  // console.log({ element });
                  if (element.survey_code === selSurvey.survey_code) {
                    return (
                      <Option value={element._id} key={element._id}>
                        {element.mt_name}
                      </Option>
                    );
                  }
                })}
              </Select>
            </div>
            <br />
          </div>
          <Title>❸</Title>
          <Text strong>CC・BCCを入力してください。</Text> <br />
          <br />
          CC：
          <Input
            placeholder="メールアドレスを「;」区切りで入力してください"
            allowClear
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 350,
            }}
            onChange={onChangeCCHandler}
            value={ccMailValue}
          />{' '}
          <br />
          BCC：
          <Input
            placeholder="メールアドレスを「;」区切りで入力してください"
            allowClear
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 350,
            }}
            onChange={onChangeBCCHandler}
            value={bccMailValue}
          />
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={showConfirm}
            disabled={submitButtonDisable}
          >
            メール送信
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
};

export default CSVMailSendPage;
