import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Steps, Button, message, Modal } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import GuidePage from './Sections/GuidePage';
import FixedQuestionPage from './Sections/FixedQuestionPage';
import SubmitSurveyPage from './Sections/SubmitSurveyPage';
const { Step } = Steps;
const { confirm } = Modal;

export const UserContext = React.createContext();

function NewTakeSurveyPage(props) {
  // const [tokenInfo, setTokenInfo] = useState();
  // const [questionInfo, setQuestionInfo] = useState([]);
  // const [stepArr, setStepArr] = useState([]);
  const [current, setCurrent] = useState(0);
  const [answer, setAnswer] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);
  const value = { answer, setAnswer };
  // const { tokenId } = props.match.params; //外部から持ってくるProjectID
  const studyStartDate = Date.now();
  const { stepArr } = props.location.state;
  const { tokenInfo } = props.location.state;
  const { questionInfo } = props.location.state;
  // const { questionSet } = props.location.state;
  tokenInfo.user = props.user.userData;
  tokenInfo.questionSet = props.location.state.questionSet;
  const queLen = questionInfo.length;

  // console.log({ questionInfo });
  // console.log({ props });

  let steps = [
    // ...stepArr,
    {
      key: 0,
      title: '案内',
      content: <GuidePage tokenInfo={tokenInfo} />,
    },
  ];

  for (let i = 0; i < stepArr.length; i++) {
    const element = stepArr[i];
    steps.push({
      key: i + 1,
      title: '',
      content: (
        <UserContext.Provider value={value}>
          <FixedQuestionPage
            tokenInfo={tokenInfo}
            section={element.section}
            questionInfo={element.questionInfo}
          />
        </UserContext.Provider>
      ),
    });
  }

  steps.push({
    key: stepArr.length + 1,
    title: '提出',
    content: (
      <UserContext.Provider value={value}>
        <SubmitSurveyPage
          props={props}
          tokenInfo={tokenInfo}
          studyStartDate={studyStartDate}
        />
      </UserContext.Provider>
    ),
  });

  const pageAnsCheck = (qi) => {
    const rq = qi.filter((item) => item.required === true);
    // console.log({ rq });
    // console.log({ answer });
    for (let i = 0; i < rq.length; i++) {
      if (!answer[rq[i].no]) return false;
      if (rq[i].free_after) {
        if (answer[rq[i].no] === rq[i].free_after && !answer[rq[i].no + 1000]) {
          return false;
        }
      }
    }
    return true;
  };

  const nextHandler = () => {
    if (current !== 0) {
      const pc = pageAnsCheck(stepArr[current - 1].questionInfo);
      // console.log({ pc });
      if (pc) {
        setCurrent(current + 1);
        window.scrollTo({
          top: 0,
        });
      } else {
        message.error({
          content: '全ての必須設問にお答えください。',
          key: 'pageCheck',
          duration: 4,
        });
      }
      // console.log({ tf });
      // console.log(stepArr.length);
      // console.log({ answer });
    } else {
      setCurrent(current + 1);
      window.scrollTo({
        top: 0,
      });
    }
    const tf = checkAnswerCnt();
    setDisableBtn(tf);
  };

  const prevHandler = () => {
    setCurrent(current - 1);
    window.scrollTo({
      top: 0,
    });
    // console.log({ answer });
  };

  const ansSendHandler = async (e) => {
    try {
      // console.log({ tokenInfo });
      let ansArr = [];
      // console.log(surveyDBURL[tokenInfo.survey_code]);
      // const res = await Axios.post('/api/dbModel300', data);

      switch (tokenInfo.survey_code) {
        //人事評価力診断　→まだここには来ない「takesurvey」に行く
        //時間があるときに統合

        // case '50010':
        // for (let i = 1; i < queLen + 1; i++) {
        //   ansArr.push(answer[i]);
        // }
        // const data500 = {
        //   user: tokenInfo.user._id,
        //   survey_token: tokenInfo._id,
        //   study_end_date: Date.now(),
        //   study_start_date: studyStartDate,
        //   answers: ansArr,
        // };
        // const res500 = await Axios.post('/api/dbModel100', data500);
        // if (res500.data.success) {
        //   const res3 = await Axios.patch(
        //     `/api/surveyToken/${tokenInfo._id}/status`,
        //     {
        //       survey_status: 5,
        //       answer_id: res500.data.dbModel100._id,
        //     }
        //   );
        //   // console.log({ res3 });
        //   if (res3.data.success) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }
        case '10010':
        case '50010':
        case '60010':
          // let ansLen = Object.keys(answer).length;
          for (let i = 1; i < queLen + 1; i++) {
            ansArr.push(answer[i]);
          }
          const data100 = {
            user: tokenInfo.user._id,
            survey_token: tokenInfo._id,
            study_end_date: Date.now(),
            study_start_date: studyStartDate,
            answers: ansArr,
          };
          const res100 = await Axios.post('/api/dbModel100', data100);
          if (res100.data.success) {
            const data2 = {
              tokenInfo: tokenInfo,
              answers: ansArr,
              study_end_date: data100.study_end_date,
            };
            // console.log(data2);
            let res2;
            if (tokenInfo.survey_code === '10010') {
              res2 = await Axios.post('/api/dbModel100/makeReport', data2);
            } else if (tokenInfo.survey_code === '50010') {
              res2 = await Axios.post('/api/dbModel100/makeReport50010', data2);
            } else if (tokenInfo.survey_code === '60010') {
              res2 = await Axios.post('/api/dbModel100/makeReport60010', data2);
            }
            if (res2.data.success) {
              // console.log({ res100 });
              const res3 = await Axios.patch(
                `/api/surveyToken/${tokenInfo._id}/status`,
                {
                  survey_status: 5,
                  answer_id: res100.data.dbModel100._id,
                }
              );
              // const [res3, res4] = await Promise.all([
              //   Axios.patch(`/api/surveyToken/${tokenInfo._id}/status`, {
              //     survey_status: 5,
              //     answer_id: res100.data.dbModel100._id,
              //   }),
              //   Axios.patch(`/api/dbModel100/${tokenInfo._id}/reportDate`), //なぜかアップデートされない→確認が必要
              // ]);
              if (res3.data.success) {
                // message.success(
                //   '回答を提出しました。マイ・サーベイに戻ります。'
                // );
                // setTimeout(() => {
                //   props.history.push('/mysurvey');
                // }, 3000);
                return true;
              } else {
                return false;
              }
            }
          }
        //E3-Survey
        case '30010':
        case '70010':
        case '80010':
          // let ansLen = Object.keys(answer).length;
          for (let i = 1; i < queLen + 1; i++) {
            if (answer[i]) {
              ansArr.push(answer[i].toString());
            } else {
              ansArr.push('');
            }
          }
          const data300 = {
            user: tokenInfo.user._id,
            survey_token: tokenInfo._id,
            study_end_date: Date.now(),
            study_start_date: studyStartDate,
            answers: ansArr,
          };
          const res300 = await Axios.post('/api/dbModel100', data300);
          if (res300.data.success) {
            const res5 = await Axios.patch(
              `/api/surveyToken/${tokenInfo._id}/status`,
              {
                survey_status: 5,
                answer_id: res300.data.dbModel100._id,
              }
            );

            if (res5.data.success) {
              // message.success('回答を提出しました。マイ・サーベイに戻ります。');
              // setTimeout(() => {
              //   props.history.push('/mysurvey');
              // }, 3000);
              return true;
            } else {
              return false;
            }
          }
        //研修前後アンケート
        case '20110':
        case '20210':
        case '40010':
          // let ansLen = Object.keys(answer).length;
          for (let i = 1; i < queLen + 1; i++) {
            if (answer[i]) {
              if (answer[i + 1000]) {
                ansArr.push(answer[i] + ' : ' + answer[i + 1000].toString());
              } else if (answer[i + 3000]) {
                ansArr.push(answer[i] + ' : ' + answer[i + 3000].toString());
              } else {
                ansArr.push(answer[i].toString());
              }
            } else {
              ansArr.push('');
            }
          }
          const data201 = {
            user: tokenInfo.user._id,
            survey_token: tokenInfo._id,
            study_end_date: Date.now(),
            study_start_date: studyStartDate,
            answers: ansArr,
          };
          const res201 = await Axios.post('/api/dbModel100', data201);
          if (res201.data.success) {
            const res5 = await Axios.patch(
              `/api/surveyToken/${tokenInfo._id}/status`,
              {
                survey_status: 5,
                answer_id: res201.data.dbModel100._id,
              }
            );

            if (res5.data.success) {
              // message.success('回答を提出しました。マイ・サーベイに戻ります。');
              // setTimeout(() => {
              //   props.history.push('/mysurvey');
              // }, 3000);
              return true;
            } else {
              return false;
            }
          }
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const showConfirm = async () => {
    confirm({
      title: '回答を提出しますか？',
      content:
        '提出処理が完了し、マイ・サーベイページへ自動遷移されるまで、そのままお待ちください。',
      async onOk() {
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0 ? resolve : reject, 3000);
        //   ansSendHandler();
        // }).catch(() => console.log('サーベイ結果提出でエラーが発生しました。'));
        const result = await ansSendHandler();
        if (result) {
          message.success('回答を提出しました。マイ・サーベイに戻ります。');
          // console.log({ answer });
          // console.log({ tokenInfo });
          // sendErrorMailHandler(
          //   JSON.stringify({ answer, tokenInfo }, null, '\t')
          // );
          // message.error({
          //   content:
          //     'サーベイ回答提出でエラーを感知しました。【回答データ】をバックアップしましたのでご安心ください。事務局から復元結果をメールで案内しますのでお待ちください。',
          //   key: 'answerError',
          //   duration: 100,
          // });
          props.history.push('/mysurvey');
        } else {
          console.log({ answer });
          sendErrorMailHandler(
            JSON.stringify({ answer, tokenInfo }, null, '\t')
          );
          message.error({
            content:
              'サーベイ回答提出でエラーを感知しました。【回答データ】はバックアップしましたのでご安心ください。事務局から復元結果をメールでご案内しますのでお待ちください。',
            key: 'answerError',
            duration: 60,
          });
          props.history.push('/mysurvey');
        }
      },
      async onCancel() {},
    });
  };

  //結果提出ボタンのDisable;true or false変換
  const checkAnswerCnt = () => {
    // console.log({ answer });
    let newAnswer = {};
    for (let key in answer) {
      if (answer[key]) {
        newAnswer[key] = answer[key];
      }
    }
    // console.log({ newAnswer });
    const arr = Object.keys(newAnswer);
    const unreqQNo = []; //任意の設問番号をArrに格納
    let answerFreeAfter = true; //選択＋自由記述型の返事結果
    for (let i = 0; i < questionInfo.length; i++) {
      const element = questionInfo[i];
      if (!element.required) {
        unreqQNo.push(element.no.toString());
      }
      if (element.free_after && element.required) {
        //必須の選択＋自由記述形を絞る
        if (answer[element.no] === element.free_after) {
          //その答えがその他を選択した場合
          if (answer[element.no + 1000]) {
            // console.log(`回答あり：${answer[element.no + 1000]}`);
          } else {
            answerFreeAfter = false;
            // console.log(
            //   `回答なし：${element.no}問目の回答が${answer[element.no]}なのに`
            // );
          }
        }
      }
    }
    // console.log({ arr });
    //E3-Surveyの場合は自由記述型は任意に処理
    if (tokenInfo.survey_code === '30010') {
      const arr2 = arr.filter(
        (item) => !unreqQNo.includes(item) //任意の設問番号が一致しない場合のみカウント
        // (item) =>
        //   item !== '31' && item !== '62' && item !== '93' && item !== '124' //自由記述設問をカウントしない
      );
      if (queLen - unreqQNo.length > arr2.length) {
        return true; //提出ボタン非表示
      } else {
        return false;
      }
    } else if (
      tokenInfo.survey_code === '20110' ||
      tokenInfo.survey_code === '20210'
    ) {
      // console.log({ arr });

      // console.log({ arr });
      const arr2 = arr.filter(
        (item) => !unreqQNo.includes(item) && Number(item) < 1000 //任意の設問番号が一致しない場合と1000以上を除いてカウント
      );
      // console.log({ answerFreeAfter });
      if (queLen - unreqQNo.length > arr2.length || !answerFreeAfter) {
        return true; //提出ボタン非表示
      } else {
        return false;
      }
    } else {
      if (queLen !== arr.length) {
        return true; //提出ボタン非表示
      } else {
        return false;
      }
    }
  };

  const sendErrorMailHandler = async (data) => {
    let mailList = {};
    try {
      // console.log({ rowKeys });
      // const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      // const res = await Axios.get(`/api/mailTemplate/tempw`);
      // const selMtInfo = res.data.mailTemplate;
      // console.log({ selMtInfo });
      // console.log({ data });
      mailList = {
        personalizations: [
          {
            to: [
              {
                email: 'retech@re-current.co.jp',
                name: `リ・カレントサーベイ事務局`,
              },
            ],
            bcc: { email: 'admin365@re-current.co.jp' },
            substitutions: {},
          },
        ],
        from: {
          email: 'retech@re-current.co.jp', // 送信元アドレス
          name: `リ・カレントサーベイ事務局`, // 送信者名
        },
        subject:
          '【サーベイ・エラー】サーベイ・エラーが発生しました。（システムテストです）',
        html: data,
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      // const sendMailResult = { data: { success: false } };
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      if (sendMailResult.data.success) {
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 2,
        });
        console.log({ sendMailResult });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log(error);
      message.error({
        content: 'メール配信で問題が発生しました。',
        key: 'mail',
        duration: 2,
      });
    }
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.key} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current > 0 && (
            <Button
              style={{ marginRight: 10 }}
              size="large"
              onClick={prevHandler}
            >
              前へ
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" size="large" onClick={nextHandler}>
              次へ
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              danger
              shape="round"
              icon={<CloudUploadOutlined />}
              size="large"
              onClick={showConfirm}
              disabled={disableBtn}
            >
              回答提出
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewTakeSurveyPage;

//일단 백업
// const readDB = async () => {
//   try {
// const res = await Axios.get(`/api/surveyToken/t/${tokenId}`);
// // console.log(res);
// if (res.data.success) {
//   const { tokenInfo } = res.data;
//   setTokenInfo(tokenInfo);
//   //question_set_id：あり→可変問題構成
//   //question_set_id：なし→固定問題構成
//   if (tokenInfo.question_set_id) {
//     // console.log('あり');
//     //可変問題構成方式
//   } else {
//     // console.log('なし');
//     //固定問題構成方式
//     const res2 = await Axios.get(
//       `/api/question/${tokenInfo.survey_code}`
//     );
//     if (res2.data.success) {
//       const questionInfo = res2.data.question;
//       setQuestionInfo(questionInfo);
//       console.log({ questionInfo });
//       //Section情報のみ数字で取り出してSortをかける
//       const secNum = [
//         ...new Map(
//           await questionInfo.map((info) => [
//             info.section,
//             Number(info.section),
//           ])
//         ).values(),
//       ].sort((first, second) => first - second);
//       console.log(secNum);
//       // secNum.forEach((secNum) => {
//       for (let i = 0; i < secNum.length; i++) {
//         const element = secNum[i];
//         steps.push({
//           title: `${element}ページ`,
//           content: `${element}コンテンツ`,
//         });
//       }
//       // setStepArr([
//       //   ...stepArr,
//       //   {
//       //     title: `${secNum}ページ`,
//       //     content: `${secNum}コンテンツ`,
//       //   },
//       // ]);
//       // });
//       // steps = stepArr;
//       console.log(steps);
//     } else {
//       throw '問題情報取得に失敗しました。';
//     }
//   }
// } else {
//   throw 'サーベイトークン情報取得に失敗しました。';
// }
//   } catch (error) {
//     console.log(error);
//   }
// };

// useEffect(() => {
//   readDB();
//   // console.log(steps);
// }, []);
