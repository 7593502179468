import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Tabs,
  Modal,
  Spin,
} from 'antd';
import Axios from 'axios';
import { useSelector } from 'react-redux';
const ExcelJS = require('exceljs');
const { Option } = Select;
const { Title, Text } = Typography;

const Excel30010DownloadPage = () => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [submitSW, setSubmitSW] = useState(true); //SubmitボタンDisable調整 true => 表示
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [excelType, setExcelType] = useState(0);

  const usr = useSelector((state) => state.user);

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      setUserInfo(usr.userData);
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/30010'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      // setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);

      if (!groups) throw new Error('グループ取得に失敗しました。');
      if (!survey) throw new Error('サーベイ取得に失敗しました。');
      if (usr.userData.role === 3) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(
          sgi.survey_season.filter(
            (item) =>
              item.survey_code === '30010' || item.survey_code === '90301'
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log(sgi.department);
    setSelGroupCode(value);
    setSelGroupInfo(sgi);

    // setGroupSurveySeasonInfo(sgi.survey_season);
    const dp = [];
    for (let i = 0; i < sgi.department.length; i++) {
      if (sgi.department[i].split('／').length < 2) {
        dp.push(sgi.department[i]);
      }
    }

    // console.log(dp);
    const child = [];
    const dep = [];
    for (let i = 0; i < dp.length; i++) {
      const element = dp[i];
      child.push(<Option key={element}>{element}</Option>);
      dep.push(element);
    }

    setGroupSurveySeasonInfo(
      sgi.survey_season.filter(
        (item) => item.survey_code === '30010' || item.survey_code === '90301'
      )
    );
    setSelSurveySeasonId('');
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);
    // console.log({ sssi });
  };

  const onChangeExcelType = (v) => {
    // setOpenPdf360(v);
    setExcelType(v);
    // console.log(value);
  };

  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      message.loading({
        content: 'データ作成中...',
        key: 'makecsv',
        duration: 20,
      });

      setIsModalVisible(true);
      setSubmitSW(false);

      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      // const question = await Axios.get(`/api/question/${30010}`);
      const res = await Axios.get(
        `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}`
      );
      const tokenI = res.data.tokenInfo;
      // console.log(tokenInfo);

      //入力順：回答順（q.no=番地+1)
      const modelIndex = [
        25, 26, 27, 28, 29, 0, 5, 10, 15, 20, 1, 6, 11, 16, 21, 2, 7, 12, 17,
        22, 3, 8, 13, 18, 23, 4, 9, 14, 19, 24, 87, 88, 89, 90, 91, 62, 67, 72,
        77, 82, 63, 68, 73, 78, 83, 64, 69, 74, 79, 84, 65, 70, 75, 80, 85, 66,
        71, 76, 81, 86, 56, 57, 58, 59, 60, 31, 36, 41, 46, 51, 32, 37, 42, 47,
        52, 33, 38, 43, 48, 53, 34, 39, 44, 49, 54, 35, 40, 45, 50, 55, 118,
        119, 120, 121, 122, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104,
        105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117,
      ];
      const commentIndex = [30, 92, 61, 123];
      const titleLv1 = ['会社', '職場', '上司', '職務'];
      const titleLv2 = [
        //
        'A 共感(会社)', //0~4   :0        i/5 =
        'B 経営理念(発信)', //5~9   :1    i/5 = 1
        'C 経営基盤', //10~14   :2
        'D 勤務環境', //15~19   :3
        'E 人的資本', //20~24   :4
        'F 人事制度(構築)', //25~29   :5
        //
        'M 共感(職場)', //30~34   :6
        'N 経営理念(共有)', //35~39   :7
        'O 職場風土',
        'P 情報共有',
        'Q 協働推進',
        'R 変革活動',
        //
        'G 共感(上司)',
        'H 部門方針',
        'I 問題解決',
        'J 協働推進',
        'K 人材育成',
        'L 人事評価(運用)',
        //
        'S 共感(職務)',
        'T 経営理念(実践)',
        'U 貢献行動',
        'V 成長実感',
        'W 自己決定',
        'X 人事評価(納得)',
      ];
      const titleLv3 = [
        //
        '経営ビジョンへの賛同',
        '会社への誇り',
        '会社への所属感',
        '社会貢献への意欲',
        '会社の成長への貢献',
        '経営理念の発信',
        '経営ビジョンの発信',
        '経営環境変化の発信',
        '経営方針の発信',
        '経営理念の実践',
        '財務の健全性',
        '顧客基盤の安定性',
        '商品・サービスへの評価',
        '技術・ノウハウの優位性',
        '環境変化への対応力',
        '職場環境の快適さ',
        'コミュニケーションの促進',
        '就業時間の自由度',
        '休暇の取りやすさ',
        '勤務ロケーションの選択',
        '優秀な人材の採用',
        '人材の多様性',
        '人材育成への投資',
        '人材の活用',
        '学習の機会',
        '目標への意思反映',
        '評価基準の明確性',
        '給与の妥当性',
        '部署間の公平性',
        '昇進昇格基準の明確性',
        '職場ビジョンへの賛同',
        '職場メンバーとしての誇り',
        '職場課題への当事者意識',
        '職場からの学習意欲',
        '目標達成への貢献',
        '経営理念の共有',
        '経営理念の実践',
        '部門戦略の共有',
        '部門方針に沿った行動',
        '部門戦略の変革対応',
        '挑戦的活動の奨励',
        '組織の風通し',
        '多様性の受容',
        'メンバー同士の協力・連携',
        'コンプライアンスの遵守',
        '知識・情報の共有',
        '情報共有の仕組み活用',
        '情報の重要性認識',
        '会社の歴史や経緯の共有',
        '成功・失敗事例の共有',
        '個人目標の共有',
        '職務への責任感',
        '職場メンバーの協働',
        '職場メンバーの能力',
        '個人の尊重',
        '変化の認知',
        '変化の共有',
        '変化の受け止め方',
        '環境変化への対応',
        '顧客ニーズへの対応',
        '部門・部署ビジョンへの賛同',
        'メンバーへの動機付け',
        '上司との協働意欲',
        '上司との挑戦意欲',
        '部門・部署目標達成への貢献',
        '経営理念の伝達',
        '部門環境変化の共有',
        '部門方針の展開',
        '市場・顧客情報の共有',
        '部門方針の実践',
        '問題の発見',
        '問題解決への姿勢',
        '解決策の提示',
        '意思決定の迅速さ',
        '上位者への提言',
        '職場コミュニケーションの促進',
        '業務進捗の共有促進',
        '協働の重要性',
        '社内情報の共有',
        '他部門の巻き込み',
        '意見の尊重',
        '強みの活用',
        '能力開発',
        'キャリアの支援',
        '部下のコンディション把握',
        '目標設定の適切さ',
        '部下の目標達成支援',
        '部下へのフィードバック',
        '評価の適切さ',
        '評価の活用',
        'キャリアビジョン',
        '職務への魅力',
        '職務による成長実感',
        '職務の意義',
        '職務での達成感',
        '社会的倫理観に基づく行動',
        '部門方針の主体的理解',
        '経営理念に基づく判断',
        '行動指針に沿った行動',
        '経営理念の定着活動',
        '上司への提言',
        '上司の活動支援',
        '社会への貢献',
        '顧客への貢献',
        '関係者への貢献',
        '能力の向上',
        '気持ちの切り替え',
        '職務への集中',
        '経験の振り返り',
        '経験の活用',
        '能力の発揮',
        '職務全体の把握',
        '自律性の発揮',
        '職務の重要性認識',
        '成果の把握',
        '合意目標の納得',
        '人事評価の納得',
        'フィードバックの適切さ',
        '業務量の適切さ',
        '業務難易度の適切さ',
      ];
      let columns0 = [];
      let columns1 = [];
      let columns2 = [];
      switch (excelType) {
        case 0: //組織別集計
          columns0.push('', '', '', '', '', '', '');
          columns1.push('', '', '', '', '', '', '');
          columns2.push(
            'NO.',
            '所属①',
            '所属②',
            '所属③',
            '所属④',
            '所属⑤',
            '項目'
          );
          break;
        case 1: //等級別集計
          columns0.push('', '', '');
          columns1.push('', '', '');
          columns2.push('NO.', '等級', '項目');
          break;
        case 2: //年代別集計
          columns0.push('', '', '');
          columns1.push('', '', '');
          columns2.push('NO.', '年代', '項目');
          break;
        case 3: //役職別集計
          columns0.push('', '', '');
          columns1.push('', '', '');
          columns2.push('NO.', '役職', '項目');
          break;

        default:
          break;
      }

      // let columns3 = ['', '', '', '', '']; //設問
      let cnt = 0;
      for (let i = 0; i < 120; i++) {
        // columns0.push({ header: i + 1, key: i + 1 });
        let title1;
        if (
          i <= 4 ||
          (i >= 30 && i <= 34) ||
          (i >= 60 && i <= 64) ||
          (i >= 90 && i <= 94)
        ) {
          title1 = '共感';
        } else {
          title1 = '事実';
        }
        if (i <= 29) title1 = titleLv1[0] + title1;
        if (i >= 30 && i <= 59) title1 = titleLv1[1] + title1;
        if (i >= 60 && i <= 89) title1 = titleLv1[2] + title1;
        if (i >= 90) title1 = titleLv1[3] + title1;
        columns0.push(title1);
        // columns3.push(question.data.question[modelIndex[i]].question);
        // let j = Math.floor(i / 5);
        columns1.push(titleLv2[Math.floor(i / 5)]);
        columns2.push(titleLv3[i]);
        cnt++;
        if (cnt % 5 === 0) {
          columns0.push(title1);
          columns1.push(titleLv2[Math.floor(i / 5)] + '：平均');
          columns2.push('ー');
        }
      }
      columns0.push('共感総平均');
      columns1.push('ー');
      columns2.push('ー');
      columns0.push('共感項目');
      columns1.push(
        '中項目',
        'フリーコメント',
        'フリーコメント',
        'フリーコメント',
        'フリーコメント'
      );
      columns2.push('小項目', '会社', '職場', '上司', '職務', '人数');
      // worksheet.columns = columns0;
      worksheet.addRow(columns0);
      worksheet.addRow(columns1);
      worksheet.addRow(columns2);
      // worksheet.addRow(columns3);
      // console.log(selGroupInfo);
      //対象になるtoken情報のuserデータをVUDを確認しsurveySeasonの一番近い過去のデータに上書き
      await tokenI.map((item) => {
        const arr = item.user.change_history;

        if (arr.length > 1) {
          //履歴を過去→未来順にsort
          item.user.change_history = arr.sort(function (a, b) {
            return a.input_date < b.input_date ? -1 : 1; //オブジェクトの昇順ソート
          });
          let tempHistory = {};
          //UVD履歴を繰り返す
          for (let i = 0; i < item.user.change_history.length; i++) {
            //履歴がサーベイ開始日より過去の場合、Pickup
            // console.log(ymd(item.user.change_history[i].input_date));
            // console.log(ymd(selSurveySeasonInfo.survey_start_date));
            if (
              ymd(item.user.change_history[i].input_date) <
              ymd(selSurveySeasonInfo.survey_start_date)
            ) {
              tempHistory = item.user.change_history[i];
            }
          }
          if (
            tempHistory['department'] !== undefined &&
            tempHistory['department']
          )
            item.user.department = tempHistory.department;
          if (
            tempHistory['department2'] !== undefined &&
            tempHistory['department2']
          )
            item.user.department2 = tempHistory.department2;
          if (
            tempHistory['department3'] !== undefined &&
            tempHistory['department3']
          )
            item.user.department3 = tempHistory.department3;
          if (
            tempHistory['department4'] !== undefined &&
            tempHistory['department4']
          )
            item.user.department4 = tempHistory.department4;
          if (
            tempHistory['department5'] !== undefined &&
            tempHistory['department5']
          )
            item.user.department5 = tempHistory.department5;
          if (tempHistory['position'] !== undefined && tempHistory['position'])
            item.user.position = tempHistory.position;
          if (
            tempHistory['level_code'] !== undefined &&
            tempHistory['level_code']
          )
            item.user.level_code = tempHistory.level_code;
          if (tempHistory['emp_type'] !== undefined && tempHistory['emp_type'])
            item.user.emp_type = tempHistory.emp_type;
          if (tempHistory['go_out'] !== undefined && tempHistory['go_out'])
            item.user.go_out = tempHistory.go_out;
          if (
            tempHistory['inactive'] !== undefined ||
            !tempHistory['inactive']
          ) {
            item.user.inactive = false;
          } else {
            item.user.inactive = true;
          }
        }
      });
      // console.log(tokenI);
      const tokenInfo = tokenI.filter(
        (item) =>
          item.user.inactive === undefined || item.user.inactive !== true
      );
      switch (excelType) {
        case 0:
          //===================組織集計＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
          if (selGroupInfo.department.length === 0)
            throw new Error('部署情報がありません。');
          const department = selGroupInfo.department;
          department.sort();
          for (let i = 0; i < department.length; i++) {
            //excelの1行
            const dp = department[i].split('／');
            // console.log(`${i}:${dp}`);
            let line1 = [];
            let line2 = [];
            let depart = [];

            line1.push(String(i + 1));
            line2.push('');
            for (let j = 0; j < 5; j++) {
              if (j < dp.length) {
                line1.push(dp[j]);
                line2.push(dp[j]);
                depart.push(dp[j]);
              } else {
                line1.push('');
                line2.push('');
                // depart.push('');
              }
            }
            line1.push('平均');
            line2.push('分散');
            // console.log(line1);
            // console.log(line2);

            //departのlengthが1の場合、2の場合、3の場合が存在する
            //1の場合はdepartmentのみをFilter, 2の場合は、department, department2をfilter, 3の場合は department, department2, department3をfilter
            let ticket = [];
            switch (depart.length) {
              case 1:
                ticket = tokenInfo.filter(
                  (item) => item.user.department === depart[0]
                );
                break;
              case 2:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.department === depart[0] &&
                    item.user.department2 === depart[1]
                );

                break;
              case 3:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.department === depart[0] &&
                    item.user.department2 === depart[1] &&
                    item.user.department3 === depart[2]
                );

                break;
              case 4:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.department === depart[0] &&
                    item.user.department2 === depart[1] &&
                    item.user.department3 === depart[2] &&
                    item.user.department4 === depart[3]
                );

                break;
              case 5:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.department === depart[0] &&
                    item.user.department2 === depart[1] &&
                    item.user.department3 === depart[2] &&
                    item.user.department4 === depart[3] &&
                    item.user.department5 === depart[4]
                );

                break;

              default:
                break;
            }
            // console.log({ depart });
            // console.log({ ticket });
            const ticketCnt = ticket.length;
            if (ticketCnt > 0) {
              let answers = [];
              let resultArr = [];
              let commentArr = [];
              for (let c = 0; c < ticketCnt; c++) {
                answers.push(ticket[c].answer_id.answers);
              }
              const answerCnt = answers.length;

              for (let a = 0; a < modelIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  tempArr.push(Number(answers[b][modelIndex[a]])); //回答単位
                }
                resultArr.push(tempArr);
              }
              for (let a = 0; a < commentIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  if (
                    !(
                      answers[b][commentIndex[a]] === '' ||
                      answers[b][commentIndex[a]] === '特になし' ||
                      answers[b][commentIndex[a]] === '特に無し' ||
                      answers[b][commentIndex[a]] === '特にありません。' ||
                      answers[b][commentIndex[a]] === '特にありません｡' ||
                      answers[b][commentIndex[a]] === '特にありません'
                    )
                  ) {
                    tempArr.push(answers[b][commentIndex[a]]); //回答単位
                  }
                }
                commentArr.push(tempArr);
              }
              let avr = [];
              let varp = [];
              let line1Sum = 0.0;
              let line2Sum = 0.0;
              let line1SumKyo = 0.0;
              let line2SumKyo = 0.0;

              cnt = 0;
              resultArr.forEach((element) => {
                let sum = 0;
                let dist = 0;
                element.forEach((x, i) => (sum += x - 1));
                // console.log({ sum });
                const ave = sum / element.length;
                element.forEach((x, i) => (dist += (x - 1 - ave) ** 2));
                const va = dist / element.length;
                varp.push(va);
                line2.push(va.toFixed(2));
                line2Sum += va;
                avr.push((ave / 6) * 100);
                line1.push(((ave / 6) * 100).toFixed(2));
                line1Sum += (ave / 6) * 100;
                if (
                  cnt <= 4 ||
                  (cnt >= 30 && cnt <= 34) ||
                  (cnt >= 60 && cnt <= 64) ||
                  (cnt >= 90 && cnt <= 94)
                ) {
                  line2SumKyo += va;
                  line1SumKyo += (ave / 6) * 100;
                }
                cnt++;
                if (cnt % 5 === 0) {
                  line1.push((line1Sum / 5).toFixed(2));
                  line2.push((line2Sum / 5).toFixed(2));
                  // console.log({ line1Sum });
                  // console.log({ line2Sum });
                  line1Sum = 0.0;
                  line2Sum = 0.0;
                }
              });
              line1.push((line1SumKyo / 20).toFixed(2));
              line2.push((line2SumKyo / 20).toFixed(2));
              line1.push('コメント数');
              line2.push('割合');
              commentArr.forEach((element) => {
                // console.log(element);
                line1.push(element.length);
                line2.push(
                  `${((element.length / ticketCnt) * 100).toFixed(1)}%`
                );
              });
              line2.push(ticketCnt);
              // console.log({ commentArr });
              worksheet.addRow(line1);
              worksheet.addRow(line2);
            }
            // console.log({ avr });
            // console.log({ varp });
          }
          break;
        case 1:
          //===================等級集計＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
          if (selGroupInfo.level_code.length === 0)
            throw new Error('部署情報がありません。');
          const levelCode = selGroupInfo.level_code;
          // levelCode.sort();
          for (let i = 0; i < levelCode.length; i++) {
            //excelの1行
            // const lc = levelCode[i].split('／');
            // console.log(`${i}:${dp}`);
            let line1 = [];
            let line2 = [];
            line1.push(String(i + 1));
            line2.push('');
            line1.push(levelCode[i]);
            line2.push(levelCode[i]);
            line1.push('平均');
            line2.push('分散');
            let ticket = tokenInfo.filter(
              (item) => item.user.level_code === levelCode[i]
            );
            const ticketCnt = ticket.length;
            if (ticketCnt > 0) {
              let answers = [];
              let resultArr = [];
              let commentArr = [];
              for (let c = 0; c < ticketCnt; c++) {
                answers.push(ticket[c].answer_id.answers);
              }
              const answerCnt = answers.length;

              for (let a = 0; a < modelIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  tempArr.push(Number(answers[b][modelIndex[a]])); //回答単位
                }
                resultArr.push(tempArr);
              }
              for (let a = 0; a < commentIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  if (
                    !(
                      answers[b][commentIndex[a]] === '' ||
                      answers[b][commentIndex[a]] === '特になし' ||
                      answers[b][commentIndex[a]] === '特に無し' ||
                      answers[b][commentIndex[a]] === '特にありません。' ||
                      answers[b][commentIndex[a]] === '特にありません｡' ||
                      answers[b][commentIndex[a]] === '特にありません'
                    )
                  ) {
                    tempArr.push(answers[b][commentIndex[a]]); //回答単位
                  }
                }
                commentArr.push(tempArr);
              }
              let avr = [];
              let varp = [];
              let line1Sum = 0.0;
              let line2Sum = 0.0;
              let line1SumKyo = 0.0;
              let line2SumKyo = 0.0;
              cnt = 0;

              resultArr.forEach((element) => {
                let sum = 0;
                let dist = 0;
                element.forEach((x, i) => (sum += x - 1));
                // console.log({ sum });
                const ave = sum / element.length;
                element.forEach((x, i) => (dist += (x - 1 - ave) ** 2));
                const va = dist / element.length;
                varp.push(va);
                line2.push(va.toFixed(2));
                line2Sum += va;
                avr.push((ave / 6) * 100);
                line1.push(((ave / 6) * 100).toFixed(2));
                line1Sum += (ave / 6) * 100;
                if (
                  cnt <= 4 ||
                  (cnt >= 30 && cnt <= 34) ||
                  (cnt >= 60 && cnt <= 64) ||
                  (cnt >= 90 && cnt <= 94)
                ) {
                  line2SumKyo += va;
                  line1SumKyo += (ave / 6) * 100;
                }
                cnt++;
                if (cnt % 5 === 0) {
                  line1.push((line1Sum / 5).toFixed(2));
                  line2.push((line2Sum / 5).toFixed(2));
                  // console.log({ line1Sum });
                  // console.log({ line2Sum });
                  line1Sum = 0.0;
                  line2Sum = 0.0;
                }
              });
              line1.push((line1SumKyo / 20).toFixed(2));
              line2.push((line2SumKyo / 20).toFixed(2));
              line1.push('コメント数');
              line2.push('割合');
              commentArr.forEach((element) => {
                // console.log(element);
                line1.push(element.length);
                line2.push(
                  `${((element.length / ticketCnt) * 100).toFixed(1)}%`
                );
              });
              line2.push(ticketCnt);
              // console.log({ commentArr });
              worksheet.addRow(line1);
              worksheet.addRow(line2);
            }
          }
          break;
        case 2:
          //===================年代集計＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
          // const age_range = [
          //   '19歳〜24歳',
          //   '25歳〜29歳',
          //   '30歳〜39歳',
          //   '40歳〜49歳',
          //   '50歳〜63歳',
          // ];
          const age_range = [
            '10代　　　〜19歳', //0
            '20代前半　20歳〜24歳', //1
            '20代後半　25歳〜29歳', //2
            '30代前半　30歳〜34歳', //3
            '30代後半　35歳〜39歳', //4
            '40代前半　40歳〜44歳', //5
            '40代後半　45歳〜49歳', //6
            '50代前半　50歳〜54歳', //7
            '50代後半　55歳〜59歳', //8
            '60代前半　60歳〜64歳', //9
            '60代後半　65歳〜', //10
          ];
          // console.log(selSurveySeasonInfo);
          for (let i = 0; i < age_range.length; i++) {
            //excelの1行
            // const lc = levelCode[i].split('／');
            // console.log(`${i}:${dp}`);
            let line1 = [];
            let line2 = [];
            line1.push(String(i + 1));
            line2.push('');
            line1.push(age_range[i]);
            line2.push(age_range[i]);
            line1.push('平均');
            line2.push('分散');
            const date = new Date(selSurveySeasonInfo.survey_end_date);
            // const date = new Date();
            const year = date.getFullYear();
            // console.log({ year });
            let ticket = [];
            switch (i) {
              case 0:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday >= `${year - 19}-01-01T00:00:00.000Z`
                );
                break;
              case 1: //25~29
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 19}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 24}-01-01T00:00:00.000Z`
                );
                break;
              case 2:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 24}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 29}-01-01T00:00:00.000Z`
                );
                break;
              case 3:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 29}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 34}-01-01T00:00:00.000Z`
                );
                break;
              case 4:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 34}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 39}-01-01T00:00:00.000Z`
                );
                break;
              case 5:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 39}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 44}-01-01T00:00:00.000Z`
                );
                break;
              case 6:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 44}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 49}-01-01T00:00:00.000Z`
                );
                break;
              case 7:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 49}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 54}-01-01T00:00:00.000Z`
                );
                break;
              case 8:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 54}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 59}-01-01T00:00:00.000Z`
                );
                break;
              case 9:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 59}-01-01T00:00:00.000Z` &&
                    item.user.birthday >= `${year - 64}-01-01T00:00:00.000Z`
                );
                break;
              case 10:
                ticket = tokenInfo.filter(
                  (item) =>
                    item.user.birthday < `${year - 64}-01-01T00:00:00.000Z`
                );
                break;

              default:
                break;
            }
            // switch (i) {
            //   case 0:
            //     ticket = tokenInfo.filter(
            //       (item) =>
            //         item.user.birthday >= `${year - 24}-01-01T00:00:00.000Z`
            //     );
            //     break;
            //   case 1:   //25~29
            //     ticket = tokenInfo.filter(
            //       (item) =>
            //         item.user.birthday < `${year - 24}-01-01T00:00:00.000Z` &&
            //         item.user.birthday >= `${year - 29}-01-01T00:00:00.000Z`
            //     );
            //     break;
            //   case 2:
            //     ticket = tokenInfo.filter(
            //       (item) =>
            //         item.user.birthday < `${year - 29}-01-01T00:00:00.000Z` &&
            //         item.user.birthday >= `${year - 39}-01-01T00:00:00.000Z`
            //     );
            //     break;
            //   case 3:
            //     ticket = tokenInfo.filter(
            //       (item) =>
            //         item.user.birthday < `${year - 39}-01-01T00:00:00.000Z` &&
            //         item.user.birthday >= `${year - 49}-01-01T00:00:00.000Z`
            //     );
            //     break;
            //   case 4:
            //     ticket = tokenInfo.filter(
            //       (item) =>
            //         item.user.birthday < `${year - 49}-01-01T00:00:00.000Z`
            //     );
            //     break;

            //   default:
            //     break;
            // }
            // ticket = tokenInfo.filter(
            //   (item) =>
            //     item.user.birthday <= `${year - 24}-01-01T00:00:00+09:00`
            // );
            // console.log({ ticket });
            // if (
            //   tokenInfo[0].user.birthday < `${2023 - 24}-01-01T00:00:00.000Z` &&
            //   tokenInfo[0].user.birthday >= `${2023 - 29}-01-01T00:00:00.000Z`
            //   // tokenInfo[0].user.birthday < `${2023 - 24}-01-01T00:00:00.000Z`
            //   // tokenInfo[0].user.birthday >= `${2023 - 29}-01-01T00:00:00.000Z`
            // ) {
            //   console.log(tokenInfo[0].user.birthday);
            //   console.log(`${2023 - 29}-01-01T00:00:00.000Z`);
            // }
            const ticketCnt = ticket.length;
            if (ticketCnt > 0) {
              let answers = [];
              let resultArr = [];
              let commentArr = [];
              for (let c = 0; c < ticketCnt; c++) {
                answers.push(ticket[c].answer_id.answers);
              }
              const answerCnt = answers.length;

              for (let a = 0; a < modelIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  tempArr.push(Number(answers[b][modelIndex[a]])); //回答単位
                }
                resultArr.push(tempArr);
              }
              for (let a = 0; a < commentIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  if (
                    !(
                      answers[b][commentIndex[a]] === '' ||
                      answers[b][commentIndex[a]] === '特になし' ||
                      answers[b][commentIndex[a]] === '特に無し' ||
                      answers[b][commentIndex[a]] === '特にありません。' ||
                      answers[b][commentIndex[a]] === '特にありません｡' ||
                      answers[b][commentIndex[a]] === '特にありません'
                    )
                  ) {
                    tempArr.push(answers[b][commentIndex[a]]); //回答単位
                  }
                }
                commentArr.push(tempArr);
              }
              let avr = [];
              let varp = [];
              let line1Sum = 0.0;
              let line2Sum = 0.0;
              let line1SumKyo = 0.0;
              let line2SumKyo = 0.0;

              cnt = 0;
              resultArr.forEach((element) => {
                let sum = 0;
                let dist = 0;
                element.forEach((x, i) => (sum += x - 1));
                // console.log({ sum });
                const ave = sum / element.length;
                element.forEach((x, i) => (dist += (x - 1 - ave) ** 2));
                const va = dist / element.length;
                varp.push(va);
                line2.push(va.toFixed(2));
                line2Sum += va;
                avr.push((ave / 6) * 100);
                line1.push(((ave / 6) * 100).toFixed(2));
                line1Sum += (ave / 6) * 100;
                if (
                  cnt <= 4 ||
                  (cnt >= 30 && cnt <= 34) ||
                  (cnt >= 60 && cnt <= 64) ||
                  (cnt >= 90 && cnt <= 94)
                ) {
                  line2SumKyo += va;
                  line1SumKyo += (ave / 6) * 100;
                }
                cnt++;
                if (cnt % 5 === 0) {
                  line1.push((line1Sum / 5).toFixed(2));
                  line2.push((line2Sum / 5).toFixed(2));
                  // console.log({ line1Sum });
                  // console.log({ line2Sum });
                  line1Sum = 0.0;
                  line2Sum = 0.0;
                }
              });
              line1.push((line1SumKyo / 20).toFixed(2));
              line2.push((line2SumKyo / 20).toFixed(2));
              line1.push('コメント数');
              line2.push('割合');
              commentArr.forEach((element) => {
                // console.log(element);
                line1.push(element.length);
                line2.push(
                  `${((element.length / ticketCnt) * 100).toFixed(1)}%`
                );
              });
              line2.push(ticketCnt);
              // console.log({ commentArr });
              worksheet.addRow(line1);
              worksheet.addRow(line2);
            }
          }
          break;
        case 3:
          //===================役職集計＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
          if (selGroupInfo.position.length === 0)
            throw new Error('部署情報がありません。');
          const position = selGroupInfo.position;
          // levelCode.sort();
          for (let i = 0; i < position.length; i++) {
            //excelの1行
            // const lc = levelCode[i].split('／');
            // console.log(`${i}:${dp}`);
            let line1 = [];
            let line2 = [];
            line1.push(String(i + 1));
            line2.push('');
            line1.push(position[i]);
            line2.push(position[i]);
            line1.push('平均');
            line2.push('分散');
            let ticket = tokenInfo.filter(
              (item) => item.user.position === position[i]
            );
            const ticketCnt = ticket.length;
            if (ticketCnt > 0) {
              let answers = [];
              let resultArr = [];
              let commentArr = [];
              for (let c = 0; c < ticketCnt; c++) {
                answers.push(ticket[c].answer_id.answers);
              }
              const answerCnt = answers.length;

              for (let a = 0; a < modelIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  tempArr.push(Number(answers[b][modelIndex[a]])); //回答単位
                }
                resultArr.push(tempArr);
              }
              for (let a = 0; a < commentIndex.length; a++) {
                //設問単位
                let tempArr = [];
                //人
                for (let b = 0; b < answerCnt; b++) {
                  if (
                    !(
                      answers[b][commentIndex[a]] === '' ||
                      answers[b][commentIndex[a]] === '特になし' ||
                      answers[b][commentIndex[a]] === '特に無し' ||
                      answers[b][commentIndex[a]] === '特にありません。' ||
                      answers[b][commentIndex[a]] === '特にありません｡' ||
                      answers[b][commentIndex[a]] === '特にありません'
                    )
                  ) {
                    tempArr.push(answers[b][commentIndex[a]]); //回答単位
                  }
                }
                commentArr.push(tempArr);
              }
              let avr = [];
              let varp = [];
              let line1Sum = 0.0;
              let line2Sum = 0.0;
              let line1SumKyo = 0.0;
              let line2SumKyo = 0.0;

              cnt = 0;
              resultArr.forEach((element) => {
                let sum = 0;
                let dist = 0;
                element.forEach((x, i) => (sum += x - 1));
                // console.log({ sum });
                const ave = sum / element.length;
                element.forEach((x, i) => (dist += (x - 1 - ave) ** 2));
                const va = dist / element.length;
                varp.push(va);
                line2.push(va.toFixed(2));
                line2Sum += va;
                avr.push((ave / 6) * 100);
                line1.push(((ave / 6) * 100).toFixed(2));
                line1Sum += (ave / 6) * 100;
                if (
                  cnt <= 4 ||
                  (cnt >= 30 && cnt <= 34) ||
                  (cnt >= 60 && cnt <= 64) ||
                  (cnt >= 90 && cnt <= 94)
                ) {
                  line2SumKyo += va;
                  line1SumKyo += (ave / 6) * 100;
                }
                cnt++;
                if (cnt % 5 === 0) {
                  line1.push((line1Sum / 5).toFixed(2));
                  line2.push((line2Sum / 5).toFixed(2));
                  // console.log({ line1Sum });
                  // console.log({ line2Sum });
                  line1Sum = 0.0;
                  line2Sum = 0.0;
                }
              });
              line1.push((line1SumKyo / 20).toFixed(2));
              line2.push((line2SumKyo / 20).toFixed(2));
              line1.push('コメント数');
              line2.push('割合');
              commentArr.forEach((element) => {
                // console.log(element);
                line1.push(element.length);
                line2.push(
                  `${((element.length / ticketCnt) * 100).toFixed(1)}%`
                );
              });
              line2.push(ticketCnt);
              // console.log({ commentArr });
              worksheet.addRow(line1);
              worksheet.addRow(line2);
            }
          }
          break;
        default:
          break;
      }

      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = `Recurrent_E3-Sruvey_Summery_Type_${excelType}_${selSurveySeasonInfo.season_name}.xlsx`;
      a.click();
      // ダウンロード後は不要なのでaタグを除去
      a.remove();
      // console.log({ excelData });
      // console.log(excelData.length);
      message.success({
        content: 'ダウンロード完了！',
        key: 'makecsv',
        duration: 2,
      });
      setSubmitSW(true);
      setIsModalVisible(false);
    } catch (error) {
      console.log(error.message);
      setIsModalVisible(false);
      message.error({
        content: error.message,
        key: 'makecsv',
        duration: 4,
      });
      setSelSurveySeasonId('');
      setSelGroupCode('');
      setSelGroupInfo([]);
      setSubmitSW(true);

      // window.location.reload(false);
    }
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '1100px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>E³-Survey集計データダウンロード(Excel)</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={userInfo.role === 3}
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Title>❸</Title>
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder="種類選択"
            optionFilterProp="items"
            onChange={onChangeExcelType}
            value={excelType}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0} key={0}>
              組織別集計
            </Option>
            <Option value={1} key={1}>
              等級別集計
            </Option>
            <Option value={2} key={2}>
              年代別集計
            </Option>
            <Option value={3} key={3}>
              役職別集計
            </Option>
            )
          </Select>
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!(submitSW && selSurveySeasonId)}
          >
            集計データダウンロード（Excel）
          </Button>
        </Form>
      </div>
      <Modal
        title="結果作成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
};

export default Excel30010DownloadPage;
