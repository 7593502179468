import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import Vimeo from '@vimeo/vimeo';
import { Card, Col, Row, Tooltip, Modal, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from '../../../Config/key';
import Vimeo from '@u-wave/react-vimeo';
const { Title, Text } = Typography;

// var Vimeo = require('vimeo').Vimeo;
function VideoViewPage(props) {
  const [viewModal, setViewModal] = useState(false);
  const [paused, setPaused] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [VDInfo, setVDInfo] = useState({}); //動画納品情報１件
  const [videoInfo, setVideoInfo] = useState([]); //納品対象の動画情報の集まり
  const [explText, setExplText] = useState([]); //納品対象の動画情報の集まり

  const thumbnailURL = `${config.S3_URL}/${config.S3_ENV}/videos/fixedThumbnail/`;
  const location = useLocation();
  const { t } = useParams();

  // let sendData = props.location.state;
  // console.log(t);

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    // console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };
  const isValid = (targetDate) => {
    const now = new Date();
    // console.log(ymdhms(surveySeasonInfo.output_360_date));
    // console.log(ymdhms(targetDate));
    // console.log(ymdhms(now));
    return ymdhms(targetDate) > ymdhms(now);
  };

  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '年' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '月' +
      ('0' + date.getDate()).slice(-2) +
      '日' +
      '(' +
      ['日', '月', '火', '水', '木', '金', '土'][date.getDay()] +
      ')'
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      // if (!read) return null;
      const res = await Axios.get(`/api/videoDelivery/t/${t}`);
      if (res.data.success) {
        // console.log(isValid(token.data.tokenInfo.token_exp));
        if (isValid(res.data.videoDelivery.delivery_end)) {
          setTokenValid(true);
          setVDInfo(res.data.videoDelivery);
          // console.log(res.data.videoDelivery);
          let vdInfo = res.data.videoDelivery;
          if (vdInfo.delivery_explanation) {
            setExplText(vdInfo.delivery_explanation.replace(/\r?\n/g, '<br>'));
          }
          const vInfo = [];
          for (let i = 0; i < vdInfo.delivery_video.length; i++) {
            const element = vdInfo.delivery_video[i];
            for (let j = 0; j < element.video_id.length; j++) {
              const video_id = element.video_id[j];
              vInfo.push(
                (await Axios.get(`/api/video/${video_id}`)).data.video
              );
            }
          }
          setVideoInfo(vInfo);
          // console.log({ vInfo });
          // console.log(res.data.videoDelivery);
        } else {
          setTokenValid(false);
          throw new Error('Tokenの期限が切れました');
        }
      } else {
        throw new Error('Tokenが無効です');
      }
      // read = true;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    readDB();
    // console.log(tokenValid);
  }, []);
  //ModalでのOK
  const handleOk = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  const handleCancel = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };
  const handleOnPause = (e) => {
    // console.log({ e });
    // setPaused(true);
    setViewModal(false);
  };

  //ModalでのOK
  const handleCardClick = (v) => {
    // console.log(v);
    // if (v === 'takeSurvey') {
    //   sendData.tokenInfo.survey_code = '10010';
    //   // console.log({ sendData });
    //   props.history.push({
    //     pathname: '/newtakesurvey',
    //     state: sendData,
    //   });
    // } else {
    setVideoId(v);
    setPaused(false);
    setViewModal(true);
    // }
  };

  // console.log(tokenValid);

  if (tokenValid && VDInfo.delivery_video && videoInfo.length > 0) {
    return (
      <Fragment>
        <div style={{ width: '90%', margin: '1rem auto' }}>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Title level={2}>{VDInfo.main_title}</Title>
            <Title level={3}>{VDInfo.sub_title}</Title>
          </div>

          <div
            style={{
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${explText}`,
              }}
            ></div>
          </div>
          {VDInfo.show_limit === 1 ? (
            <div
              style={{
                textAlign: 'center',
                marginBottom: '2rem',
              }}
            >
              <Title level={4}>
                【視聴期間】　{ymd(VDInfo.delivery_start)}　～　
                {ymd(VDInfo.delivery_end)}
              </Title>
            </div>
          ) : (
            <div></div>
          )}

          {VDInfo.delivery_video.map((element) => {
            return (
              <Card
                title={element.category}
                bodyStyle={{ backgroundColor: '#EEEEEE' }}
                headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
                key={element.category}
                style={{ marginBottom: '2rem' }}
                extra={
                  <Tooltip
                    placement="bottomRight"
                    key={element.cagtegory_help}
                    title={element.category_help}
                    color={'orange'}
                  >
                    {element.category_help && (
                      <QuestionCircleOutlined
                        style={{ fontSize: '25px', color: '#FFF' }}
                      />
                    )}
                  </Tooltip>
                }
              >
                <Row gutter={16}>
                  {element.video_id.map((element2) => {
                    const vi = videoInfo.find((item) => item._id === element2);
                    // console.log({ videoInfo });
                    let tg = <></>;
                    let title = <></>;
                    switch (VDInfo.show_title) {
                      case 0:
                        title = <></>;
                        break;
                      case 1:
                        title = <>{vi.title}</>;
                        break;
                      case 2:
                        title = <>{vi.video_explanation}</>;
                        break;
                      case 3:
                        title = (
                          <>
                            {vi.title}
                            <br></br>
                            {vi.video_explanation}
                          </>
                        );
                        break;
                      default:
                        title = <></>;
                        break;
                    }
                    if (element.video_id.length === 1) {
                      tg = (
                        <Col span={8} offset={8} key={vi._id}>
                          <Card
                            hoverable
                            key={vi._id}
                            style={{ marginBottom: '2rem' }}
                            cover={
                              <div style={{ position: 'relative' }}>
                                <img
                                  style={{ width: '100%' }}
                                  alt={vi.title}
                                  src={vi.thumbnail[0]}
                                />
                                <div className="duration">
                                  <span>
                                    {('00' + vi.duration_h).slice(-2)} :{' '}
                                    {('00' + vi.duration_m).slice(-2)} :{' '}
                                    {('00' + vi.duration_s).slice(-2)}
                                  </span>
                                </div>
                              </div>
                            }
                            onClick={() =>
                              handleCardClick(vi.url.split('/')[2])
                            }
                          >
                            {title}
                          </Card>
                        </Col>
                      );
                    } else {
                      tg = (
                        <Col span={8} key={vi._id}>
                          <Card
                            hoverable
                            key={vi._id}
                            style={{ marginBottom: '2rem' }}
                            cover={
                              <div style={{ position: 'relative' }}>
                                <img
                                  style={{ width: '100%' }}
                                  alt={vi.title}
                                  src={vi.thumbnail[0]}
                                />
                                <div className="duration">
                                  <span>
                                    {('00' + vi.duration_h).slice(-2)} :{' '}
                                    {('00' + vi.duration_m).slice(-2)} :{' '}
                                    {('00' + vi.duration_s).slice(-2)}
                                  </span>
                                </div>
                              </div>
                            }
                            onClick={() =>
                              handleCardClick(vi.url.split('/')[2])
                            }
                          >
                            {title}
                          </Card>
                        </Col>
                      );
                    }

                    return tg;
                  })}
                </Row>
              </Card>
            );
          })}

          <br />
        </div>
        <div>
          <Modal
            title="動画再生"
            open={viewModal}
            onOk={handleOk}
            onCancel={handleCancel}
            bodyStyle={{ overflowX: 'scroll' }}
            width={1000}
            footer={null}
          >
            <Vimeo
              video={videoId}
              className="vimeo pc"
              responsive
              autoplay={true}
              speed={true}
              paused={paused}
            />
          </Modal>
        </div>
      </Fragment>
    );
  } else if (VDInfo.delivery_video || videoInfo.length > 0) {
    return (
      <Fragment>
        <div style={{ width: '90%', margin: '1rem auto' }}>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Title level={2}>少々お待ちください</Title>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div style={{ width: '90%', margin: '1rem auto' }}>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Title level={2}>本動画の視聴期限が切れました。</Title>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default VideoViewPage;
