import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;
const { Text } = Typography;

function ContentsPage1() {
  return (
    <div>
      <Title>人事評価力診断</Title>
      <Title level={4}>【　目　的　】</Title>
      <Text>
        こちらは、評価を行う際の評価傾向について自己診断するものです。
      </Text>
      <br />
      また、それに加えて、評価者に必要とされる一般的な知識と意識を診断するものです。
      <br />
      <br />
      <Title level={4}>【診断受講のための注意事項】</Title>
      <br />
      <ul>
        <li>設問は全部で80問です。</li>
        <li>
          設問には２択（「その通りである」「その通りではない」）と３択（「その通りである」「その通りではない」「どちらともいえない」）があります。
        </li>
        <li>
          評価の段階は５段階（５・４・３・２・１）で、最高評価は５で最低評価は「１」です。
        </li>
        <li>
          設問にある「」の中身は評価項目の名称です。特に定義の意味が分からなくても回答できるようになっていますが、表示された漢字そのままの意味で考えていただくことで回答しやすくなります。
        </li>
      </ul>
      <Text type="warning">
        ※成績評価を目標管理で行っていない場合には、目標を仕事の出来栄えと読み替えてお答えください。
      </Text>
      <br />
    </div>
  );
}

export default ContentsPage1;
