module.exports = {
  BACKEND_SERVER: process.env.REACT_APP_PROD_BACKEND_SERVER,
  USER_SERVER: `${process.env.REACT_APP_PROD_BACKEND_SERVER}${process.env.REACT_APP_USER_SERVER}`,
  S3_BUCKET_NAME: process.env.REACT_APP_S3_BUCKET_NAME,
  S3_URL: process.env.REACT_APP_S3_URL,
  S3_ENV: process.env.REACT_APP_S3_ENV_PROD,
  VIMEO_ACCESS_TOKEN: process.env.REACT_APP_VIMEO_ACCESS_TOKEN,
  VIMEO_CLIENT_IDENTIFIER: process.env.REACT_APP_VIMEO_CLIENT_IDENTIFIER,
  VIMEO_CLIENT_SECRETS: process.env.REACT_APP_VIMEO_CLIENT_SECRETS,
  VIMEO_FOLDER: process.env.REACT_APP_VIMEO_FOLDER,
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
};
