import axios from 'axios';
import { LOGIN_USER, REGISTER_USER, AUTH_USER, LOGOUT_USER } from './types';
import config from '../components/Config/key';

export function registerUser(dataToSubmit) {
  const request = axios
    .post(`${config.USER_SERVER}/register`, dataToSubmit, {
      withCredentials: true,
    })
    .then((response) => response.data);

  return {
    type: REGISTER_USER,
    payload: request,
  };
}

export function loginUser(dataToSubmit) {
  const request = axios
    // .post(`${config.USER_SERVER}/login`, dataToSubmit)
    .post(`${config.USER_SERVER}/login`, dataToSubmit, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
    .then((response) => response.data);
  return {
    type: LOGIN_USER,
    payload: request,
  };
}

export function auth() {
  // console.log(`${config.USER_SERVER}/auth`);
  const w_auth = localStorage.getItem('w_auth');
  // console.log({ w_auth });
  const request = axios
    .get(`${config.USER_SERVER}/auth/${w_auth}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
    .then((response) => {
      // console.log('data', response.data);
      return response.data;
    });

  // console.log({ request });
  return {
    type: AUTH_USER,
    payload: request,
  };
}

export function logoutUser() {
  // console.log(`${config.USER_SERVER}/logout`);
  const w_auth = localStorage.getItem('w_auth');
  const request = axios
    .get(`${config.USER_SERVER}/logout/${w_auth}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
    .then((response) => {
      // console.log('data', response.data);
      localStorage.removeItem('w_auth');
      localStorage.removeItem('w_authExp');
      return response.data;
    });

  return {
    type: LOGOUT_USER,
    payload: request,
  };
}
