import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
// import Vimeo from '@vimeo/vimeo';
import { Card, Col, Row, Tooltip, Modal, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from '../../Config/key';
import Vimeo from '@u-wave/react-vimeo';
const { Title, Text } = Typography;

// var Vimeo = require('vimeo').Vimeo;
function Course90501Page(props) {
  const [viewModal, setViewModal] = useState(false);
  const [paused, setPaused] = useState(false);
  const [videoId, setVideoId] = useState('');
  const thumbnailURL = `${config.S3_URL}/${config.S3_ENV}/videos/fixedThumbnail/`;
  let sendData = props.location.state;
  let surveyStatus = sendData.tokenInfo.survey_status === 5 ? true : false;

  //ModalでのOK
  const handleOk = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  const handleCancel = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  //ModalでのOK
  const handleCardClick = (v) => {
    // console.log(v);
    if (v === 'takeSurvey') {
      sendData.tokenInfo.survey_code = '50010';
      // console.log({ sendData });
      props.history.push({
        pathname: '/newtakesurvey',
        state: sendData,
      });
    } else {
      setVideoId(v);
      setPaused(false);
      setViewModal(true);
    }
  };

  const viewSurveyResultHandler = async () => {
    // console.log({ sendData });
    try {
      // console.log({ user });

      let url = '';
      if (sendData.tokenInfo.group_code) {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${sendData.tokenInfo.group_code}/${sendData.tokenInfo.user}/rcep_${sendData.tokenInfo._id}.pdf`;
      } else {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${sendData.tokenInfo.user}/rcep_${sendData.tokenInfo._id}.pdf`;
      }
      window.open(url, '_blank');
    } catch (error) {
      alert('サーベイ結果確認ができません。');
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '1rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>モチベーション・トレンド診断プログラム</Title>
        </div>
        {sendData.tokenInfo.coursePartView[0] ? (
          <Card
            title="モチベーション・トレンド診断"
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、モチベーション・トレンド診断の受検および、診断の概要と診断結果の見方を解説しています。\n【学習時間の目安：30分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="診断概要紹介"
                        src={thumbnailURL + '50010_01.png'}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 3).slice(-2)} : {('00' + 58).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick('856675504')}
                >
                  診断概要紹介
                </Card>
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable={false}
                    cover={
                      <img
                        alt="診断受検完了"
                        src={thumbnailURL + '50010_02_d.png'}
                      />
                    }
                  >
                    診断受検完了
                  </Card>
                ) : (
                  <Card
                    hoverable={true}
                    cover={
                      <img
                        alt="診断受検はこちら"
                        src={thumbnailURL + '50010_02.png'}
                        onClick={() => handleCardClick('takeSurvey')}
                      />
                    }
                  >
                    診断受検はこちら
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable={true}
                    cover={
                      <img
                        alt="診断結果PDFダウンロード"
                        src={thumbnailURL + '50010_03.png'}
                        onClick={viewSurveyResultHandler}
                      />
                    }
                  >
                    診断結果PDFダウンロード
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <img
                        alt="診断結果PDFダウンロード"
                        src={thumbnailURL + '50010_03_d.png'}
                      />
                    }
                  >
                    診断結果PDFダウンロード
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方"
                          src={thumbnailURL + '50010_04.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 26).slice(-2)} : {('00' + 13).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863443984')}
                  >
                    診断結果の読み方
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方"
                          src={thumbnailURL + '50010_04_d.png'}
                        />
                      </div>
                    }
                  >
                    診断結果の読み方
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <br />
        {sendData.tokenInfo.coursePartView[1] ? (
          <Card
            title="メンバーとの関わり方動画"
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、各モチベーション・トレンドごとに、職場でどのようにメンバーとの関わり方を工夫できるか解説しています。\n【学習時間の目安：60分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方①（充実／熟練／実用）"
                          src={thumbnailURL + '50010_05.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 5).slice(-2)} : {('00' + 16).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863444464')}
                  >
                    関わり方①（充実／熟練／実用）
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方①（充実／熟練／実用）"
                          src={thumbnailURL + '50010_05_d.png'}
                        />
                      </div>
                    }
                  >
                    関わり方①（充実／熟練／実用）
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方②（挑戦／危機／自負編）"
                          src={thumbnailURL + '50010_05.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 11).slice(-2)} : {('00' + 13).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863444749')}
                  >
                    関わり方②（挑戦／危機／自負編）
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方②（挑戦／危機／自負編）"
                          src={thumbnailURL + '50010_05_d.png'}
                        />
                      </div>
                    }
                  >
                    関わり方②（挑戦／危機／自負編）
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方③（関係／競争／称賛／報酬編）"
                          src={thumbnailURL + '50010_05.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 13).slice(-2)} : {('00' + 45).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863448261')}
                  >
                    関わり方③（関係／競争／称賛／報酬編）
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方③（関係／競争／称賛／報酬編）"
                          src={thumbnailURL + '50010_05_d.png'}
                        />
                      </div>
                    }
                  >
                    関わり方③（関係／競争／称賛／報酬編）
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方④価値観編"
                          src={thumbnailURL + '50010_05.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 17).slice(-2)} : {('00' + 38).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863448756')}
                  >
                    関わり方④価値観編
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方④価値観編"
                          src={thumbnailURL + '50010_05_d.png'}
                        />
                      </div>
                    }
                  >
                    関わり方④価値観編
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方⑤事例編"
                          src={thumbnailURL + '50010_05.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 11).slice(-2)} : {('00' + 41).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('863449076')}
                  >
                    関わり方⑤事例編
                  </Card>
                ) : (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="関わり方⑤事例編"
                          src={thumbnailURL + '50010_05_d.png'}
                        />
                      </div>
                    }
                  >
                    関わり方⑤事例編
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Modal
          title="動画再生"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={1000}
          footer={null}
        >
          <Vimeo
            video={videoId}
            className="vimeo pc"
            responsive
            autoplay={true}
            speed={true}
            paused={paused}
          />
        </Modal>
      </div>
    </Fragment>
  );
}

export default Course90501Page;
