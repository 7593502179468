import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import config from '../../Config/key';
import {
  Table,
  Divider,
  Tag,
  Button,
  message,
  Typography,
  Select,
  Modal,
  Space,
  Spin,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { userRole } from '../../Config/userRole';
import { useSelector } from 'react-redux';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { surveyCode } from '../../Config/surveyCode';
import { surveyStatus } from '../../Config/surveyStatus';
const { Column, ColumnGroup } = Table;
const ExcelJS = require('exceljs');
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const survey_status_complete = 5;

function RespondentMgrPage() {
  const [viewModal, setViewModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userToken, setUserToken] = useState([]);
  const [isAnswerd, setIsAnswerd] = useState(9);
  // const [usedToken, setUsedToken] = useState([]);
  // const [Users, setUsers] = useState([]); //全てのユーザ一覧
  const [tUsers, setTUsers] = useState([]); //グループフィルタリングされたユーザ一覧（全体）
  const [gUsers, setGUsers] = useState([]); //グループ・サーベイシーズンでフィルタリングされたユーザ一覧
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //グループ名とグループコード
  const [surveySeason, setSurveySeason] = useState([]);
  const [selSurveySeason, setSelSurveySeason] = useState([]);
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグルプコード
  const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  const [questionSetFilter, setQuestionSetFilter] = useState([]); //全ての設問セット
  // const [selQuestionSetFilter, setSelQuestionSetFilter] = useState([]); //全ての設問セット
  const [questionSet, setQuestionSet] = useState([]); //全ての設問セット
  const [selSurveyId, setSelSurveyId] = useState(''); //選択したサーベイID
  // const [role360, setRole360] = useState(1); //選択した360度関係：１：本人、２：上司、３：部下・同僚
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択したサーベイID
  const [selSurveySeasonIdFilter, setSelSurveySeasonIdFilter] = useState(''); //選択したサーベイID
  const [selQuestionSetId, setSelQuestionSetId] = useState(''); //選択した設問セットID
  const [selQuestionSetFilterId, setSelQuestionSetFilterId] = useState(''); //選択した設問セットID
  const [rowKeys, setRowKeys] = useState({
    //選択されたユーザ一覧
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
  });

  const usr = useSelector((state) => state.user);
  //9:管理者９が最高管理者
  // const userRole = [
  //   '停止中',
  //   '学習者',
  //   '管理者',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   '最高管理者',
  // ];

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log(usr.userData);

      const [surveyInfo, groupInfo, seasonInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      setSurvey(surveyInfo.data.survey);
      setSurveySeason(seasonInfo.data.surveySeason);
      setGUsers([]);
      setTUsers([]);
      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      if (!seasonInfo) throw 'サーベイシーズン取得に失敗しました。';
      // let userData = res.data;
      // if (!userData) throw 'ユーザー取得に失敗しました。';
      // console.log({ groups });
      // console.log({ survey });
      // console.log({ userData });
      // await userData.map((user) => {
      //   user.fullName = user.lastname + ' ' + user.name;
      //   user.roleText = userRole[user.role];
      //   if (user.survey_token.length === 0) {
      //     user.takeTokenCnt = 0;
      //     user.usedTokenCnt = 0;
      //     user.surveyProgress = 0;
      //   } else {
      //     user.takeTokenCnt = user.survey_token.length;
      //     user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
      //       return tokenInfo.survey_status === 5; //実施済み：5
      //     }).length;
      //     user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      //   }
      //   return user;
      // });
      // console.log({ userData });
      // setUsers(userData);
      // setGUsers(userData); //全体ユーザ情報を初期表示のためグループフィルターユーザーに格納
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const roleChangeHandler = async (userId, role, e) => {
    e.preventDefault();
    try {
      const res = await Axios.patch(`${config.USER_SERVER}/${userId}/role`, {
        role: role,
      });
      // console.log(res);
      if (res.data.success) {
        await readDB();
        message.success('権限を変更しました。');
      }
    } catch (error) {
      alert('権限変更に失敗しました。');
      console.log(error);
    }
  };

  //ユーザー削除を「role:0」設定
  const userDeleteHandler = async (userId, e) => {
    e.preventDefault();
    try {
      const res = await Axios.patch(`${config.USER_SERVER}/${userId}/role`, {
        role: 0,
      });
      // console.log(res);
      if (res.data.success) {
        await readDB();
        message.success('論理削除しました。');
      }
    } catch (error) {
      alert('論理削除に失敗しました。');
      console.log(error);
    }
  };

  function genPassword() {
    const length = 12;
    let password_numeric = '1234567890';
    let password_uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password_lowercase = 'abcdefghijklmnopqrstuvwxyz';
    let password_symbol = '!@#$%^&*[]{}=?().,;:|~_';
    let password = '';
    let password_base =
      password_numeric +
      password_uppercase +
      password_lowercase +
      password_symbol;
    //生成ルールの確認

    for (let i = 0; i < length; i++) {
      switch (i) {
        case 1:
          password += password_numeric.charAt(
            Math.floor(Math.random() * password_numeric.length)
          );
          break;
        case 4:
          password += password_uppercase.charAt(
            Math.floor(Math.random() * password_uppercase.length)
          );
          break;
        case 7:
          password += password_lowercase.charAt(
            Math.floor(Math.random() * password_lowercase.length)
          );
          break;
        case 10:
          password += password_symbol.charAt(
            Math.floor(Math.random() * password_symbol.length)
          );
          break;

        default:
          password += password_base.charAt(
            Math.floor(Math.random() * password_base.length)
          );
          break;
      }
    }
    return password;
  }

  const sendMailHandler = async (data) => {
    let mailList = {};
    try {
      // console.log({ rowKeys });
      // const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      const res = await Axios.get(`/api/mailTemplate/tempw`);
      const selMtInfo = res.data.mailTemplate;
      // console.log({ selMtInfo });
      // console.log({ data });
      mailList = {
        personalizations: [
          {
            to: [
              {
                email: data.user.email,
                name: `${data.user.lastname} ${data.user.name}様`,
              },
            ],
            bcc: { email: 'admin365@re-current.co.jp' },
            substitutions: {
              email: data.user.email,
              fullname: `${data.user.lastname} ${data.user.name}`,
              familyname: data.user.lastname,
              company: data.user.company,
              temp_pw: data.password,
            },
          },
        ],
        from: {
          email: selMtInfo.from_mail, // 送信元アドレス
          name: selMtInfo.from_name, // 送信者名
        },
        subject: selMtInfo.subject,
        html: selMtInfo.body.replace(/\r?\n/g, '<br>'),
        substitutionWrappers: ['{$', '$}'],
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      // const sendMailResult = { data: { success: false } };
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      if (sendMailResult.data.success) {
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 2,
        });
        console.log({ sendMailResult });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log(error);
      message.error({
        content: 'メール配信で問題が発生しました。',
        key: 'mail',
        duration: 2,
      });
    }
  };

  const passworChangedHandler = async (user, e) => {
    e.preventDefault();
    try {
      // console.log(user);
      const pw = genPassword();
      const data = {
        user: user,
        password: pw,
        changePassword: false,
      };

      // console.log({ pw });

      const res = await Axios.patch(`${config.USER_SERVER}/pc`, data);

      if (res.data.success) {
        sendMailHandler(data);
        await readDB();
        message.success(
          'パスワードを初期化しました。（臨時パスワードはメールで配信）'
        );
      }
    } catch (error) {
      alert('パスワードを初期化に失敗しました。');
      console.log(error);
    }
  };

  // console.log({ rowKeys });
  const { loading, selectedRowKeys } = rowKeys;

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = async (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    setRowKeys({ selectedRowKeys });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //リストフィルタリング
  const filterDatasource = async (groupCode) => {
    // setGUsers(
    //   Users.filter(function (user) {
    //     return user.group_code === groupCode;
    //   })
    // );
    message.loading({
      content: '読み込み中...',
      key: 'updateable',
      duration: 20,
    });
    const res = await Axios.get(`${config.USER_SERVER}/group/${groupCode}`);
    // console.log({ res });
    let userData = res.data.users;
    await userData.map((user) => {
      user.fullName = user.lastname + ' ' + user.name;
      user.roleText = userRole[user.role];
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.length;
        user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
          return tokenInfo.survey_status === 5; //実施済み：5
        }).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    setGUsers(userData);
    setTUsers(userData);
    message.success({
      content: '読み込み完了！',
      key: 'updateable',
      duration: 2,
    });
  };

  //グループを変更した場合
  const onChangeGroup = async (value) => {
    // console.log(`selected ${value}`);
    setSelGroupCode(value);
    filterDatasource(value); //リストフィルタリング
    const selGroup = groups.find((item) => item.group_code === value);
    // console.log({ selGroup });
    setSelGroupInfo(selGroup);
    setQuestionSet([]);
    setSelSurveySeason([]);
    setSelSurveyId('');
    setSelSurveySeasonId('');
    setSelSurveySeasonIdFilter('');
    // setRole360('');
    setSelQuestionSetFilterId('');
    setQuestionSetFilter([]);
    setIsAnswerd(9);
    // setSelSurveySeasonInfo([]);
  };

  //設問セットを変更した場合
  const onChangeQuestionSet = (value) => {
    // console.log(`selected ${value}`);
    setSelQuestionSetId(value); //選択した設問セットIDを格納
  };

  //設問セットを変更した場合
  const onChangeQuestionFilter = (value) => {
    setSelQuestionSetFilterId(value); //選択した設問セットIDを格納
    // const selQuestionSet = questionSetFilter.find((item) => item._id === value);
    // setSelQuestionSetFilter(selQuestionSet); //現在選択したQuestionSet
    // console.log({ questionSetFilter });

    setIsAnswerd(9);
    // console.log({ gUsers });
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        // if (isAnswerd === 9) {
        token = element.survey_token.find(
          (item) =>
            item.question_set_id === value &&
            item.survey_season_id === selSurveySeasonIdFilter
        );
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setGUsers(gUserArr);
  };

  //実施有無
  const onChangeAnswer = (value) => {
    // console.log({ value });
    setIsAnswerd(value);
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        if (value === 9) {
          if (selQuestionSetFilterId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetFilterId &&
                item.survey_season_id === selSurveySeasonIdFilter
            );
          } else {
            token = element.survey_token.find(
              (item) => item.survey_season_id === selSurveySeasonIdFilter
            );
          }
        } else if (value === 5) {
          if (selQuestionSetFilterId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetFilterId &&
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          } else {
            token = element.survey_token.find(
              (item) =>
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          }
        } else {
          if (selQuestionSetFilterId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetFilterId &&
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          } else {
            token = element.survey_token.find(
              (item) =>
                (item.survey_season_id === selSurveySeasonIdFilter &&
                  item.survey_status === value) ||
                item.survey_status === 7 //未回答の場合期限切れも含ませる
            );
          }
        }
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setGUsers(gUserArr);
  };

  //サーベイシーズンを変更した場合（チケット発行）
  const onChangeSeason = async (value) => {
    // console.log(`selected ${value}`);
    // console.log(`selected ${value}`);
    // console.log({ selGroupInfo });
    // console.log({ surveySeason });
    setSelSurveySeasonId(value);
    // filterDatasource(value); //リストフィルタリング
    const selSeasonInfo = selGroupInfo.survey_season.find(
      (item) => item._id === value
    );
    // console.log({ selSeasonInfo });
    // setSelSurveySeasonInfo(selSeasonInfo);
    // onChangeSurvey(selSeasonInfo.survey_id);
    const arrQuestionSet = selSeasonInfo.question_set;
    setSelSurveyId(selSeasonInfo.survey_id);
    setQuestionSet(arrQuestionSet);
    // console.log(survey.find((element) => element._id === value).question_set);
    // console.log({ arrQuestionSet });
    //選択したサーベイの設問セットが存在する場合筆頭のセットをデフォルトに選択したセットに設定する
    if (arrQuestionSet.length > 0) {
      setSelQuestionSetId(arrQuestionSet[0]._id);
    } else {
      setSelQuestionSetId('');
    }
  };
  //サーベイシーズンを変更した場合（絞り込み）
  const onChangeSeasonFilter = async (value) => {
    // console.log(`selected ${value}`);
    setIsAnswerd(9);
    setSelSurveySeasonIdFilter(value);
    setSelQuestionSetFilterId('');
    setQuestionSetFilter([]);
    // setRole360(1);
    const selSeasonInfo = selGroupInfo.survey_season.find(
      (item) => item._id === value
    );

    setSelSurveySeason(selSeasonInfo);
    const arrQuestionSet = selSeasonInfo.question_set;
    // setSelSurveyId(selSeasonInfo.survey_id);
    setQuestionSetFilter(arrQuestionSet);
    // const userIdArr = [];
    // const bossArr = [];
    // const partnerArr = [];
    const gUserArr = [];
    if (selSeasonInfo.survey_code === '40010') {
      //360度診断の場合
      // console.log({ selSeasonInfo });
      selSeasonInfo.relation_360.map((element) => {
        // userIdArr.push(element.user_id);
        // bossArr.push(element.boss);
        // partnerArr.push(element.partner);
        gUserArr.push(tUsers.find((item) => item._id === element.user_id)); //本人のみをFilter
        // console.log({ gUserArr });
      });
      setGUsers(computCnt(gUserArr, value));
    } else {
      // const gUserArr = [];
      for (let i = 0; i < tUsers.length; i++) {
        const element = tUsers[i];
        let token = {};
        if (element.survey_token) {
          token = element.survey_token.find(
            (item) => item.survey_season_id === value
          );
        }
        if (token) {
          gUserArr.push(element);
        }
      }
      // console.log({ gUserArr });
      // console.log({ value });

      setGUsers(computCnt(gUserArr, value));
    }
    // console.log({ value });
    // console.log({ selSeasonInfo });
    // console.log({ gUserArr });
  };

  const computCnt = (gUserArr, value) => {
    gUserArr.map((user) => {
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.filter(
          (tokenInfo) => tokenInfo.survey_season_id === value
        ).length;
        user.usedTokenCnt = user.survey_token.filter(
          (tokenInfo) =>
            tokenInfo.survey_season_id === value &&
            tokenInfo.survey_status === 5
          //実施済み：5
        ).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    return gUserArr;
  };

  // const onChangeRole360 = (value) => {
  //   setRole360(value); //選択した設問セットIDを格納
  //   // console.log({ value });
  //   const gUserArr = [];
  //   //360度診断の場合
  //   // console.log({ selSurveySeason });
  //   selSurveySeason.relation_360.map((element) => {
  //     switch (value) {
  //       case 1:
  //         gUserArr.push(tUsers.find((item) => item._id === element.user_id)); //本人のみをFilter
  //         break;

  //       case 2:
  //         element.boss.map((boss) =>
  //           gUserArr.push(tUsers.find((item) => item._id === boss))
  //         ); //上司のみをFilter
  //         break;

  //       case 3:
  //         element.partner.map((partner) =>
  //           gUserArr.push(tUsers.find((item) => item._id === partner))
  //         ); //部下・同僚のみをFilter
  //         // console.log({ gUserArr });
  //         break;

  //       default:
  //         break;
  //     }
  //     // console.log({ gUserArr });
  //   });
  //   setGUsers(gUserArr);
  // };

  //ボタン削除されたのでFuncも削除
  // const surveySendHandler = async (user, surveyCode, e) => {
  //   e.preventDefault();
  //   try {
  //     // console.log({ user });
  //     const sendDate = new Date();
  //     let expire = new Date();
  //     expire = expire.setDate(expire.getDate() + 30);

  //     const data = {
  //       user: user._id,
  //       survey_code: surveyCode,
  //       token_make_date: sendDate,
  //       token_expire: expire,
  //       token_valid: sendDate,
  //       survey_status: 0,
  //       group_code: user.group_code,
  //     };
  //     // console.log({ data });
  //     const res = await Axios.post('/api/surveyToken', data);
  //     if (res.data.success) {
  //       await readDB();
  //       message.success('Tokenを発行しました。');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
      // date.getMilliseconds()
    );
  };
  //「チケット発行確認」ボタンを押した時
  const viewTicketHandler = async (user, e) => {
    e.preventDefault();
    // console.log({ user });
    // console.log({ survey });
    try {
      let ut = [];
      user.survey_token.forEach((element) => {
        let element2 = element;
        if (element.survey_status === survey_status_complete) {
          element2.update = ymdhms(element.updatedAt);
        } else {
          element2.update = surveyStatus[element.survey_status];
        }
        element2.tokenExpire = ymdhms(element.token_expire);
        element2.tokenValid = ymdhms(element.token_valid);
        element2.groupCode = element.group_code;
        let surveyName = '';
        //シーズンIDが存在するならシーズン名を表示
        if (element.survey_season_id) {
          let surveySeasonInfo = surveySeason.find(
            (item) => item._id === element.survey_season_id
          );
          surveyName = surveySeasonInfo.season_name;
          element2.e3_option = surveySeasonInfo.e3_option;
        } else {
          surveyName = surveyCode[element.survey_code];
        }
        //Question Set Idが存在するならQuestionSet名を表示
        if (element.question_set_id && element.survey_code !== '20210') {
          let surveyInfo = survey.find(
            (item) => item._id === element.survey_id
          );
          // console.log({ surveyInfo });
          surveyName =
            surveyName +
            ' : ' +
            surveyInfo.question_set.find(
              (item) => item._id === element.question_set_id
            ).set_name;
        }

        element2.surveyName = surveyName;
        ut.push(element2);
      });
      // console.log({ ut });
      setUserToken(ut);
      setViewModal(true);
    } catch (error) {
      alert('サーベイ情報が読み込めませんでした。');
      console.log(error);
    }
  };

  //チケット発行処理
  const start = async () => {
    try {
      // console.log({ rowKeys });
      message.loading({
        content: 'チケット発行中...',
        key: 'ticket',
        duration: 20,
      });
      const data = [];
      const sendDate = new Date();
      let expire = new Date();
      expire = expire.setDate(expire.getDate() + 60);
      let selSurveyCode = survey.find(
        (element) => element._id === selSurveyId
      ).survey_code;
      rowKeys.selectedRowKeys.forEach((element) => {
        if (selQuestionSetId) {
          //設問セットが存在するなら
          data.push({
            user: element,
            survey_code: selSurveyCode,
            survey_id: selSurveyId,
            question_set_id: selQuestionSetId,
            token_make_date: sendDate,
            token_expire: expire,
            token_valid: sendDate,
            survey_status: 0,
            group_code: selGroupCode,
            survey_season_id: selSurveySeasonId,
          });
        } else {
          //設問セットが存在しないなら
          data.push({
            user: element,
            survey_code: selSurveyCode,
            survey_id: selSurveyId,
            token_make_date: sendDate,
            token_expire: expire,
            token_valid: sendDate,
            survey_status: 0,
            group_code: selGroupCode,
            survey_season_id: selSurveySeasonId,
          });
        }
      });
      // console.log({ data });
      setRowKeys({ selectedRowKeys: [], loading: true });
      const res = await Axios.post('/api/surveyToken/ticket', data);
      if (res.data.success) {
        await readDB();
        setSelGroupCode(null);
        setSelSurveyId(null);
        setSelQuestionSetId(null);
        setQuestionSet([]);
        // setSelSurveySeasonInfo([]);
        setSelGroupInfo([]);
        setRowKeys({
          selectedRowKeys: [],
          loading: false,
        });
        message.success({
          content: 'チケット発行完了',
          key: 'ticket',
          duration: 2,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // setTimeout(() => {
    // }, 1000);
  };

  //結果PDFを見せる：人事評価力診断のみ
  const viewSurveyResultHandler = async (tokenInfo, e) => {
    e.preventDefault();
    // console.log({ tokenInfo });
    try {
      let url = '';
      if (tokenInfo.groupCode) {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${tokenInfo.groupCode}/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      } else {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      }
      window.open(url, '_blank');
    } catch (error) {
      alert('サーベイ結果確認ができません。');
      console.log(error);
    }
  };

  const showConfirm = async (tokenI, type) => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0 ? resolve : reject, 3000);
        //   ansSendHandler();
        // }).catch(() => console.log('サーベイ結果提出でエラーが発生しました。'));
        // console.log({ tokenI });
        // console.log({ selSurveySeason });
        tokenI.type = type; //1:個人用、2:共有用, 30010:E3-Survey
        let result = {};
        if (type === 30010) {
          result = await Axios.post(
            '/api/dbModel100/makeReport30010Personal',
            tokenI
          );
        }
        // console.log({ result });
        if (result.data.success) {
          // message.success('結果生成が完了しました。マイ・サーベイに戻ります。');
          // props.history.push('/mysurvey');
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  //チケット削除
  const deleteTicketHandler = async (tokenInfo, e) => {
    // console.log(tokenInfo);

    try {
      const res = await Axios.delete(
        `/api/surveyToken/ticket/${tokenInfo.user}/${tokenInfo._id}`
      );
      if (res.data.success) {
        await readDB();
        setSelGroupCode(null);
        setSelSurveyId(null);
        setSelQuestionSetId(null);
        setQuestionSet([]);
        // setSelSurveySeasonInfo([]);
        setSelGroupInfo([]);
        setRowKeys({
          selectedRowKeys: [],
          loading: false,
        });
        setViewModal(false);
        message.success({
          content: 'チケット削除完了',
          key: 'ticket',
          duration: 2,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //ModalでのOK
  const handleOk = (e) => {
    // console.log(e);
    setViewModal(false);
  };

  const handleCancel = (e) => {
    // console.log(e);
    setViewModal(false);
  };

  const csvDownloadHandler = async (event) => {
    event.preventDefault();
    // console.log({ gUsers });
    try {
      message.loading({
        content: 'データ作成中...',
        key: 'makecsv',
        duration: 20,
      });
      const tempData = {};
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      let columns = [
        { header: 'ID', key: 'user_id' },
        { header: 'eメール', key: 'email' },
        { header: '名前', key: 'fullname' },
        { header: '社員番号', key: 'empNo' },
        { header: 'ロール', key: 'roletext' },
        { header: '会社名', key: 'company' },
        { header: '部署①', key: 'department' },
        { header: '部署②', key: 'department2' },
        { header: '部署③', key: 'department3' },
        { header: '部署④', key: 'department4' },
        { header: '部署⑤', key: 'department5' },
        { header: '役職', key: 'position' },
        { header: '等級', key: 'level_code' },
        { header: '性別', key: 'sex' },
        { header: '生年月日', key: 'birthday' },
        { header: '入社日', key: 'join_company' },
        { header: '雇用携帯', key: 'emp_type' },
        { header: '勤務地', key: 'work_location' },
        { header: '勤務時間制度・職制', key: 'work_time_rule' },
        { header: '勤務時間', key: 'work_time' },
        { header: '入社形態', key: 'join_type' },
        { header: '出向状態', key: 'go_out' },
        { header: '職種', key: 'job_type' },
      ];
      worksheet.columns = columns;
      // console.log(gUsers);
      for (let i = 0; i < gUsers.length; i++) {
        const element = gUsers[i];
        tempData.user_id = element._id;
        tempData.company = element.company;
        tempData.department = element.department;
        tempData.department2 = element.department2;
        tempData.department3 = element.department3;
        tempData.department4 = element.department4;
        tempData.department5 = element.department5;
        tempData.email = element.email;
        tempData.fullname = element.fullName;
        tempData.empNo = element.emp_no;
        tempData.roletext = element.roleText;
        tempData.position = element.position; //役職
        tempData.level_code = element.level_code; //等級
        switch (element.sex) {
          case 1:
            tempData.sex = '男性';
            break;
          case 2:
            tempData.sex = '女性';
            break;
          case 3:
            tempData.sex = 'その他';
            break;
          case 4:
            tempData.sex = '回答しない';
            break;
          default:
            tempData.sex = '未登録';
            break;
        }
        tempData.birthday = ymd(element.birthday); //生年月日
        tempData.join_company = ymd(element.join_company); //入社日
        tempData.emp_type = element.emp_type; //雇用携帯
        tempData.work_location = element.work_location; //勤務地
        tempData.work_time_rule = element.work_time_rule; //勤務時間制度・職制
        tempData.work_time = element.work_time; //勤務時間
        tempData.join_type = element.join_type; //入社携帯
        tempData.go_out = element.go_out; //出向状態
        tempData.job_type = element.job_type; //職種
        worksheet.addRow(tempData);
      }
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (selQuestionSetId) {
        let selQuestionSetInfo = questionSet.find(
          (item) => item._id === selQuestionSetId
        );
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeason.season_name}_${selQuestionSetInfo.set_name}_${isAnswerd}.xlsx`;
      } else {
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeason.season_name}_${isAnswerd}.xlsx`;
      }
      a.click();
      // ダウンロード後は不要なのでaタグを除去
      a.remove();
      // console.log({ excelData });
      // console.log(excelData.length);
      message.success({
        content: 'ダウンロード完了！',
        key: 'makecsv',
        duration: 2,
      });
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'ダウンロード失敗！',
        key: 'makecsv',
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          ■絞り込み｜企業選択
          <Select
            showSearch
            style={{
              marginBottom: 10,
              marginLeft: 5,
              marginRight: 5,
              width: 200,
            }}
            placeholder="企業選択"
            optionFilterProp="items"
            onChange={onChangeGroup}
            value={selGroupCode}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {groups.map((element) => {
              // console.log({ element });
              return (
                <Option value={element.group_code} key={element._id}>
                  {element.group_name}
                </Option>
              );
            })}
          </Select>
          {selGroupInfo.survey_season && (
            <Fragment>
              サーベイシーズン選択:
              <Select
                showSearch
                style={{
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  width: 250,
                }}
                placeholder="シーズン選択"
                optionFilterProp="items"
                onChange={onChangeSeasonFilter}
                value={selSurveySeasonIdFilter}
                defaultValue={selGroupInfo.survey_season[0].season_name}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {selGroupInfo.survey_season.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_description}
                    </Option>
                  );
                })}
              </Select>
              {questionSetFilter.length > 0 && (
                <Select
                  showSearch
                  style={{
                    marginBottom: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    width: 200,
                  }}
                  placeholder="設問セット選択"
                  optionFilterProp="items"
                  onChange={onChangeQuestionFilter}
                  value={selQuestionSetFilterId}
                  defaultValue={questionSetFilter[0].set_name}
                  filterOption={(input, option) =>
                    option.props.items
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionSetFilter.map((element) => {
                    return (
                      <Option value={element._id} key={element._id}>
                        {element.set_name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Fragment>
          )}
          <Fragment>
            実施選択：
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 100,
              }}
              placeholder="対象選択"
              optionFilterProp="items"
              onChange={onChangeAnswer}
              value={isAnswerd}
              disabled={!selSurveySeasonIdFilter}
            >
              <Option value={9} key={9}>
                全員
              </Option>
              <Option value={0} key={0}>
                未回答
              </Option>
              <Option value={5} key={5}>
                回答済み
              </Option>
            </Select>
          </Fragment>
          <Button
            type="primary"
            onClick={csvDownloadHandler}
            disabled={!selSurveySeasonIdFilter}
          >
            CSVダウンロード
          </Button>
          <Fragment>　対象者：{gUsers.length}名</Fragment>
          <br />
        </div>
        <Table
          rowSelection={rowSelection}
          dataSource={gUsers}
          size="middle"
          rowKey="_id"
        >
          <Column
            title="名前"
            dataIndex="fullName"
            key="fullName"
            aligh="right"
          />
          <Column title="メール" dataIndex="email" key="email" aligh="center" />
          <Column
            title="会社"
            dataIndex="company"
            key="company"
            aligh="center"
          />
          <Column
            title="部署"
            dataIndex="department"
            key="department"
            aligh="center"
          />
          <Column
            title="チケット発行"
            key="ticket"
            render={(text, record) => (
              <span>
                <Button
                  type={'primary'}
                  size="small"
                  onClick={(e) => viewTicketHandler(record, e)}
                  disabled={
                    record.role === 0 || record.takeTokenCnt === 0
                      ? true
                      : false
                  }
                >
                  発行チケット確認
                </Button>
                <span>
                  {record.takeTokenCnt !== 0 &&
                  record.takeTokenCnt === record.usedTokenCnt ? (
                    <Text mark>
                      {' 発行：' +
                        record.takeTokenCnt +
                        ' | 実施済：' +
                        record.usedTokenCnt}
                    </Text>
                  ) : record.takeTokenCnt !== 0 ? (
                    <Text type="danger">
                      {' 発行：' +
                        record.takeTokenCnt +
                        ' | 実施済：' +
                        record.usedTokenCnt}
                    </Text>
                  ) : (
                    <Text>
                      {' 発行：' +
                        record.takeTokenCnt +
                        ' | 実施済：' +
                        record.usedTokenCnt}
                    </Text>
                  )}
                </span>
              </span>
            )}
          />
          <Column
            title="権限付与"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}

                <Button
                  type={
                    record.role === 1 || record.role === 0
                      ? 'primary'
                      : 'default'
                  }
                  size="small"
                  onClick={(e) => roleChangeHandler(record._id, 1, e)}
                  disabled={usr.userData.role !== 9 ? true : false}
                >
                  {record.role === 0 ? '復　元' : '受講者'}
                </Button>
                <Divider type="vertical" />
                <Button
                  type={record.role === 3 ? 'primary' : 'default'}
                  size="small"
                  onClick={(e) => roleChangeHandler(record._id, 3, e)}
                  disabled={
                    record.role === 0 || usr.userData.role !== 9 ? true : false
                  }
                >
                  企業管理者
                </Button>
              </span>
            )}
          />
          <Column
            title="パスワード"
            key="passchange"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={(e) => passworChangedHandler(record, e)}
                  disabled={record.role === 0 ? true : false}
                >
                  初期化
                </Button>
              </span>
            )}
          />
          <Column
            title="削除"
            key="delete"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={(e) => userDeleteHandler(record._id, e)}
                  disabled={usr.userData.role === 9 ? false : true}
                >
                  ユーザ停止
                </Button>
              </span>
            )}
          />
        </Table>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          ■チケット発行｜
          {selGroupInfo.survey_season && (
            <Fragment>
              サーベイシーズン選択：
              <Select
                showSearch
                style={{
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  width: 400,
                }}
                placeholder="シーズン選択"
                optionFilterProp="items"
                onChange={onChangeSeason}
                value={selSurveySeasonId}
                defaultValue={selGroupInfo.survey_season[0].season_name}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {selGroupInfo.survey_season.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_description}
                    </Option>
                  );
                })}
              </Select>
            </Fragment>
          )}
          {questionSet.length > 0 && (
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 300,
              }}
              placeholder="設問セット選択"
              optionFilterProp="items"
              onChange={onChangeQuestionSet}
              value={selQuestionSetId}
              defaultValue={questionSet[0].set_name}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {questionSet.map((element) => {
                return (
                  <Option value={element._id} key={element._id}>
                    {element.set_name}
                  </Option>
                );
              })}
            </Select>
          )}
          <Button
            type="primary"
            onClick={start}
            disabled={
              !hasSelected ||
              !selGroupCode ||
              !selSurveyId ||
              selSurveySeason.survey_code === '40010' ||
              !(usr.userData.role === 9)
            }
            loading={loading}
          >
            チケット発行
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? ` ${selectedRowKeys.length}項目選択中` : ''}
          </span>
        </div>
      </div>
      <div>
        <Modal
          title="チケット確認"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={900}
        >
          <div style={{ width: '100%', margin: '0rem auto' }}>
            <Table dataSource={userToken} size="middle" rowKey="_id">
              <Column
                title="サーベイ名"
                dataIndex="surveyName"
                key="surveyName"
                aligh="right"
              />
              {/* <Column
                title="サーベイ開始日"
                dataIndex="tokenValid"
                key="tokenValid"
                aligh="center"
              /> */}
              <Column
                title="有効期限"
                dataIndex="tokenExpire"
                key="tokenExpire"
                aligh="center"
              />
              {/* <Column
                title="サーベイ実施日"
                dataIndex="update"
                key="update"
                aligh="center"
              /> */}
              <Column
                title="実施日"
                key="deleteTicket"
                aligh="center"
                render={(text, record) =>
                  record.survey_status === survey_status_complete ? (
                    <span>{record.update}</span>
                  ) : (
                    <span>
                      <Button
                        type={'primary'}
                        size="small"
                        onClick={(e) => deleteTicketHandler(record, e)}
                        disabled={usr.userData.role === 9 ? false : true}
                      >
                        未実施：削除
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )
                }
              />
              <Column
                title="結果確認"
                key="action"
                render={(text, record) => (
                  <span>
                    {record.survey_code === '10010' ||
                    record.survey_code === '90101' ||
                    record.survey_code === '50010' ||
                    record.survey_code === '90501' ||
                    record.survey_code === '60010' ||
                    record.survey_code === '90601' ? (
                      <Button
                        type={'primary'}
                        size="small"
                        onClick={(e) => viewSurveyResultHandler(record, e)}
                        disabled={
                          record.survey_status === survey_status_complete
                            ? false
                            : true
                        }
                      >
                        サーベイ結果確認 &nbsp;{' '}
                        <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                      </Button>
                    ) : (record.survey_code === '30010' ||
                        record.survey_code === '90301') &&
                      record.e3_option ? ( //E3-Survey単体orコース　 + パーソナル診断Optionあり
                      <Space>
                        <Button
                          type={'primary'}
                          size="small"
                          onClick={(e) => showConfirm(record, 30010)}
                          disabled={record.survey_status === 5 ? false : true}
                        >
                          サーベイ結果確認 &nbsp;{' '}
                          <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                        </Button>
                      </Space>
                    ) : (
                      <Button type={'primary'} size="small" disabled={true}>
                        個別結果はありません
                      </Button>
                    )}
                  </span>
                )}
              />
            </Table>
          </div>
        </Modal>
        <Modal
          title="サーベイ結果ファイル生成中..."
          open={isModalVisible}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: true }}
          bodyStyle={{ textAlign: 'center' }}
        >
          <Spin size="large" />
        </Modal>
      </div>
    </Fragment>
  );
}

export default RespondentMgrPage;
