import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Card,
  Input,
  Select,
  Tabs,
  Radio,
  Space,
  Row,
  Col,
  Modal,
  Spin,
} from 'antd';
import Axios from 'axios';
import config from '../../Config/key';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const PDF80010DowlnoadPage = () => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupDepartment, setSelGroupDepartment] = useState([]); //選択されたグループ情報
  const [depArr, setDepArr] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  // const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  // const [selQuestionSetId, setSelQuestionSetId] = useState(''); //全てのサーベイ情報
  // const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [submitSW, setSubmitSW] = useState(true); //SubmitボタンDisable調整 true => 表示
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [positionArr, setPositionArr] = useState([]);
  const [levelCodeArr, setLevelCodeArr] = useState([]);

  const [radio, setRadio] = useState({
    tabs: 'basic', //basic:基本集計、cross:クロス集計
    radio01: 0, //クロス軸選択、0:年代,1:所属部署, 2:役職, 3:職位, 4:職制, 5:勤務地, 6:勤務時間制度, 7:勤務時間, 8:入社形態, 9:勤続年数
    radio02: 0, //クロス軸選択、0:年代,1:所属部署, 2:役職, 3:職位, 4:職制, 5:勤務地, 6:勤務時間制度, 7:勤務時間, 8:入社形態, 9:勤続年数
  });
  const cross_text = [
    '性別',
    '年代',
    '所属部署',
    '役職',
    '職位',
    '職制',
    '勤務地',
    '勤務時間制度',
    '勤務時間',
    '入社形態',
    '勤続年数',
  ];

  const usr = useSelector((state) => state.user);

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      setUserInfo(usr.userData);
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      // setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);

      if (!groups) throw new Error('グループ取得に失敗しました。');
      if (!survey) throw new Error('サーベイ取得に失敗しました。');
      if (usr.userData.role === 3 || usr.userData.role === 4) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(
          sgi.survey_season.filter((item) => item.survey_code === '80010')
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log(sgi.department);
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setPositionArr(sgi.position);
    setLevelCodeArr(sgi.level_code);
    const dp = [];
    for (let i = 0; i < sgi.department.length; i++) {
      if (sgi.department[i].split('／').length < 2) {
        dp.push(sgi.department[i]);
      }
    }

    // console.log(dp);
    const child = [];
    const dep = [];
    for (let i = 0; i < dp.length; i++) {
      const element = dp[i];
      child.push(<Option key={element}>{element}</Option>);
      dep.push(element);
    }
    setSelGroupDepartment(child);
    setDepArr(dep);

    setGroupSurveySeasonInfo(
      sgi.survey_season.filter((item) => item.survey_code === '80010')
    );
    setSelSurveySeasonId('');
    // setSelQuestionSetId('');
    // setQuestionSet([]);
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsModalVisible(true);
      setSubmitSW(false);
      const sendData = {
        selGroupInfo: selGroupInfo,
        selSurveySeasonInfo: selSurveySeasonInfo,
        searchInfo: radio,
      };
      // console.log({ sendData });
      const res = await Axios.post('/api/dbModel100/makeReport80010', sendData);
      const url = `${config.S3_URL}/${res.data.s3Path}`;
      // console.log({ url });
      setTimeout(() => {
        message.success({
          content: 'ファイル生成完了！',
          key: 'makecsv',
          duration: 2,
        });
        setSubmitSW(true);
        setIsModalVisible(false);

        window.open(url, '_blank');
      }, 3000);
    } catch (error) {
      console.log(error.message);
      setIsModalVisible(false);
      message.error({
        content: error.message,
        key: 'makecsv',
        duration: 4,
      });
      setSelSurveySeasonId('');
      setSelGroupCode('');
      setSelGroupInfo([]);
      setSubmitSW(true);

      // window.location.reload(false);
    }
  };
  const onChangeTabs = async (key) => {
    // console.log('Tabbed', key);
    setRadio({ ...radio, tabs: key });
  };
  const onChangeRadio01 = async (e) => {
    // console.log('radio01 checked', e.target.value);
    setRadio({ ...radio, radio01: e.target.value });
  };
  const onChangeRadio02 = async (e) => {
    // console.log('radio02 checked', e.target.value);
    setRadio({ ...radio, radio02: e.target.value });
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '1100px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>ダイバーシティ組織診断結果ダウンロード(PDF)</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={userInfo.role === 3 || userInfo.role === 4}
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="サーベイ選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Text strong>結果フォーマットを選択してください。（必須）</Text>
          <Tabs
            onChange={onChangeTabs}
            type="card"
            size="large"
            style={{ marginBottom: 30 }}
          >
            <TabPane tab="基本結果" key="basic">
              <Row>
                <Card
                  title="基本結果"
                  type="inner"
                  style={{
                    marginTop: 16,
                    marginLeft: 8,
                    marginRight: 8,
                    width: 350,
                  }}
                >
                  ・検索条件：ALL
                </Card>
              </Row>
            </TabPane>
            <TabPane tab="クロス集計：項目①ｘ項目②" key="cross">
              <Row>
                <Card
                  title="項目①"
                  type="inner"
                  style={{ marginTop: 16, width: 350 }}
                >
                  <Radio.Group onChange={onChangeRadio01} value={radio.radio01}>
                    <Space direction="vertical">
                      {cross_text.map((value, index) => {
                        return <Radio value={index}>{value}</Radio>;
                      })}
                    </Space>
                  </Radio.Group>
                </Card>
                <Card
                  title="項目②"
                  type="inner"
                  style={{ marginTop: 16, width: 350 }}
                >
                  <Radio.Group onChange={onChangeRadio02} value={radio.radio02}>
                    <Space direction="vertical">
                      {cross_text.map((value, index) => {
                        if (radio.radio01 != index)
                          return <Radio value={index}>{value}</Radio>;
                      })}
                    </Space>
                  </Radio.Group>
                </Card>
              </Row>
            </TabPane>
          </Tabs>
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!(submitSW && selSurveySeasonId)}
          >
            ダウンロード
          </Button>
        </Form>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
};

export default PDF80010DowlnoadPage;
