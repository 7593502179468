import React from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { registerUser } from '../../../_actions/user_actions';
// import { useDispatch } from 'react-redux';
import config from '../../Config/key';

import { message, Form, Input, Button, Typography } from 'antd';
const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function ResetUserPage(props) {
  // const dispatch = useDispatch();
  function genPassword() {
    const length = 12;
    let password_numeric = '1234567890';
    let password_uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password_lowercase = 'abcdefghijklmnopqrstuvwxyz';
    let password_symbol = '!@#$%^&*[]{}=?().,;:|~_';
    let password = '';
    let password_base =
      password_numeric +
      password_uppercase +
      password_lowercase +
      password_symbol;
    //生成ルールの確認

    for (let i = 0; i < length; i++) {
      switch (i) {
        case 1:
          password += password_numeric.charAt(
            Math.floor(Math.random() * password_numeric.length)
          );
          break;
        case 4:
          password += password_uppercase.charAt(
            Math.floor(Math.random() * password_uppercase.length)
          );
          break;
        case 7:
          password += password_lowercase.charAt(
            Math.floor(Math.random() * password_lowercase.length)
          );
          break;
        case 10:
          password += password_symbol.charAt(
            Math.floor(Math.random() * password_symbol.length)
          );
          break;

        default:
          password += password_base.charAt(
            Math.floor(Math.random() * password_base.length)
          );
          break;
      }
    }
    return password;
  }

  const sendMailHandler = async (data) => {
    let mailList = {};
    try {
      // console.log({ rowKeys });
      // const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      const res = await Axios.get(`/api/mailTemplate/tempw`);
      const selMtInfo = res.data.mailTemplate;
      // console.log({ selMtInfo });
      // console.log({ data });
      mailList = {
        personalizations: [
          {
            to: [
              {
                email: data.user.email,
                name: `${data.user.lastname} ${data.user.name}様`,
              },
            ],
            bcc: { email: 'admin365@re-current.co.jp' },
            substitutions: {
              email: data.user.email,
              fullname: `${data.user.lastname} ${data.user.name}`,
              familyname: data.user.lastname,
              company: data.user.company,
              temp_pw: data.password,
            },
          },
        ],
        from: {
          email: selMtInfo.from_mail, // 送信元アドレス
          name: selMtInfo.from_name, // 送信者名
        },
        subject: selMtInfo.subject,
        html: selMtInfo.body.replace(/\r?\n/g, '<br>'),
        substitutionWrappers: ['{$', '$}'],
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      // const sendMailResult = { data: { success: false } };
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      if (sendMailResult.data.success) {
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 2,
        });
        // console.log({ sendMailResult });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log(error);
      message.error({
        content: 'メール配信で問題が発生しました。',
        key: 'mail',
        duration: 2,
      });
    }
  };

  const passworChangedHandler = async (user) => {
    try {
      // console.log(user);
      const pw = genPassword();
      const data = {
        user: user,
        password: pw,
        changePassword: false,
      };

      // console.log({ pw });

      const res = await Axios.patch(`${config.USER_SERVER}/pc`, data);

      if (res.data.success) {
        sendMailHandler(data);
        message.success(
          'パスワードを初期化しました。（臨時パスワードはメールで配信）'
        );
        return { success: true };
      }
    } catch (error) {
      console.log(error);
      return { success: false, message: 'パスワードを初期化に失敗しました。' };
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        lastName: '',
        name: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('名前（名）を入力してください。'),
        lastName: Yup.string().required('名前（姓）を入力してください。'),
        email: Yup.string()
          .email('メールの形式ではありません。')
          .required('メールを入力してください。'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        // setTimeout(() => {
        //   let dataToSubmit = {
        //     email: values.email,
        //     name: values.name,
        //     lastname: values.lastName,
        //   };

        //   dispatch(resetUser(dataToSubmit)).then((response) => {
        //     if (response.payload.success) {
        //       props.history.push('/login');
        //     } else {
        //       alert(response.payload.err.errmsg);
        //     }
        //   });

        //   setSubmitting(false);
        // }, 500);
        try {
          const data = {
            email: values.email,
            name: values.name,
            lastname: values.lastName,
          };
          // console.log({ data });
          const res = await Axios.get(
            `${config.USER_SERVER}/email/${data.email}`
          );
          if (res.data.success && res.data.user) {
            if (
              data.name.trim() === res.data.user.name.trim() &&
              data.lastname.trim() === res.data.user.lastname.trim()
            ) {
              const res1 = await passworChangedHandler(res.data.user);
              if (res1.success) {
                message.success({
                  content: 'パスワードをリセットしました。',
                  key: 'reset',
                  duration: 5,
                });
                props.history.push('/login');
              } else {
                throw new Error(res1.message);
              }
            } else {
              setSubmitting(false);
              message.error({
                content: 'ユーザー情報を確認ください。',
                key: 'reset',
                duration: 5,
              });
              throw new Error('ユーザー情報を確認ください。');
            }
          } else {
            setSubmitting(false);
            message.error({
              content: 'ユーザーが存在しません。',
              key: 'reset',
              duration: 5,
            });
            throw new Error('ユーザーが存在しません。');
          }
          // console.log(res);
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return (
          <div className="app">
            <h2>パスワードリセット</h2>
            <Form
              style={{ minWidth: '375px' }}
              {...formItemLayout}
              onSubmit={handleSubmit}
            >
              <Form.Item required label="名前（姓）">
                <Input
                  id="lastName"
                  placeholder="名前（姓）入力"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.lastName && touched.lastName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />

                {errors.lastName && touched.lastName && (
                  <div className="input-feedback">
                    <br />
                    {errors.lastName}
                  </div>
                )}
              </Form.Item>
              <br />
              <Form.Item required label="名前（名）">
                <Input
                  id="name"
                  placeholder="名前（名）入力"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.name && touched.name
                      ? 'text-input error'
                      : 'text-input'
                  }
                />

                {errors.name && touched.name && (
                  <div className="input-feedback">
                    <br />
                    {errors.name}
                  </div>
                )}
              </Form.Item>
              <br />
              <Form.Item
                required
                label="メール"
                hasFeedback
                validateStatus={
                  errors.email && touched.email ? 'error' : 'success'
                }
              >
                <Input
                  id="email"
                  placeholder="メール入力"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />

                {errors.email && touched.email && (
                  <div className="input-feedback">
                    <br />
                    {errors.email}
                  </div>
                )}
              </Form.Item>
              <br />
              <Form.Item {...tailFormItemLayout}>
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  disabled={isSubmitting}
                >
                  リセット
                </Button>
              </Form.Item>
            </Form>
            <Text type="message">
              ※臨時パスワードは登録済みのeメールに自動配信されます。
              <br />{' '}
              ※臨時パスワードを利用してログインに成功した場合は、必ずパスワードを変更してください。
            </Text>
          </div>
        );
      }}
    </Formik>
  );
}

export default ResetUserPage;
