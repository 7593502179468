import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import config from '../../Config/key';
import { Table, Button, message, Typography, Select, Modal, Input } from 'antd';
import { userRole } from '../../Config/userRole';
import { useSelector } from 'react-redux';
const { Column, ColumnGroup } = Table;
const ExcelJS = require('exceljs');
const { Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const RemindMailSendPage = (props) => {
  const [isAnswerd, setIsAnswerd] = useState(0);
  const [tUsers, setTUsers] = useState([]); //グループフィルタリングされたユーザ一覧（全体）
  const [gUsers, setGUsers] = useState([]); //グループ・サーベイシーズンでフィルタリングされたユーザ一覧
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //グループ名とグループコード
  const [surveySeason, setSurveySeason] = useState([]);
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグルプコード
  const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  const [selSurvey, setSelSurvey] = useState([]); //サーベイ名とサーベイコード
  const [questionSet, setQuestionSet] = useState([]); //全ての設問セット
  const [selSurveyId, setSelSurveyId] = useState(''); //選択したサーベイID
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択したサーベイID
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState({}); //選択したサーベイID
  const [selSurveySeasonIdFilter, setSelSurveySeasonIdFilter] = useState(''); //選択したサーベイID
  const [selQuestionSetId, setSelQuestionSetId] = useState(''); //選択した設問セットID
  const [selQuestionSetInfo, setSelQuestionSetInfo] = useState({}); //選択した設問セットID
  const [mtInfo, setMtInfo] = useState([]);
  const [selMtId, setSelMtId] = useState('');
  const [ccMailValue, setCCMailValue] = useState('');
  const [bccMailValue, setBCCMailValue] = useState('');
  const [testMailValue, setTestMailValue] = useState('');
  const usr = useSelector((state) => state.user);
  //9:管理者９が最高管理者
  // const userRole = [
  //   '停止中',
  //   '学習者',
  //   '管理者',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   '最高管理者',
  // ];

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log(usr.userData);

      const [surveyInfo, groupInfo, seasonInfo, mtInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        Axios.get('/api/mailTemplate/'),
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);
      setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);
      setSurveySeason(seasonInfo.data.surveySeason);
      setMtInfo(mtInfo.data.mailTemplate);
      setGUsers([]);
      setTUsers([]);
      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      if (!seasonInfo) throw 'サーベイシーズン取得に失敗しました。';
      if (!mtInfo) throw 'メールテンプレート取得に失敗しました。';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  //リストフィルタリング
  const filterDatasource = async (groupCode) => {
    message.loading({ content: '読み込み中...', key: 'loading', duration: 20 });
    const res = await Axios.get(`${config.USER_SERVER}/group/${groupCode}`);
    // console.log({ res });
    let userData = res.data.users;
    await userData.map((user) => {
      user.fullName = user.lastname + ' ' + user.name;
      user.roleText = userRole[user.role];
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.length;
        user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
          return tokenInfo.survey_status === 5; //実施済み：5
        }).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    setGUsers(userData);
    setTUsers(userData);
    message.success({
      content: '読み込み完了！',
      key: 'loading',
      duration: 2,
    });
  };

  //グループを変更した場合
  const onChangeGroup = async (value) => {
    // console.log(`selected ${value}`);
    setSelGroupCode(value);
    filterDatasource(value); //リストフィルタリング
    const selGroup = groups.find((item) => item.group_code === value);
    // console.log({ selGroup });
    setSelGroupInfo(selGroup);
    setQuestionSet([]);
    setSelSurveyId('');
    setSelSurveySeasonId('');
    setSelSurveySeasonIdFilter('');
    setSelQuestionSetId('');
    setIsAnswerd(0);
    // setSelSurveySeasonInfo([]);
  };

  //サーベイシーズンを変更した場合
  const onChangeSeason = async (value) => {
    setSelSurveySeasonId(value);
    const selSeasonInfo = selGroupInfo.survey_season.find(
      (item) => item._id === value
    );
    const arrQuestionSet = selSeasonInfo.question_set;
    setSelSurveySeasonInfo(selSeasonInfo);
    setSelSurveyId(selSeasonInfo.survey_id);
    setQuestionSet(arrQuestionSet);
    setSelQuestionSetId('');
    setIsAnswerd(0);
    // if (arrQuestionSet.length > 0) {
    //   setSelQuestionSetId(arrQuestionSet[0]._id);
    // } else {
    //   setSelQuestionSetId('');
    // }
  };
  //サーベイシーズンを変更した場合
  const onChangeSeasonFilter = async (value) => {
    await onChangeSeason(value);
    setSelSurveySeasonIdFilter(value);
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        token = element.survey_token.find(
          (item) => item.survey_season_id === value && item.survey_status === 0
        );
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setGUsers(gUserArr);
  };

  //設問セットを変更した場合
  const onChangeQuestionSet = (value) => {
    setSelQuestionSetId(value); //選択した設問セットIDを格納
    const selQuestionSet = questionSet.find((item) => item._id === value);
    setSelQuestionSetInfo(selQuestionSet); //現在選択したQuestionSet
    // console.log({ selQuestionSet });

    setIsAnswerd(0);
    // console.log({ gUsers });
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        // if (isAnswerd === 9) {
        token = element.survey_token.find(
          (item) =>
            item.question_set_id === value &&
            item.survey_season_id === selSurveySeasonIdFilter &&
            item.survey_status === 0
        );
        // } else {
        //   token = element.survey_token.find(
        //     (item) =>
        //       item.question_set_id === value &&
        //       item.survey_season_id === selSurveySeasonIdFilter &&
        //       item.survey_status === isAnswerd
        //   );
        // }
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setGUsers(gUserArr);
  };

  //実施有無
  const onChangeAnswer = (value) => {
    // console.log({ value });
    setIsAnswerd(value);
    const gUserArr = [];
    for (let i = 0; i < tUsers.length; i++) {
      const element = tUsers[i];
      let token = {};
      if (element.survey_token) {
        if (value === 9) {
          if (selQuestionSetId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetId &&
                item.survey_season_id === selSurveySeasonIdFilter
            );
          } else {
            token = element.survey_token.find(
              (item) => item.survey_season_id === selSurveySeasonIdFilter
            );
          }
        } else {
          if (selQuestionSetId) {
            token = element.survey_token.find(
              (item) =>
                item.question_set_id === selQuestionSetId &&
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          } else {
            token = element.survey_token.find(
              (item) =>
                item.survey_season_id === selSurveySeasonIdFilter &&
                item.survey_status === value
            );
          }
        }
      }
      if (token) {
        gUserArr.push(element);
      }
    }
    // console.log({ gUserArr });
    setGUsers(gUserArr);
  };

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };

  //メール配信
  const sendMailHandler = async () => {
    let mailList = {}; //最終分類用
    try {
      // console.log({ rowKeys });
      message.loading({ content: 'メール配信中...', key: 'mail' });
      const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      const ccString = ccMailValue
        .replace(/\s+/g, '') //文字列の中の空白を削除
        .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
      const bccString = bccMailValue
        .replace(/\s+/g, '') //文字列の中の空白を削除
        .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
      let cc = [];
      let bcc = [];
      let ccArr = [];
      let bccArr = [];
      if (ccString) {
        cc = ccString.split(';');
        cc.map((element) => {
          ccArr.push({ email: element });
        });
      }
      if (bccString) {
        bcc = bccString.split(';');
        bcc.map((element) => {
          bccArr.push({ email: element });
        });
      }

      let personalizations = [];
      gUsers.map((record) => {
        let substitutions = {};
        // console.log(record);
        let init_pass = '';
        if (record.change_password || !record.init_pass) {
          init_pass = '（登録済みパスワード）';
        } else {
          init_pass = record.init_pass;
        }
        if (
          selQuestionSetInfo.survey_code === '40010' &&
          selQuestionSetInfo.sub_type === '5'
        ) {
          //360診断の協力者の場合
          substitutions = {
            email: record.email,
            fullname: `${record.lastname} ${record.name}`,
            familyname: record.lastname,
            company: record.company,
            tname: selSurveySeasonInfo.t_name,
            target: searchUser(record._id),
            emp_no: record.emp_no,
            init_pass: init_pass,
          };
        } else {
          substitutions = {
            email: record.email,
            fullname: `${record.lastname} ${record.name}`,
            familyname: record.lastname,
            company: record.company,
            tname: selSurveySeasonInfo.t_name,
            emp_no: record.emp_no,
            init_pass: init_pass,
          };
        }
        personalizations.push({
          to: [
            {
              email: record.email,
              name: `${record.lastname} ${record.name}様`,
            },
          ],
          cc: ccArr,
          bcc: bccArr,
          substitutions: substitutions,
        });
      });
      mailList = {
        personalizations: personalizations,
        from: {
          email: selMtInfo.from_mail, // 送信元アドレス
          name: selMtInfo.from_name, // 送信者名
        },
        subject: selMtInfo.subject,
        html: selMtInfo.body.replace(/\r?\n/g, '<br>'),
        substitutionWrappers: ['{$', '$}'],
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      // console.log({ selSurveySeasonInfo });
      //----------------------------テスト用--------------------------------------
      // const sendMailResult = { data: { success: false } };
      //----------------------------本番用---------------------------------------
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      //------------------------------------------------------------------------
      if (sendMailResult.data.success) {
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 2,
        });
        // console.log({ sendMailResult });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log(error);
      message.error({
        content: 'メール配信で問題が発生しました。',
        key: 'mail',
        duration: 2,
      });
    }
  };

  const searchUser = (userId) => {
    //1. selSurveySeasonInfo.relation_360のbossとpartnerを検索してuserIdを探す
    //2. 探したオブジェクトのuser_id（本人のIDを取得
    //3. _idでtUserを検索して名前、所属などの情報をReturn
    let heroId = [];
    selSurveySeasonInfo.relation_360.map((item) => {
      item.boss.map((item2) => {
        if (item2 === userId) heroId = item.user_id;
      });
      item.partner.map((item2) => {
        if (item2 === userId) heroId = item.user_id;
      });
    });
    const heroInfo = tUsers.find((item) => item._id === heroId);
    // console.log({ tUsers });
    // console.log({ heroInfo });

    return `${heroInfo.department} ${heroInfo.lastname} ${heroInfo.name}`;
  };
  //テストメール配信
  const sendTestMailHandler = async () => {
    let mailList = {}; //最終分類用
    try {
      // console.log({ rowKeys });
      message.loading({ content: 'テストメール配信中...', key: 'mail' });
      const selMtInfo = mtInfo.find((item) => item._id === selMtId);
      const testMailString = testMailValue
        .replace(/\s+/g, '') //文字列の中の空白を削除
        .replace(/(^[;]+)|([;]+$)/g, ''); //文字列前後の「;」を削除
      let testMail = [];
      let testMailArr = [];
      if (testMailString) {
        testMail = testMailString.split(';');
        testMail.map((element) => {
          testMailArr.push(element);
        });
      }

      let personalizations = [];
      testMailArr.map((record) => {
        personalizations.push({
          to: [
            {
              email: record,
            },
          ],
          substitutions: {
            email: record,
            fullname: '【テスト　太郎】',
            familyname: '【テスト】',
            company: '【テスト株式会社】',
            tname: '【テスト研修】',
            target: '【テスト対象者】',
            init_pass: '【テスト初期パスワード】',
          },
        });
      });
      mailList = {
        personalizations: personalizations,
        from: {
          email: selMtInfo.from_mail, // 送信元アドレス
          name: selMtInfo.from_name, // 送信者名
        },
        subject: selMtInfo.subject,
        html: selMtInfo.body.replace(/\r?\n/g, '<br>'),
        substitutionWrappers: ['{$', '$}'],
        headers: {
          'X-Sent-Using': 'SendGrid-API',
        },
      };
      // console.log({ mailList });
      // const sendMailResult = { data: { success: false } };
      const sendMailResult = await Axios.post('/api/sendMail', mailList);
      if (sendMailResult.data.success) {
        message.success({
          content: 'メール送信完了！',
          key: 'mail',
          duration: 2,
        });
        return sendMailResult;
      } else {
        message.error({
          content: 'メール送信に失敗しました。',
          key: 'mail',
          duration: 2,
        });
        console.log({ sendMailResult });
        throw new Error('メール送信に失敗しました。');
      }
    } catch (error) {
      console.log(error);
      message.error({
        content: 'メール配信で問題が発生しました。',
        key: 'mail',
        duration: 2,
      });
    }
  };

  const onChangeMtInfo = (value) => {
    if (value) {
      setSelMtId(value);
    }
    // console.log(e);
  };

  const showConfirm = async () => {
    confirm({
      title: 'メールを本当に配信しますか？',
      content:
        'メール配信処理が完了し、ホームへ自動遷移されるまで、そのままお待ちください。',
      async onOk() {
        const result = await sendMailHandler();
        if (result) {
          message.success({
            content: 'メールの配信が完了しました。ホームに戻ります。',
            key: 'mail',
            duration: 2,
          });
          props.history.push('/');
        } else {
          message.error({
            content: 'メール送信に失敗しました。',
            key: 'mail',
            duration: 2,
          });
          throw new Error('メール送信に失敗しました。');
        }
      },
      async onCancel() {
        message.success({
          content: 'メール配信を取り消しました。',
          key: 'mail',
          duration: 2,
        });
      },
    });
  };
  const onChangeSurvey = (v) => {
    // setValue({
    //   ...value,
    //   survey_code: v,
    //   survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
    // });
    setSelSurvey(survey.find((item) => item.survey_code === v));
    setSelMtId('');
  };
  const onChangeCCHandler = (e) => {
    setCCMailValue(e.currentTarget.value);
  };

  const onChangeBCCHandler = (e) => {
    setBCCMailValue(e.currentTarget.value);
  };

  const onChangeTestHandler = (e) => {
    setTestMailValue(e.currentTarget.value);
  };
  const csvDownloadHandler = async (event) => {
    event.preventDefault();
    // console.log({ gUsers });
    try {
      message.loading({
        content: 'データ作成中...',
        key: 'makecsv',
        duration: 20,
      });
      const tempData = {};
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      let columns = [
        { header: 'ID', key: 'user_id' },
        { header: '会社名', key: 'company' },
        { header: '部署名', key: 'department' },
        { header: 'eメール', key: 'email' },
        { header: '名前', key: 'fullname' },
        { header: '社員番号', key: 'empNo' },
        { header: 'ロール', key: 'roletext' },
      ];
      worksheet.columns = columns;
      for (let i = 0; i < gUsers.length; i++) {
        const element = gUsers[i];
        tempData.user_id = element._id;
        tempData.company = element.company;
        tempData.department = element.department;
        tempData.email = element.email;
        tempData.fullname = element.fullName;
        tempData.empNo = element.emp_no;
        tempData.roletext = element.roleText;

        worksheet.addRow(tempData);
      }
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (selQuestionSetId) {
        let selQuestionSetInfo = questionSet.find(
          (item) => item._id === selQuestionSetId
        );
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeasonInfo.season_name}_${selQuestionSetInfo.set_name}_${isAnswerd}.xlsx`;
      } else {
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeasonInfo.season_name}_${isAnswerd}.xlsx`;
      }
      a.click();
      // ダウンロード後は不要なのでaタグを除去
      a.remove();
      // console.log({ excelData });
      // console.log(excelData.length);
      message.success({
        content: 'ダウンロード完了！',
        key: 'makecsv',
        duration: 2,
      });
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'ダウンロード失敗！',
        key: 'makecsv',
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          ■絞り込み｜企業選択
          <Select
            showSearch
            style={{
              marginBottom: 10,
              marginLeft: 5,
              marginRight: 5,
              width: 200,
            }}
            placeholder="企業選択"
            optionFilterProp="items"
            onChange={onChangeGroup}
            value={selGroupCode}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {groups.map((element) => {
              // console.log({ element });
              return (
                <Option value={element.group_code} key={element._id}>
                  {element.group_name}
                </Option>
              );
            })}
          </Select>
          {selGroupInfo.survey_season && (
            <Fragment>
              サーベイシーズン選択:
              <Select
                showSearch
                style={{
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  width: 250,
                }}
                placeholder="シーズン選択"
                optionFilterProp="items"
                onChange={onChangeSeasonFilter}
                value={selSurveySeasonIdFilter}
                defaultValue={selGroupInfo.survey_season[0].season_name}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {selGroupInfo.survey_season.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_name}
                    </Option>
                  );
                })}
              </Select>
              {questionSet.length > 0 && (
                <Select
                  showSearch
                  style={{
                    marginBottom: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    width: 200,
                  }}
                  placeholder="設問セット選択"
                  optionFilterProp="items"
                  onChange={onChangeQuestionSet}
                  value={selQuestionSetId}
                  defaultValue={questionSet[0].set_name}
                  filterOption={(input, option) =>
                    option.props.items
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionSet.map((element) => {
                    return (
                      <Option value={element._id} key={element._id}>
                        {element.set_name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Fragment>
          )}
          <Fragment>
            実施選択：
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 100,
              }}
              placeholder="回答選択"
              optionFilterProp="items"
              onChange={onChangeAnswer}
              value={0}
              disabled={true}
            >
              <Option value={9} key={9}>
                全体
              </Option>
              <Option value={0} key={0}>
                未回答
              </Option>
              <Option value={5} key={5}>
                回答済み
              </Option>
            </Select>
          </Fragment>
          {/* <span style={{ marginLeft: 8 }}>
            {hasSelected ? ` ${selectedRowKeys.length}項目選択中` : ''}
          </span> */}
          <Button
            type="primary"
            onClick={csvDownloadHandler}
            disabled={!selSurveySeasonId}
          >
            CSVダウンロード
          </Button>
          <Fragment>　対象者：{gUsers.length}名</Fragment>
        </div>
        <Table
          // rowSelection={rowSelection}
          dataSource={gUsers}
          size="middle"
          rowKey="_id"
        >
          <Column
            title="名前"
            dataIndex="fullName"
            key="fullName"
            aligh="right"
          />
          <Column title="メール" dataIndex="email" key="email" aligh="center" />
          <Column
            title="会社"
            dataIndex="company"
            key="company"
            aligh="center"
          />
          <Column
            title="部署"
            dataIndex="department"
            key="department"
            aligh="center"
          />
        </Table>
        <Text strong>メールテンプレートを選択してください。（必須）</Text>
        <div>
          <Select
            showSearch
            style={{
              width: 400,
            }}
            placeholder="サーベイ選択"
            optionFilterProp="items"
            onChange={onChangeSurvey}
            value={selSurvey.survey_code}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {survey.map((element) => {
              // console.log({ element });
              return (
                <Option value={element.survey_code} key={element._id}>
                  {element.survey_name}
                </Option>
              );
            })}
          </Select>
          <Select
            showSearch
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 600,
            }}
            placeholder="テンプレート選択"
            optionFilterProp="items"
            onChange={onChangeMtInfo}
            value={selMtId}
            filterOption={(input, option) =>
              option.props.items.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mtInfo.map((element) => {
              // console.log({ element });
              if (element.survey_code === selSurvey.survey_code) {
                return (
                  <Option value={element._id} key={element._id}>
                    {element.mt_name}
                  </Option>
                );
              }
            })}
          </Select>
          <br />
          <br />
          <Text strong>テストメールを送信します。</Text>
          <br />
          Test Mail To：
          <Input
            placeholder="メールアドレスを「;」区切りで入力してください"
            allowClear
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 350,
            }}
            onChange={onChangeTestHandler}
            value={testMailValue}
          />
          <Button
            type="primary"
            onClick={sendTestMailHandler}
            disabled={!testMailValue || !selMtId}
          >
            メール発送
          </Button>
          <br />
          <Text strong>本メールを送信します。</Text>
          <br />
          CC：
          <Input
            placeholder="メールアドレスを「;」区切りで入力してください"
            allowClear
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 350,
            }}
            onChange={onChangeCCHandler}
            value={ccMailValue}
          />
          BCC：
          <Input
            placeholder="メールアドレスを「;」区切りで入力してください"
            allowClear
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 10,
              width: 350,
            }}
            onChange={onChangeBCCHandler}
            value={bccMailValue}
          />
          <Button
            type="primary"
            onClick={showConfirm}
            disabled={!selGroupCode || !selSurveySeasonId || !selMtId}
          >
            メール発送
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default RemindMailSendPage;
