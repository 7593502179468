import Axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import config from '../../Config/key';
import { Table, Divider, Tag, Button, message, Modal, Progress } from 'antd';
import { BsBoxArrowUpRight } from 'react-icons/bs';
const { Column, ColumnGroup } = Table;

function ReportListPage(props) {
  const [surveyInfo, setSurveyInfo] = useState([]);
  const projectId = props.match.params.projectId; //外部から持ってくるProjectID

  const readDB = useCallback(async () => {
    const res = await Axios.get(`/api/evaluationPower/${projectId}`);
    //console.log({ res });
    setSurveyInfo(res.data.surveyInfo);
  }, [projectId]);

  useEffect(() => {
    readDB();
  }, [readDB]);

  const viewReportHandler = async (projectId, epId, e) => {
    e.preventDefault();
    try {
      console.log({ projectId, epId });
      const url = `${config.S3_URL}/${projectId}/rcep_${epId}.pdf`;
      window.open(url, '_blank');
    } catch (error) {
      console.log({ error });
    }
  };

  // const resetReportHandler = async (projectId, e) => {
  //   e.preventDefault();
  //   const res = await Axios.patch(
  //     `/api/projectKey/${projectId}/makeReportDate`,
  //     { make_report_date: null }
  //   );
  //   // console.log({ res });
  //   readDB();
  // };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={surveyInfo} size="middle" rowKey="_id">
        <Column
          title="名前"
          dataIndex="user_name"
          key="user_name"
          aligh="right"
        />
        <Column
          title="ユーザID"
          dataIndex="login_id"
          key="login_id"
          aligh="left"
        />
        <Column
          title="所属"
          dataIndex="user_department"
          key="user_department"
          aligh="left"
        />
        <Column
          title="実施日"
          dataIndex="study_end_date"
          key="study_end_date"
          aligh="left"
        />
        <Column
          title="作業"
          key="action"
          render={(text, record) => (
            <span>
              <Button
                type="primary"
                size="small"
                onClick={(e) =>
                  viewReportHandler(record.project_id, record._id, e)
                }
              >
                レポート確認 &nbsp;{' '}
                <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
              </Button>
            </span>
          )}
        />
      </Table>
    </div>
  );
}

export default ReportListPage;
