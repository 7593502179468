import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Table, Button, Select } from 'antd';

const { Column, ColumnGroup } = Table;
const { Text } = Typography;
const { Option } = Select;
const MailTemplatePage = (props) => {
  // console.log({ props });
  const { user } = props;
  const [value, setValue] = useState('');
  const [templateInfo, setTemplateInfo] = useState([]);
  const [templateInfoFilter, setTemplateInfoFilter] = useState([]);
  const [surveyInfo, setSurveyInfo] = useState([]);
  // const [tokenInfo, setTokenInfo] = useState();
  // const [questionInfo, setQuestionInfo] = useState([]);
  //const [myUser, setMyUser] = useState([]);
  // let user = useSelector((state) => state.user);
  // const history = useHistory();
  //if (!user.userData) return null;

  //console.log({ myUser });

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const res = await Axios.get('/api/mailTemplate/');
        const survey = await Axios.get('/api/survey/');

        if (res.data.success) {
          // console.log(res.data.mailTemplate);
          setSurveyInfo(survey.data.survey);
          setTemplateInfo(res.data.mailTemplate);
          setTemplateInfoFilter(res.data.mailTemplate);
        } else {
          throw new Error('テンプレート取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  const editTemplateHandler = async (tInfo, e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/maketemplate',
        state: tInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const newTemplateHandler = async (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/maketemplate',
        state: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSurvey = (v) => {
    setValue({
      ...value,
      survey_code: v,
      survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
    });
    setTemplateInfoFilter(
      templateInfo.filter((item) => item.survey_code === v)
    );
    // console.log(templateInfo.filter((item) => item.survey_code === v));
    // console.log(value);
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Text>■絞り込み｜</Text>
          <>
            <Text>サーベイ選択：</Text>
            <Select
              showSearch
              style={{
                width: 300,
              }}
              placeholder="サーベイ選択"
              optionFilterProp="items"
              onChange={onChangeSurvey}
              value={value['survey_code']}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {surveyInfo.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.survey_code} key={element._id}>
                    {element.survey_name}
                  </Option>
                );
              })}
            </Select>
          </>
        </div>
        <Table dataSource={templateInfoFilter} size="middle" rowKey="_id">
          <Column
            title="テンプレート名"
            dataIndex="mt_name"
            key="mt_name"
            aligh="right"
          />
          <Column
            title="テンプレート説明"
            dataIndex="mt_description"
            key="mt_description"
            aligh="right"
          />
          <Column
            title="件名"
            dataIndex="subject"
            key="subject"
            aligh="center"
          />
          <Column
            title="差出人名"
            dataIndex="from_name"
            key="from_name"
            aligh="center"
          />
          <Column
            title="編集"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Button
                  type={'primary'}
                  size="small"
                  disabled={!record.edit_ok}
                  onClick={(e) => editTemplateHandler(record, e)}
                >
                  編集
                </Button>
              </span>
            )}
          />
          {/* <Column
          title="サーベイ削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => surveyDeleteHandler(record._id, e)}
                disabled={record.survey_status === 5 ? false : true}
              >
                サーベイ削除
              </Button>
            </span>
          )}
        /> */}
        </Table>

        <Button
          type={'primary'}
          size="small"
          onClick={(e) => newTemplateHandler(e)}
        >
          新規テンプレート作成
        </Button>
      </div>
    </Fragment>
  );
};

export default MailTemplatePage;
