import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import config from '../../Config/key';
import { registerUser } from '../../../_actions/user_actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Form, Input, Button, message, Typography } from 'antd';
const { Text, Link } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function CPPage(props) {
  const user = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  const changePasswordHandler = async (values, { setSubmitting }) => {
    // let dataToSubmit = {
    //   password: values.password,
    // };
    // dispatch(registerUser(dataToSubmit)).then((response) => {
    //   if (response.payload.success) {
    //     props.history.push('/login');
    //   } else {
    //     alert(response.payload.err.errmsg);
    //   }
    // });
    // console.log('here1');
    try {
      const data = {
        user: user.userData,
        password: values.password,
        changePassword: true,
      };
      // console.log({ data });
      const res = await Axios.patch(`${config.USER_SERVER}/pc`, data);
      if (res.data.success) {
        // await readDB();
        message.success('パスワードを変更しました。');
        props.history.push('/');
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }

    setSubmitting(false);
  };
  if (!user.userData) return null;
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(12, 'パスワードは半角英数字12文字以上です。')
          .required('パスワードを入力してください。')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\[\]\{\}\=\?\(\)\.\,\;\:\<\>|~_])(?=.{12,})/,
            '英小文字、英大文字、数字、記号をそれぞれ最低1文字以上'
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'パスワードが一致しません。')
          .required('パスワードを再入力してください'),
      })}
      onSubmit={changePasswordHandler}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return (
          <div className="app">
            <h2>パスワード変更</h2>

            <br />
            {!user.userData.changePassword && (
              <Text type="danger">
                ※「初回ログインの際」、「パスワードリセット後」は必ずパスワード変更が必要です。
              </Text>
            )}
            <br />
            <Form
              style={{ minWidth: '700px' }}
              {...formItemLayout}
              onSubmit={handleSubmit}
            >
              <Form.Item
                required
                label="パスワード"
                hasFeedback
                validateStatus={
                  errors.password && touched.password ? 'error' : 'success'
                }
              >
                <div>
                  <br></br>
                  <Text type="secondary">・半角英数字12桁以上</Text>
                  <br></br>
                  <Text type="secondary">
                    ・英小文字、英大文字、数字、記号をそれぞれ最低1文字以上含む
                  </Text>
                  <br></br>
                  <Text type="secondary">
                    ・使用できる記号　→　! @ # $ % ^ & * [ ] {'{ }'} = ? ( ) . ,
                    ; : | ~ _ &lt; &gt;
                  </Text>
                </div>
                <Input
                  id="password"
                  placeholder="パスワード入力"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-feedback">
                    <br></br>
                    {errors.password}
                  </div>
                )}
              </Form.Item>
              <br></br>
              <Form.Item required label="確認パスワード" hasFeedback>
                <Input
                  id="confirmPassword"
                  placeholder="確認パスワードを入力"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="input-feedback">
                    <br></br>
                    {errors.confirmPassword}
                  </div>
                )}
              </Form.Item>
              <br></br>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  disabled={isSubmitting}
                >
                  登録
                </Button>
              </Form.Item>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default CPPage;
