import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import ContentsPage1 from './Sections/ContentsPage1';
import ContentsPage2 from './Sections/ContentsPage2';
import ContentsPage3 from './Sections/ContentsPage3';
const { SubMenu } = Menu;
const { Content, Sider } = Layout;

export const UserContext = React.createContext();

function TakeSurveyPage(props) {
  let user = useSelector((state) => state.user);
  const [answer, setAnswer] = useState({});
  const [tokenInfo, setTokenInfo] = useState();
  const value = { answer, setAnswer };
  const [selectedMenuItem, setSelectedMenuItem] = useState('contentspage1');
  const { tokenId } = props.match.params; //外部から持ってくるProjectID
  // console.log(tokenId);

  // Axios.get(`/api/surveyToken/t/${tokenId}`).then((res) => console.log(res));

  //console.log(res);
  const studyStartDate = Date.now();
  const readDB = async () => {
    try {
      // console.log('here');
      const res = await Axios.get(`/api/surveyToken/t/${tokenId}`);
      setTokenInfo(res.data.tokenInfo);
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    //console.log({ mySurvey });
  }, []);

  const componentsSwtich = (key) => {
    switch (key) {
      case 'contentspage1':
        return <ContentsPage1 />;
      case 'contentspage2':
        return (
          <UserContext.Provider value={value}>
            <ContentsPage2
              surveyCode={tokenInfo.survey_code}
              section="10"
              title="1問~30問"
              choice="「その通りである」「その通りではない」の２択"
            />
          </UserContext.Provider>
        );
      case 'contentspage3':
        return (
          <UserContext.Provider value={value}>
            <ContentsPage2
              surveyCode={tokenInfo.survey_code}
              section="20"
              title="31問~60問"
              choice="「その通りである」「その通りではない」「どちらともいえない」の３択"
            />
          </UserContext.Provider>
        );
      case 'contentspage4':
        return (
          <UserContext.Provider value={value}>
            <ContentsPage2
              surveyCode={tokenInfo.survey_code}
              section="30"
              title="61問~70問"
              choice="「その通りである」「その通りではない」の２択"
            />
          </UserContext.Provider>
        );
      case 'contentspage5':
        return (
          <UserContext.Provider value={value}>
            <ContentsPage2
              surveyCode={tokenInfo.survey_code}
              section="40"
              title="71問~80問"
              choice="「その通りである」「その通りではない」の２択"
            />
          </UserContext.Provider>
        );
      case 'contentspage6':
        return (
          <UserContext.Provider value={value}>
            <ContentsPage3
              props={props}
              tokenInfo={tokenInfo}
              studyStartDate={studyStartDate}
            />
          </UserContext.Provider>
        );
      default:
        break;
    }
  };
  const onClickHandler = (e) => {
    setSelectedMenuItem(e.key);
  };

  return (
    <Layout>
      <Layout>
        <Sider width={200} style={{ background: '#fff' }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['contentspage1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
            onClick={onClickHandler}
          >
            <SubMenu
              key="sub1"
              title={
                <span>
                  <UserOutlined />
                  診断:STEP1
                </span>
              }
            >
              <Menu.Item key="contentspage1">事前案内</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              title={
                <span>
                  <LaptopOutlined />
                  診断:STEP2
                </span>
              }
            >
              <Menu.Item key="contentspage2">設問01~30</Menu.Item>
              <Menu.Item key="contentspage3">設問31~60</Menu.Item>
              <Menu.Item key="contentspage4">設問61~70</Menu.Item>
              <Menu.Item key="contentspage5">設問71~80</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              title={
                <span>
                  <NotificationOutlined />
                  診断:STEP3
                </span>
              }
            >
              <Menu.Item key="contentspage6">結果提出</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ padding: '24px 24px 24px' }}>
          <Content
            style={{
              background: '#fff',
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {componentsSwtich(selectedMenuItem)}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default TakeSurveyPage;
