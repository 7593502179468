import Axios from 'axios';
import React, { useEffect, Fragment, useState } from 'react';
import { Table, Button, Modal } from 'antd';
import Vimeo from '@u-wave/react-vimeo';
// import { surveyCode } from '../../Config/surveyCode';

const { Column } = Table;

const VideoMgrPage = (props) => {
  // console.log({ props });
  const { user } = props;
  const [videoInfo, setVideoInfo] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [paused, setPaused] = useState(false);

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const res = await Axios.get('/api/video/');
        // console.log(res.data.groups);
        if (res.data.success) {
          let vArr = res.data.video;
          vArr.map((element) => {
            element.iName = element.instructor.name;
            element.cName = element.category_code_id.category_name;
            element.isThumbnail = element.thumbnail[0] ? '⭕️' : '❌';
            return element;
          });
          // console.log(vArr);
          setVideoInfo(vArr);
        } else {
          throw new Error('動画データ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  const editVideoHandler = async (vInfo, e) => {
    e.preventDefault();
    try {
      vInfo.fg = 'edit';
      props.history.push({
        pathname: '/videoedit',
        state: vInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const newVideoHandler = async (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: '/videoupload',
        state: { fg: 'new' },
      });
    } catch (error) {
      console.log(error);
    }
  };
  //ModalでのOK
  const handleOk = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  const handleCancel = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  //ModalでのOK
  const handleCardClick = (v) => {
    // console.log({ v });
    setVideoId(v.url.split('/')[2]);
    setPaused(false);
    setViewModal(true);
    // }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <Table dataSource={videoInfo} size="middle" rowKey="_id">
          <Column title="動画名" dataIndex="title" key="title" aligh="left" />
          <Column
            title="カテゴリー"
            dataIndex="cName"
            key="cName"
            aligh="left"
          />
          <Column title="講師" dataIndex="iName" key="iName" aligh="left" />
          <Column
            title="動画説明：管理向け"
            dataIndex="video_description"
            key="video_description"
            aligh="left"
          />
          <Column
            title="サムネール"
            dataIndex="isThumbnail"
            key="thumbnail"
            aligh="center"
          />
          <Column
            title="編集"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Button
                  type={'primary'}
                  size="small"
                  onClick={(e) => editVideoHandler(record, e)}
                  disabled={false}
                >
                  編集
                </Button>
              </span>
            )}
          />
          <Column
            title="動画"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Button
                  type={'primary'}
                  size="small"
                  onClick={(e) => handleCardClick(record, e)}
                  disabled={false}
                >
                  動画確認
                </Button>
              </span>
            )}
          />
          {/* <Column
          title="サーベイ削除"
          key="delete"
          render={(text, record) => (
            <span>
              // {<a>権限付与 {record._id}</a>} 
              <Button
                type="danger"
                size="small"
                onClick={(e) => surveyDeleteHandler(record._id, e)}
                disabled={record.survey_status === 5 ? false : true}
              >
                サーベイ削除
              </Button>
            </span>
          )}
        /> */}
        </Table>

        <Button
          type={'primary'}
          size="small"
          onClick={(e) => newVideoHandler(e)}
        >
          新規動画アップロード
        </Button>
      </div>
      <div>
        <Modal
          title="動画再生"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={1000}
          footer={null}
        >
          <Vimeo
            video={videoId}
            className="vimeo pc"
            responsive
            autoplay={true}
            speed={true}
            paused={paused}
          />
        </Modal>
      </div>
    </Fragment>
  );
};

export default VideoMgrPage;
