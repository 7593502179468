//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function CSVTokenInsertPage(props) {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selSurveySeasonId, setSelSurveySeasonId] = useState([]); //選択したシーズンID
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択したシーズン情報
  const [surveySeason, setSurveySeason] = useState([]); //グループにあるサーベイシーズン
  const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  const [selQuestionSetId, setSelQuestionSetId] = useState(null); //全てのサーベイ情報

  // let department = [];

  // const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onDrop = async (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          //const courseInfo = courseMerge(results.data);
          // setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          // console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
    const group = await Axios.get('/api/group/');
    setGroups(group.data.groups);
    setSubmitButtonDisable(true);
    setGroupCode('');
    setSelSurveySeasonId('');

    // console.log(group.data.groups);
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // let userData = []; //最終分類用
    try {
      message.loading({
        content: 'CSVアップロード中...',
        key: 'csvupload',
        duration: 20,
      });
      const res = await Axios.get('/api/users/group/' + groupCode);
      const groupUser = res.data.users;
      let sendData = [];
      let tempData = {};
      let sendToken = [];
      let tempToken = {};
      let boss = [];
      let partner = [];
      // for (let index = 0; index < fileJSON.length; index++) {
      //   const record = fileJSON[index];
      //   switch (record.relation) {
      //     case 1:
      //       if (tempData) {
      //         tempData.boss = boss;
      //         tempData.partner = partner;
      //         sendData.push(tempData);
      //         tempData = {};
      //         boss = [];
      //         partner = [];
      //       }
      //       tempData = {
      //         survey_season_id: selSurveySeasonId,
      //         user_id: groupUser.find((item) => item.email === record.email)
      //           ._id,
      //       };
      //       break;

      //     case 2:
      //       boss.push(
      //         groupUser.find((item) => item.email === record.email)._id
      //       );
      //       break;

      //     case 3:
      //       partner.push(
      //         groupUser.find((item) => item.email === record.email)._id
      //       );
      //       break;

      //     default:
      //       break;
      //   }
      //   console.log({ record });
      // }

      //本人が本人の協力者に登録しないようにする。
      // let hero = '';
      // fileJSON.forEach((element) => {
      //   if (element.relation === 1) {
      //     hero = element.email;
      //   }
      //   if (
      //     (element.relation === 2 || element.relation === 3) &&
      //     element.email === hero
      //   ) {
      //     throw new Error('本人が協力者の中に入っています。');
      //   }
      // });

      //Upload Fileの本人email
      // let heroEmail = fileJSON
      //   .filter((item) => item.relation === 1)
      //   .map((item) => item.email);

      // let s = new Set(heroEmail);
      // // console.log({ s });
      // // console.log({ heroEmail });
      // if (s.size != heroEmail.length) {
      //   throw new Error('本人が重複しています。');
      // }

      //Upload Fileの「本人」のID
      // let heroId = [];
      // heroEmail.forEach((element) => {
      //   heroId.push(groupUser.find((item) => item.email === element)._id);
      // });
      //選択したシーズンに設定されている「本人」のID
      // let inHeroId = [];
      // selSurveySeasonInfo.relation_360.forEach((element) => {
      //   inHeroId.push(element.user_id);
      // });

      // console.log({ heroId });
      // console.log({ inHeroId });
      //Upload Dataの本人が同じシーズンに既に存在する場合はエラーを出す
      //新しい本人+協力者（既存含め）は追加登録可能
      // heroId.forEach((element) => {
      //   if (inHeroId.includes(element)) {
      //     throw new Error('登録対象の本人が既に存在します。');
      //   }
      // });
      // let hInfo = '';
      await fileJSON.map((record) => {
        const sendDate = new Date();
        let expire = new Date();
        expire = expire.setDate(expire.getDate() + 60);
        tempToken = {
          user: groupUser.find((item) => item.email === record.email)._id,
          survey_id: selSurveySeasonInfo.survey_id,
          survey_code: selSurveySeasonInfo.survey_code,
          question_set_id: selQuestionSetId,
          token_make_date: sendDate,
          token_expire: expire,
          token_valid: sendDate,
          survey_status: 0,
          group_code: groupCode,
          survey_season_id: selSurveySeasonId,
        };

        //本人用設問セットのIDを取得
        //シーズン情報の中に存在する一番目の本人用設問セット
        //１シーズンでは１typeの問題セットのみがアサインされる前提(6x6,6x5,5x6,cup)
        // const hero_question = selSurveySeasonInfo.question_set.find(
        //   (item) => item.sub_type === '4'
        // )._id;
        //協力者用設問セットのIDを取得
        //シーズン情報の中に存在する一番目の協力者用設問セット
        //１シーズンでは１typeの問題セットのみがアサインされる前提(6x6,6x5,5x6,cup)
        // const around_question = selSurveySeasonInfo.question_set.find(
        //   (item) => item.sub_type === '5'
        // )._id;

        //   switch (record.relation) {
        //     case 1: //本人の場合
        //       if (tempData.survey_season_id) {
        //         //次の本人になった場合
        //         tempData.boss = boss;
        //         tempData.partner = partner;
        //         sendData.push(tempData);
        //         tempData = {};
        //         boss = [];
        //         partner = [];
        //       }
        //       //本人データの格納
        //       hInfo = groupUser.find((item) => item.email === record.email);
        //       // console.log({ hInfo });
        //       tempData = {
        //         survey_season_id: selSurveySeasonId,
        //         user_id: hInfo._id,
        //         group_code: groupCode,
        //       };
        //       tempToken.question_set_id = hero_question;
        //       tempToken.hero_id = hInfo._id;
        //       tempToken.hero_name = hInfo.lastname + ' ' + hInfo.name;
        //       tempToken.relation = 1;
        //       break;

        //     case 2: //上司の場合
        //       boss.push(
        //         groupUser.find((item) => item.email === record.email)._id
        //       );
        //       tempToken.question_set_id = around_question;
        //       tempToken.hero_id = hInfo._id;
        //       tempToken.hero_name = hInfo.lastname + ' ' + hInfo.name;
        //       tempToken.relation = 2;
        //       break;

        //     case 3: //部下・同僚の場合
        //       partner.push(
        //         groupUser.find((item) => item.email === record.email)._id
        //       );
        //       tempToken.question_set_id = around_question;
        //       tempToken.hero_id = hInfo._id;
        //       tempToken.hero_name = hInfo.lastname + ' ' + hInfo.name;
        //       tempToken.relation = 3;
        //       break;

        //     default:
        //       break;
        //   }
        sendToken.push(tempToken);
      });
      // tempData.boss = boss;
      // tempData.partner = partner;
      // sendData.push(tempData);

      // console.log({ sendData });
      // console.log({ sendToken });
      // console.log({ selSurveySeasonInfo });
      // console.log({ userData });
      // console.log({ department });

      // const res3 = await Axios.post('/api/relation360', sendData);

      const res3 = await Axios.post('/api/surveyToken/ticket', sendToken);
      // Axios.post('/api/relation360', sendData),

      if (res3.data.success) {
        // if (0) {
        setFileData('CSVアップロードが完了しました。');
        message.success({
          content: 'アップロード完了！',
          key: 'csvupload',
          duration: 3,
        });
        setTimeout(() => {
          props.history.push('/');
        }, 1000);
      } else {
        message.error({
          content: 'CSVアップロードに失敗しました。',
          key: 'csvupload',
          duration: 3,
        });
        throw new Error('CSVアップロードに失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      /*
      ＜課題＞
      projectKeyがUniqueでない場合の処理を追加
      MongoDB error code 11000 
      */
      message.error({
        content: error.message,
        key: 'csvupload',
        duration: 3,
      });
    }
  };

  const onChangeGroup = (value) => {
    if (value) {
      setGroupCode(value);
      setSubmitButtonDisable(true);
      const ssi = groups.find(
        (item) => item.group_code === value
      ).survey_season;
      setSurveySeason(ssi);
      setSelSurveySeasonId('');
      setSelQuestionSetId(null);
      setQuestionSet([]);
      // console.log({ ssi });
    }
    // console.log(e);
  };

  const onChangeSurveySeason = (value) => {
    if (value) {
      const sssi = surveySeason.find((item) => item._id === value);
      setSelSurveySeasonId(value);
      setSelSurveySeasonInfo(sssi);
      setSubmitButtonDisable(false);
      setQuestionSet(sssi.question_set);
      if (sssi.question_set.length > 0) {
        setSelQuestionSetId(sssi.question_set[0]._id);
      } else {
        setSelQuestionSetId(null);
      }
      // console.log({ value });
    }
  };

  const onChangeQuestionSet = async (value) => {
    // console.log({ value });
    setSelQuestionSetId(value);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>サーベイチケット CSV 発行</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>企業を選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeGroup}
                value={groupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groups.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.group_code} key={element._id}>
                      {element.group_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <br />
          </div>
          <br />
          <br />
          <div>
            <Title>❸</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>サーベイシーズンを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="サーベイシーズン選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {surveySeason.map((element) => {
                  if (element.survey_code != '40010') {
                    return (
                      <Option value={element._id} key={element._id}>
                        {element.season_description}
                      </Option>
                    );
                  } else {
                    return;
                  }
                  // console.log({ element });
                })}
              </Select>
              {questionSet.length > 0 && (
                <span>
                  設問セット
                  <Select
                    showSearch
                    style={{ marginLeft: 5, marginRight: 5, width: 300 }}
                    placeholder="設問セット選択"
                    optionFilterProp="items"
                    onChange={onChangeQuestionSet}
                    value={selQuestionSetId}
                    defaultValue={questionSet[0].set_name}
                    filterOption={(input, option) =>
                      option.props.items
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {questionSet.map((element) => {
                      return (
                        <Option value={element._id} key={element._id}>
                          {element.set_name}
                        </Option>
                      );
                    })}
                  </Select>
                </span>
              )}
            </div>
            <br />
          </div>
          <br />
          <br />
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={submitButtonDisable}
          >
            アップロード
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
}

export default CSVTokenInsertPage;
