import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import config from '../../Config/key';
import {
  Table,
  Divider,
  Tag,
  Button,
  message,
  Typography,
  Select,
  Modal,
  Space,
  Spin,
  TreeSelect,
} from 'antd';
import { DeleteOutlined, CarryOutOutlined } from '@ant-design/icons';
import { userRole } from '../../Config/userRole';
import { useSelector } from 'react-redux';
const { Column, ColumnGroup } = Table;
const ExcelJS = require('exceljs');
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
// const survey_status_complete = 5;

function E3DepMgrPage() {
  const [viewModal, setViewModal] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [uId, setUId] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [selTree, setSelTree] = useState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
  });
  // const [isAnswerd, setIsAnswerd] = useState(9);
  // const [usedToken, setUsedToken] = useState([]);
  // const [Users, setUsers] = useState([]); //全てのユーザ一覧
  // const [tUsers, setTUsers] = useState([]); //グループフィルタリングされたユーザ一覧（全体）
  const [gUsers, setGUsers] = useState([]); //グループ・サーベイシーズンでフィルタリングされたユーザ一覧
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  // const [selGroupInfo, setSelGroupInfo] = useState([]); //グループ名とグループコード
  // const [surveySeason, setSurveySeason] = useState([]);
  // const [selSurveySeason, setSelSurveySeason] = useState([]);
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグルプコード
  // const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  // const [questionSetFilter, setQuestionSetFilter] = useState([]); //全ての設問セット
  // const [selQuestionSetFilter, setSelQuestionSetFilter] = useState([]); //全ての設問セット
  // const [questionSet, setQuestionSet] = useState([]); //全ての設問セット
  // const [selSurveyId, setSelSurveyId] = useState(''); //選択したサーベイID
  // const [role360, setRole360] = useState(1); //選択した360度関係：１：本人、２：上司、３：部下・同僚
  // const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択したサーベイID
  // const [selSurveySeasonIdFilter, setSelSurveySeasonIdFilter] = useState(''); //選択したサーベイID
  // const [selQuestionSetFilterId, setSelQuestionSetFilterId] = useState(''); //選択した設問セットID
  // const [rowKeys, setRowKeys] = useState({
  //   //選択されたユーザ一覧
  //   selectedRowKeys: [], // Check here to configure the default column
  //   loading: false,
  // });
  const usr = useSelector((state) => state.user);
  //9:管理者９が最高管理者
  // const userRole = [
  //   '停止中',
  //   '学習者',
  //   '管理者',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   '最高管理者',
  // ];

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log(usr.userData);
      setUserInfo(usr.userData);

      const groupInfo = await Axios.get('/api/group/'); //DBからグループ情報取得
      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }
      setGUsers([]);
      if (!groups) throw 'グループ取得に失敗しました。';
      if (usr.userData.role === 3) {
        const g = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );
        // console.log({ g });
        setSelGroupCode(g.group_code);
        filterDatasource(g.group_code);
        setTreeData(genTree(g.department));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
  }, [usr]);

  //リストフィルタリング
  const filterDatasource = async (groupCode) => {
    message.loading({
      content: '読み込み中...',
      key: 'updateable',
      duration: 20,
    });
    const res = await Axios.get(
      `${config.USER_SERVER}/role46group/${groupCode}`
    );
    // console.log({ res });
    let userData = res.data.users;
    await userData.map((user) => {
      user.fullName = user.lastname + ' ' + user.name;
      user.roleText = userRole[user.role];
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.length;
        user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
          return tokenInfo.survey_status === 5; //実施済み：5
        }).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    setGUsers(userData);
    // setTUsers(userData);
    message.success({
      content: '読み込み完了！',
      key: 'updateable',
      duration: 2,
    });
  };

  const genTree = (depArr) => {
    const dpObj = {
      dp1: [],
      dp2: [],
      dp3: [],
      dp4: [],
      dp5: [],
    };
    let treeData = [];
    for (let i = 0; i < depArr.length; i++) {
      let dArr = depArr[i].split('／');
      if (dArr.length === 1) {
        dpObj.dp1.push(depArr[i]);
      }
      if (dArr.length === 2) {
        dpObj.dp2.push(depArr[i]);
      }
      if (dArr.length === 3) {
        dpObj.dp3.push(depArr[i]);
      }
      if (dArr.length === 4) {
        dpObj.dp4.push(depArr[i]);
      }
      if (dArr.length === 5) {
        dpObj.dp5.push(depArr[i]);
      }
    }
    for (let i = 0; i < dpObj.dp1.length; i++) {
      treeData.push({
        value: dpObj.dp1[i],
        title: dpObj.dp1[i],
        icon: <CarryOutOutlined />,
        children: [],
      });
    }
    for (let i = 0; i < dpObj.dp2.length; i++) {
      const dArr = dpObj.dp2[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.push({
            value: dpObj.dp2[i],
            title: dArr[1],
            icon: <CarryOutOutlined />,
            children: [],
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp3.length; i++) {
      const dArr = dpObj.dp3[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp3[i].includes(oo.value)) {
              oo.children.push({
                value: dpObj.dp3[i],
                title: dArr[2],
                icon: <CarryOutOutlined />,
                children: [],
              });
            }
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp4.length; i++) {
      const dArr = dpObj.dp4[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp4[i].includes(oo.value)) {
              oo.children.map((ooo) => {
                if (dpObj.dp4[i].includes(ooo.value)) {
                  ooo.children.push({
                    value: dpObj.dp4[i],
                    title: dArr[3],
                    icon: <CarryOutOutlined />,
                    children: [],
                  });
                }
              });
            }
          });
        }
      });
    }
    for (let i = 0; i < dpObj.dp5.length; i++) {
      const dArr = dpObj.dp5[i].split('／');
      treeData.map((o) => {
        if (o.value === dArr[0]) {
          o.children.map((oo) => {
            if (dpObj.dp5[i].includes(oo.value)) {
              oo.children.map((ooo) => {
                if (dpObj.dp5[i].includes(ooo.value)) {
                  ooo.children.map((oooo) => {
                    if (dpObj.dp5[i].includes(oooo.value)) {
                      oooo.children.push({
                        value: dpObj.dp5[i],
                        title: dArr[4],
                        icon: <CarryOutOutlined />,
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
    // console.log(dpObj);
    // console.log(treeData);
    return treeData;
  };
  //グループを変更した場合
  const onChangeGroup = async (value) => {
    // console.log(`selected ${value}`);
    setSelGroupCode(value);
    filterDatasource(value); //リストフィルタリング
    const selGroup = groups.find((item) => item.group_code === value);
    setTreeData(genTree(selGroup.department));
    // console.log(treeData);
  };

  //「チケット発行確認」ボタンを押した時
  const viewTicketHandler = async (user, e) => {
    e.preventDefault();
    // console.log({ user });
    // console.log({ selTree });

    let a = {};
    for (let i = 0; i < 10; i++) {
      if (user.e3_department[i]) {
        a[i] = user.e3_department[i];
      } else {
        a[i] = '';
      }
    }
    // for (let i = 0; i < user.e3_department.length; i++) {
    //   const element = user.e3_department[i];
    //   a[i] = element;
    // }
    // console.log({ a });
    setSelTree(a);
    setUId(user._id);
    try {
      setViewModal(true);
    } catch (error) {
      alert('サーベイ情報が読み込めませんでした。');
      console.log(error);
    }
  };

  //ModalでのOK
  const handleOk = async (e) => {
    e.preventDefault();
    try {
      // console.log(e);
      setViewModal(false);
      // console.log(selTree);
      const v = Object.values(selTree);
      // console.log(v);
      const res = await Axios.patch(
        `${config.USER_SERVER}/${uId}/e3department`,
        {
          e3_department: v,
        }
      );
      // console.log(res);
      if (res.data.success) {
        // await readDB();
        await filterDatasource(selGroupCode);
        message.success('保存しました。');
      }
    } catch (error) {
      alert('部署情報保存に失敗しました。');
      console.log(error);
    }
  };

  const handleCancel = (e) => {
    // console.log(e);
    setViewModal(false);
  };

  const handleChangeTree0 = (value, label, extra) => {
    setSelTree({ ...selTree, 0: value });
  };
  const handleChangeTree1 = (value, label, extra) => {
    setSelTree({ ...selTree, 1: value });
  };
  const handleChangeTree2 = (value, label, extra) => {
    setSelTree({ ...selTree, 2: value });
  };
  const handleChangeTree3 = (value, label, extra) => {
    setSelTree({ ...selTree, 3: value });
  };
  const handleChangeTree4 = (value, label, extra) => {
    setSelTree({ ...selTree, 4: value });
  };
  const handleChangeTree5 = (value, label, extra) => {
    setSelTree({ ...selTree, 5: value });
  };
  const handleChangeTree6 = (value, label, extra) => {
    setSelTree({ ...selTree, 6: value });
  };
  const handleChangeTree7 = (value, label, extra) => {
    setSelTree({ ...selTree, 7: value });
  };
  const handleChangeTree8 = (value, label, extra) => {
    setSelTree({ ...selTree, 8: value });
  };
  const handleChangeTree9 = (value, label, extra) => {
    setSelTree({ ...selTree, 9: value });
  };
  const treeDeleteHandler = (key) => {
    switch (key) {
      case 0:
        setSelTree({ ...selTree, 0: '' });
        break;
      case 1:
        setSelTree({ ...selTree, 1: '' });
        break;
      case 2:
        setSelTree({ ...selTree, 2: '' });
        break;
      case 3:
        setSelTree({ ...selTree, 3: '' });
        break;
      case 4:
        setSelTree({ ...selTree, 4: '' });
        break;
      case 5:
        setSelTree({ ...selTree, 5: '' });
        break;
      case 6:
        setSelTree({ ...selTree, 6: '' });
        break;
      case 7:
        setSelTree({ ...selTree, 7: '' });
        break;
      case 8:
        setSelTree({ ...selTree, 8: '' });
        break;
      case 9:
        setSelTree({ ...selTree, 9: '' });
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        {(userInfo.role === 9 || userInfo.role === 2) && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            ■絞り込み｜企業選択
            <Select
              showSearch
              style={{
                marginBottom: 10,
                marginLeft: 5,
                marginRight: 5,
                width: 200,
              }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
            <br />
          </div>
        )}
        <Table dataSource={gUsers} size="middle" rowKey="_id">
          <Column
            title="名前"
            dataIndex="fullName"
            key="fullName"
            aligh="right"
          />
          <Column title="メール" dataIndex="email" key="email" aligh="center" />
          <Column
            title="部署"
            dataIndex="department"
            key="department"
            aligh="center"
          />
          <Column
            title="役職"
            dataIndex="position"
            key="position"
            aligh="center"
          />
          <Column
            title="部署指定"
            key="ticket"
            render={(text, record) => (
              <span>
                <Button
                  type={'primary'}
                  size="small"
                  onClick={(e) => viewTicketHandler(record, e)}
                  disabled={false}
                >
                  部署指定
                </Button>
              </span>
            )}
          />
        </Table>
      </div>
      <div>
        <Modal
          title="部署指定"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={900}
          okText={'保存'}
          cancelText={'キャンセル'}
        >
          <Text>※最大10部署まで指定可能です。</Text>
          <br />
          <Text>※指定された部署の配下まで結果閲覧が可能になります。</Text>
          <br />
          <Text type="danger">
            ※部署に指定がない場合、全ての部署の結果が閲覧できます。
          </Text>
          <div style={{ width: '100%', margin: '1rem auto' }}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree0}
                  value={selTree[0]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={
                    selTree[0] === '' || selTree[0] === null ? true : false
                  }
                  onClick={(e) => treeDeleteHandler(0)}
                >
                  削除
                </Button>
                <Text>{selTree[0]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree1}
                  value={selTree[1]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[1] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(1)}
                >
                  削除
                </Button>
                <Text>{selTree[1]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree2}
                  value={selTree[2]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[2] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(2)}
                >
                  削除
                </Button>
                <Text>{selTree[2]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree3}
                  value={selTree[3]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[3] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(3)}
                >
                  削除
                </Button>
                <Text>{selTree[3]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree4}
                  value={selTree[4]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[4] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(4)}
                >
                  削除
                </Button>
                <Text>{selTree[4]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree5}
                  value={selTree[5]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={
                    selTree[5] === '' || selTree[5] === null ? true : false
                  }
                  onClick={(e) => treeDeleteHandler(5)}
                >
                  削除
                </Button>
                <Text>{selTree[5]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree6}
                  value={selTree[6]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[6] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(6)}
                >
                  削除
                </Button>
                <Text>{selTree[6]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree7}
                  value={selTree[7]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[7] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(7)}
                >
                  削除
                </Button>
                <Text>{selTree[7]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree8}
                  value={selTree[8]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[8] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(8)}
                >
                  削除
                </Button>
                <Text>{selTree[8]}</Text>
              </Space>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
              >
                <TreeSelect
                  treeLine={true}
                  style={{
                    width: 200,
                  }}
                  treeData={treeData}
                  onSelect={handleChangeTree9}
                  value={selTree[9]}
                />
                <Button
                  type={'primary'}
                  size="small"
                  disabled={selTree[9] === '' ? true : false}
                  onClick={(e) => treeDeleteHandler(9)}
                >
                  削除
                </Button>
                <Text>{selTree[9]}</Text>
              </Space>
            </Space>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
}

export default E3DepMgrPage;
