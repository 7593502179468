/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu, message } from 'antd';
// import Axios from 'axios';
// import config from '../../../Config/key';
import { logoutUser } from '../../../../_actions/user_actions';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function RightMenu(props) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    // axios.get(`${config.USER_SERVER}/logout`).then((response) => {
    //   if (response.status === 200) {
    //     props.history.push('/login');
    //   } else {
    //     alert('Log Out Failed');
    //   }
    // });
    dispatch(logoutUser()).then((response) => {
      //Not Loggined in Status
      // if (!response.payload.isAuth) {
      //   if (option) {
      //     props.history.push('/login');
      //   }
      //   //Loggined in Status
      // } else {
      //   //supposed to be Admin page, but not admin person wants to go inside
      //   if (adminRoute && !response.payload.isAdmin) {
      //     props.history.push('/');
      //   }
      //   //Logged in Status, but Try to go into log in page
      //   else {
      //     if (option === false) {
      //       props.history.push('/');
      //     }
      //   }
      // }
      // console.log({ response });
      props.history.push('/login');
    });
  };

  // const changePasswordHandler = async () => {
  //   // e.preventDefault();
  //   try {
  //     const data = {
  //       user: user.userData,
  //       password: '123456',
  //     };
  //     // console.log({ data });
  //     const res = await Axios.patch(`${config.USER_SERVER}/pc`, data);
  //     if (res.data.success) {
  //       // await readDB();
  //       message.success('パスワードを変更しました。');
  //       props.history.push('/');
  //     }
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  if (!user.userData) return null;
  if (user.userData && !user.userData.isAuth) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key="signin">
          <a href="/login">ログイン</a>
        </Menu.Item>
        {/* <Menu.Item key="signup">
          <a href="/register">ユーザ登録</a>
        </Menu.Item> */}
      </Menu>
    );
  } else {
    //console.log(user.userData.name);
    return (
      <Menu mode={props.mode}>
        <Menu.Item key="パスワード変更">
          <a href="/cp">パスワード変更</a>
        </Menu.Item>
        <Menu.Item key="ログアウト">
          <a onClick={logoutHandler}>
            {/* Logout({user.userData.lastname + user.userData.name}) */}
            ログアウト
          </a>
        </Menu.Item>
      </Menu>
    );
  }
}

export default withRouter(RightMenu);
