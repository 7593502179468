import Axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import config from '../../Config/key';
import { Table, Divider, Tag, Button, message, Modal, Progress } from 'antd';
const { Column, ColumnGroup } = Table;

function MakeReportPage() {
  const [projectKey, setProjectKey] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [pdfProgress, setPdfProgress] = useState(0);

  const readDB = async () => {
    const res = await Axios.get('/api/projectKey');
    let projectData = res.data;
    // console.log({ res });
    setProjectKey(projectData);
  };

  useEffect(() => {
    readDB();
    //console.log({ projectKey });
  }, []);

  const makeReportHandler = async (projectId, e) => {
    //作成日付をupdate
    //make_report_date
    e.preventDefault();
    setModalVisible(true);

    const res = await Axios.patch(
      `/api/projectKey/${projectId}/makeReportDate`,
      { make_report_date: Date.now() }
    );
    // console.log({ res });
    readDB();
    // console.log({ res });
    const { user_cnt, _id } = res.data.project;
    let eventSource = new EventSource(
      `${config.BACKEND_SERVER}/api/evaluationPower/${user_cnt}/${_id}`
    );
    // eventSource.onmessage = (e) => updateStatus(JSON.parse(e.data));
    eventSource.onmessage = (e) => {
      let status = JSON.parse(e.data);
      console.log({ status });
      let progress = parseInt((status.finish / status.userCnt) * 100);
      setPdfProgress(progress);
      if (status.finish >= status.userCnt) {
        eventSource.close();
        setButtonDisabled(false);
      }
    };
    eventSource.onerror = () => {
      eventSource.close();
    };
  };

  const resetReportHandler = async (projectId, e) => {
    e.preventDefault();
    try {
      const [res1, res2] = await Promise.all([
        Axios.delete(`/api/projectKey/${projectId}`),
        Axios.patch(`/api/projectKey/${projectId}/makeReportDate`, {
          make_report_date: null,
        }),
      ]);
      console.log({ res1, res2 });
      readDB();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleModalOk = async (e) => {
    //setLoading(true);
    setModalVisible(false);
    setButtonDisabled(true);
    setPdfProgress(0);
  };

  // return <div>User Management Page2</div>;
  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Table dataSource={projectKey} size="middle" rowKey="project_key">
        <Column
          title="プロジェクトキー"
          dataIndex="project_key"
          key="project_key"
          aligh="right"
          render={(text, record) =>
            record.make_report_date ? (
              <a href={'/reportlist/' + record._id}>{text}</a>
            ) : (
              <div>{text}</div>
            )
          }
        />
        <Column
          title="プロジェクト説明"
          dataIndex="project_description"
          key="project_description"
          aligh="left"
        />
        <Column
          title="作業"
          key="action"
          render={(text, record) => (
            <span>
              {/* {<a>権限付与 {record._id}</a>} */}
              <Button
                type="primary"
                size="small"
                onClick={(e) => makeReportHandler(record._id, e)}
                disabled={record.make_report_date}
              >
                レポート作成
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                danger
                size="small"
                onClick={(e) => resetReportHandler(record._id, e)}
                disabled={!record.make_report_date}
              >
                レポート削除
              </Button>
            </span>
          )}
        />
      </Table>
      <Modal
        title="レポート生成"
        open={modalVisible}
        onOk={handleModalOk}
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={buttonDisabled}
            onClick={handleModalOk}
          >
            OK
          </Button>,
        ]}
      >
        <div
          style={{
            height: '12em',
            position: 'relative',
          }}
        >
          <div
            style={{
              margin: '0',
              height: '10em',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {pdfProgress < 100 ? 'PDFファイル生成中...' : 'PDFファイル生成完了'}
            <br />
            <br />
            <Progress type="circle" percent={pdfProgress} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MakeReportPage;
