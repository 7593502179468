import React, { useEffect, useState } from 'react';
import { FiDatabase } from 'react-icons/fi';
import { Card, Badge } from 'antd';
import Axios from 'axios';
import config from '../../Config/key';
import { useSelector } from 'react-redux';

const LandingPage = () => {
  const [userInfo, setUserInfo] = useState({});
  const [tokenCnt, setTokenCnt] = useState(0);
  const [isAuth, setIsAuth] = useState(false);

  let user = useSelector((state) => state.user);

  // if (!user.userData) return null;
  // console.log(user);
  const readDB = async () => {
    try {
      if (!user.userData) return null;
      setIsAuth(user.userData.isAuth);
      // console.log(user);
      if (user.userData.isAuth) {
        const res = await Axios.get(
          config.USER_SERVER + '/' + user.userData._id
        );
        // const surveyToken = res.data.user.survey_token;
        setUserInfo(res.data.user);
        var now = ymdhms();
        // console.log(now);
        // console.log(ymdhms(res.data.user.survey_token[0].token_expire));
        setTokenCnt(
          res.data.user.survey_token.filter(
            (item) =>
              item.survey_status === 0 && ymdhms(item.token_expire) > now
          ).length
        );
      }
      // console.log({ tokenCnt });
      // let userData = res.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //if (!usr) return null;
    // console.log({ user });
    readDB();
  }, [user]);

  //yyyymmddhhmmss
  // const ymdhms2 = () => {
  //   var date = new Date();
  //   return (
  //     date.getFullYear() +
  //     ('0' + (date.getMonth() + 1)).slice(-2) +
  //     ('0' + date.getDate()).slice(-2) +
  //     ('0' + date.getHours()).slice(-2) +
  //     ('0' + date.getMinutes()).slice(-2) +
  //     ('0' + date.getSeconds()).slice(-2)
  //     // date.getMilliseconds()
  //   );
  // };

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date;
    if (tdate) {
      date = new Date(tdate);
    } else {
      date = new Date();
    }
    //console.log({ date });
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  // const userPlusHandler = async () => {
  //   try {
  //     for (let index = 0; index < 100; index++) {
  //       let body = {
  //         email: Date.now() + '@mail.com',
  //         name: '名' + index,
  //         lastname: '性' + index,
  //         role: 0,
  //         company: 'ABC商事',
  //         department: `営業${index}チーム`,
  //         password: 'testpw',
  //       };
  //       console.log({ body });
  //       const res = await Axios.post(config.USER_SERVER + '/register', body);
  //     }
  //   } catch (error) {
  //     alert('ユーザ追加に失敗しました。');
  //   }
  // };

  return (
    <>
      <div className="app">
        <br />
        <br />
        <br />
        <FiDatabase style={{ fontSize: '4rem' }} />
        <br />
        <span style={{ fontSize: '2rem' }}>リ・カレント</span>
        <span style={{ fontSize: '2rem' }}>サーベイシステム</span>
        <br />
        <br />
        {isAuth && (
          <Card style={{ width: 600, textAlign: 'center' }}>
            <Badge count={tokenCnt} />
            <a href="/mysurvey">
              <span style={{ fontSize: '1rem' }}>
                【{userInfo.lastname + ' ' + userInfo.name}
                】様に届いている参加可能なサーベイは{tokenCnt}件です。
              </span>
            </a>
          </Card>
        )}
        <br />
        <br />
        <br />
        <span style={{ fontSize: '1rem' }}>
          ＜＜深夜に定期メンテナンスを行います＞＞
        </span>
        <br />
        <span style={{ fontSize: '1rem' }}>・定期点検：毎日23:00~24:00</span>
        {/* <span style={{ fontSize: '1rem' }}>
          ・特別点検：2022.4.27 16:30 ~ 2022.4.28 24:00
        </span> */}
        <br />
        <span style={{ fontSize: '1rem' }}>
          ※点検中は不定期にサーバーダウン及びセッション切断の可能性があるので診断・アンケートは受けないでください。
        </span>

        {/* <Button type="primary" onClick={userPlusHandler}>
          ダミーユーザ+100
        </Button> */}
      </div>
    </>
  );
};

export default LandingPage;
