// console.log(process.env);
if (process.env.NODE_ENV === 'production') {
  //환경변수에 값을 준비
  module.exports = require('./prod');
  // console.log('prod.js読み込む！');
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
  module.exports = require('./stag');
  // console.log('stag.js読み込む！');
} else {
  module.exports = require('./dev');
}
