//9:管理者９が最高管理者
module.exports = {
  userRole: {
    0: '未設定',
    1: '受講者',
    2: 'リ・カレント管理者',
    3: '企業管理者(ALL)',
    4: '企業管理者(E3)',
    5: '企業管理者(360)',
    6: '企業管理者(E3+360)',
    7: 'その他',
    8: 'その他',
    9: '特権管理者',
  },
};
