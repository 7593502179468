//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import Axios from 'axios';

// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function ChatGptTestPage() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setAnswer('');
      message.loading({
        content: '回答作成中...',
        key: 'csvupload',
        duration: 100,
      });
      const ans = await Axios.post('/api/chatGpt', { question: question });
      if (ans) {
        // console.log(ans);
        setAnswer(ans.data.ans);
        message.loading({
          content: '回答完了',
          key: 'csvupload',
          duration: 3,
        });
      }
    } catch (error) {
      console.log({ error });
      message.error({
        content: '回答作成に失敗しました。',
        key: 'csvupload',
        duration: 2,
      });
    }
  };

  const onChangeQuestion = (e) => {
    if (e.target.value) {
      setQuestion(e.target.value);
      setSubmitButtonDisable(false);
    } else {
      setSubmitButtonDisable(true);
    }
    // console.log(e);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>Chat GPT テスト</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextArea
              rows={10}
              placeholder="質疑入力"
              onChange={onChangeQuestion}
              value={question}
            />
          </div>

          <Title>❷</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={submitButtonDisable}
          >
            質疑
          </Button>
        </Form>
        <TextArea rows={20} value={answer} />
      </div>
    </Fragment>
  );
}

export default ChatGptTestPage;
