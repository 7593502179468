import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import config from '../../Config/key';
import {
  Table,
  // Divider,
  // Tag,
  Button,
  message,
  Typography,
  Select,
  // Modal,
  Space,
  Badge,
  Modal,
  Spin,
} from 'antd';
// import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { userRole } from '../../Config/userRole';
import { useSelector } from 'react-redux';
// import { BsBoxArrowUpRight } from 'react-icons/bs';
// import { surveyCode } from '../../Config/surveyCode';
// import { surveyStatus } from '../../Config/surveyStatus';
const { confirm } = Modal;
const { Column, ColumnGroup } = Table;
const { Text } = Typography;
const { Option } = Select;
const survey_status_complete = 5;
let selSurveySeason = [];
let selSurveySeasonId = '';

function Status360Page(props) {
  const [tUsers, setTUsers] = useState([]); //グループフィルタリングされたユーザ一覧（全体）
  const [gUsers, setGUsers] = useState([]); //グループ・サーベイシーズンでフィルタリングされたユーザ一覧
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //グループ名とグループコード
  // const [surveySeason, setSurveySeason] = useState([]);
  // const [selSurveySeason, setSelSurveySeason] = useState([]);
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグルプコード
  const [survey, setSurvey] = useState([]); //サーベイ名とサーベイコード
  // const [questionSet, setQuestionSet] = useState([]); //全ての設問セット
  // const [selSurveyId, setSelSurveyId] = useState(''); //選択したサーベイID
  // const [role360, setRole360] = useState(1); //選択した360度関係：１：本人、２：上司、３：部下・同僚
  // const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択したサーベイID
  // const [selSurveySeasonIdFilter, setSelSurveySeasonIdFilter] = useState(''); //選択したサーベイID
  const [userInfo, setUserInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const usr = useSelector((state) => state.user);
  //9:管理者９が最高管理者
  // const userRole = [
  //   '停止中',
  //   '学習者',
  //   '管理者',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   'その他',
  //   '最高管理者',
  // ];

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };

  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log(usr.userData);
      setUserInfo(usr.userData);
      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/40010'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);

      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }

      // setGroups(groupInfo.data.groups);
      setSurvey(surveyInfo.data.survey);
      // setSurveySeason(seasonInfo.data.surveySeason);
      setGUsers([]);
      setTUsers([]);
      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      // if (!seasonInfo) throw 'サーベイシーズン取得に失敗しました。';
      if (
        usr.userData.role === 3 ||
        usr.userData.role === 5 ||
        usr.userData.role === 6
      ) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        // setGroupSurveySeasonInfo(
        //   sgi.survey_season.filter((item) => item.survey_code === '40010')
        // );
        filterDatasource(usr.userData.groupCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  //リストフィルタリング
  const filterDatasource = async (groupCode) => {
    // setGUsers(
    //   Users.filter(function (user) {
    //     return user.group_code === groupCode;
    //   })
    // );
    message.loading({
      content: '読み込み中...',
      key: 'updateable',
      duration: 20,
    });
    const res = await Axios.get(`${config.USER_SERVER}/group/${groupCode}`);
    // console.log({ res });
    let userData = res.data.users;
    userData.map((user) => {
      user.fullName = user.lastname + ' ' + user.name;
      user.roleText = userRole[user.role];
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.surveyProgress = 0;
      } else {
        user.takeTokenCnt = user.survey_token.length;
        user.usedTokenCnt = user.survey_token.filter(function (tokenInfo) {
          return tokenInfo.survey_status === 5; //実施済み：5
        }).length;
        user.surveyProgress = (user.usedTokenCnt / user.takeTokenCnt) * 100;
      }
      return user;
    });
    setGUsers([]); //SurveySeason選択するまでデータ表示をしない
    setTUsers(userData); //選択したグループの全員
    // console.log({ userData });
    message.success({
      content: '読み込み完了！',
      key: 'updateable',
      duration: 2,
    });
  };

  //グループを変更した場合
  const onChangeGroup = (value) => {
    // console.log(`selected ${value}`);
    setSelGroupCode(value);
    filterDatasource(value); //リストフィルタリング
    const selGroup = groups.find((item) => item.group_code === value);
    // console.log({ selGroup });
    setSelGroupInfo(selGroup);
    // setQuestionSet([]);
    selSurveySeason = [];
    // setSelSurveyId('');
    selSurveySeasonId = '';
    // setRole360('');
    // setSelSurveySeasonInfo([]);
  };

  //サーベイシーズンを変更した場合（絞り込み）
  //360度診断のみが選択される
  //valueはSurveySeasonのid
  // console.log(`selected ${value}`);
  const onChangeSeasonFilter = (seasonId) => {
    selSurveySeasonId = seasonId;
    selSurveySeason = selGroupInfo.survey_season.find(
      (item) => item._id === seasonId
    );
    // selSurveySeason = selSeasonInfo;
    const gUserArr = []; //本人たちの情報
    // console.log({ tUsers });
    selSurveySeason.relation_360.map((element) => {
      gUserArr.push(tUsers.find((item) => item._id === element.user_id)); //本人のみをFilter
    });
    // console.log({ gUserArr });
    setGUsers(computCnt(gUserArr, seasonId));
  };

  //本人たちの情報とシーズンIDを利用して「本人」行の情報を構成
  const computCnt = (gUserArr, seasonId) => {
    // let seasonTokenInfo = {};
    gUserArr.map((user) => {
      let doneCnt = 0;
      let totalCnt = 0;
      let heroCnt = 0;
      let bossCnt = 0;
      let partnerCnt = 0;
      if (user.survey_token.length === 0) {
        user.takeTokenCnt = 0;
        user.usedTokenCnt = 0;
        user.status = false;
      } else {
        // console.log(user.survey_token);
        // console.log({ seasonId });

        let sortedToken = user.survey_token.sort(function (a, b) {
          return a.updatedAt > b.updatedAt ? -1 : 1; //オブジェクトの昇順ソート
        });
        // console.log({ result });

        //選択したサーベイセッション＋自分が本人のトークン情報を１個探す
        let seasonTokenInfo = user.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === seasonId && tokenInfo.relation === 1
        );
        //選択してないサーベイシーズン＋自分が本人＋360サーベイ＋全員が受検済み+過去のサーベイであること+同じQuestionSet
        //前回との比較対象の「あり」・「なし」を決める
        let lastSeasonTokenInfo = sortedToken.find(
          (tokenInfo) =>
            tokenInfo.survey_status === 5 &&
            tokenInfo.relation === 1 &&
            tokenInfo.survey_code === '40010' &&
            tokenInfo.survey_season_id != seasonId &&
            ymd(tokenInfo.updatedAt) < ymd(seasonTokenInfo.updatedAt) &&
            tokenInfo.question_set_id === seasonTokenInfo.question_set_id
        );
        //残的的にトークンを選定
        //これから前回の回答が全員回答したのかを検証
        let lastSeason = false;
        if (lastSeasonTokenInfo) {
          const lastSurveySeason = selGroupInfo.survey_season.find(
            (item) => item._id === lastSeasonTokenInfo.survey_season_id
          );
          // console.log({ lastSurveySeason });
          // console.log({ lastSeasonTokenInfo });
          lastSeason = isLastSeasonComplete(user, lastSurveySeason);
        }

        if (seasonTokenInfo.survey_status === survey_status_complete) {
          user.status = true;
          user.testDate = ymdhms(seasonTokenInfo.updatedAt);
          totalCnt++;
          doneCnt++;
          heroCnt++;

          user.lastSeason = lastSeason;
          if (lastSeason) {
            user.lastSeasonTokenInfo = lastSeasonTokenInfo;
            user.lastSurveySeason = selGroupInfo.survey_season.find(
              (item) => item._id === lastSeasonTokenInfo.survey_season_id
            );
          }
        } else {
          user.status = false;
          user.testDate = '-';
          totalCnt++;
        }
        // user.status = seasonTokenInfo.survey_status === 5 > 0 ? true : false;
      }
      // const u = Object.assign({}, JSON.parse(JSON.stringify(user)));
      // console.log({ u });
      // const data = makeTeam(u); //本人1名の情報を送ってチーム情報を持ってくる
      // console.log(data);
      // const totlaCnt = data.length + 1;
      // const doneCnt =
      //   data.filter((item) => item.status === true).length +
      //   (user.status ? 1 : 0);
      // user.totalStatus = totlaCnt === doneCnt;
      // user.cnt = `${doneCnt} / ${totlaCnt}`;
      const team = selSurveySeason.relation_360.find(
        (item) => item.user_id === user._id
      );

      let seasonTokenInfo = {};
      // console.log({ team });
      for (let i = 0; i < team.boss.length; i++) {
        const bossId = team.boss[i];
        const bossInfo = tUsers.find((item) => item._id === bossId);
        if (bossInfo.survey_token.length === 0) {
          bossInfo.status = false;
          seasonTokenInfo = {};
        } else {
          seasonTokenInfo = bossInfo.survey_token.find(
            (tokenInfo) =>
              tokenInfo.survey_season_id === selSurveySeasonId &&
              tokenInfo.hero_id === user._id
          );
          // console.log({ seasonTokenInfo });
          if (seasonTokenInfo.survey_status === 5) {
            doneCnt++;
            totalCnt++;
            bossCnt++;
          } else {
            totalCnt++;
          }
        }
      }
      for (let i = 0; i < team.partner.length; i++) {
        const partnerId = team.partner[i];
        const partnerInfo = tUsers.find((item) => item._id === partnerId);
        if (partnerInfo.survey_token.length === 0) {
          partnerInfo.status = false;
          seasonTokenInfo = {};
        } else {
          seasonTokenInfo = partnerInfo.survey_token.find(
            (tokenInfo) =>
              tokenInfo.survey_season_id === selSurveySeasonId &&
              tokenInfo.hero_id === user._id
          );
          // console.log({ seasonTokenInfo });
          if (seasonTokenInfo.survey_status === 5) {
            doneCnt++;
            totalCnt++;
            partnerCnt++;
          } else {
            totalCnt++;
          }
        }
      }
      user.totalStatus = totalCnt === doneCnt;
      const cutOver =
        heroCnt && bossCnt && (partnerCnt > 1 ? true : false) ? '🌞' : '🌨️';
      user.cnt = `${doneCnt} / ${totalCnt} ： ${cutOver}`;
      return user;
    });
    // console.log(gUserArr);

    return gUserArr;
  };

  const isLastSeasonComplete = (user, lastSurveySeason) => {
    let doneCnt = 0;
    let totalCnt = 0;
    // let heroCnt = 1;
    let bossCnt = 0;
    let partnerCnt = 0;
    const team = lastSurveySeason.relation_360.find(
      (item) => item.user_id === user._id
    );

    let seasonTokenInfo = {};
    // console.log({ team });
    for (let i = 0; i < team.boss.length; i++) {
      const bossId = team.boss[i];
      const bossInfo = tUsers.find((item) => item._id === bossId);
      if (bossInfo.survey_token.length === 0) {
        bossInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = bossInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === lastSurveySeason._id &&
            tokenInfo.hero_id === user._id
        );
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          doneCnt++;
          totalCnt++;
          bossCnt++;
        } else {
          totalCnt++;
        }
      }
    }
    for (let i = 0; i < team.partner.length; i++) {
      const partnerId = team.partner[i];
      const partnerInfo = tUsers.find((item) => item._id === partnerId);
      if (partnerInfo.survey_token.length === 0) {
        partnerInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = partnerInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === lastSurveySeason._id &&
            tokenInfo.hero_id === user._id
        );
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          doneCnt++;
          totalCnt++;
          partnerCnt++;
        } else {
          totalCnt++;
        }
      }
    }
    // console.log({ doneCnt });
    // console.log({ totalCnt });
    return totalCnt === doneCnt && totalCnt > 0 ? true : false;
  };

  const makeTeam = (user) => {
    // console.log({ user });
    //チームの基本情報を獲得
    const team = selSurveySeason.relation_360.find(
      (item) => item.user_id === user._id
    );
    // console.log({ team });
    const data = [];
    let seasonTokenInfo = {};
    // console.log({ selSurveySeasonId });
    team.boss.map((bossId, i) => {
      const bossInfo = tUsers.find((item) => item._id === bossId);
      if (bossInfo.survey_token.length === 0) {
        bossInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = bossInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === selSurveySeasonId &&
            tokenInfo.hero_id === user._id
        );
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          bossInfo.status = true;
          bossInfo.testDate = ymdhms(seasonTokenInfo.updatedAt);
        } else {
          bossInfo.status = false;
          bossInfo.testDate = '-';
        }
      }
      // console.log({ bossInfo });
      data.push({
        key: i.toString(),
        userId: bossInfo._id,
        teamId: team._id,
        relation: '上司',
        relationName: 'boss',
        fullname: bossInfo.lastname + ' ' + bossInfo.name,
        email: bossInfo.email,
        department: bossInfo.department,
        status: bossInfo.status,
        testDate: bossInfo.testDate,
        tokenId: seasonTokenInfo._id,
      });
    });

    team.partner.map((partnerId, i) => {
      const partnerInfo = tUsers.find((item) => item._id === partnerId);
      if (partnerInfo.survey_token.length === 0) {
        partnerInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = partnerInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === selSurveySeasonId &&
            tokenInfo.hero_id === user._id
        );
        if (seasonTokenInfo.survey_status === 5) {
          partnerInfo.status = true;
          partnerInfo.testDate = ymdhms(seasonTokenInfo.updatedAt);
        } else {
          partnerInfo.status = false;
          partnerInfo.testDate = '-';
        }
      }
      data.push({
        key: (i + 10).toString(),
        userId: partnerInfo._id,
        teamId: team._id,
        relation: '部下・同僚',
        relationName: 'partner',
        fullname: partnerInfo.lastname + ' ' + partnerInfo.name,
        email: partnerInfo.email,
        department: partnerInfo.department,
        status: partnerInfo.status,
        testDate: partnerInfo.testDate,
        tokenId: seasonTokenInfo._id,
      });
    });

    return data;
  };

  const expandedRowRender = (user) => {
    //サブテーブルのタグを返却する関数
    if (selSurveySeason.length === 0) return null;

    let columns = [
      {
        title: '関係',
        dataIndex: 'relation',
        key: 'relation',
      },
      {
        title: '名前',
        dataIndex: 'fullname',
        key: 'fullname',
      },
      {
        title: 'メール',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: '部署',
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: '回答状態',
        key: 'status',
        render: (record) =>
          record.status ? (
            <span>
              <Badge status="success" />
              回答済
            </span>
          ) : (
            <span>
              <Badge status="error" />
              未回答
            </span>
          ),
      },
      {
        title: '回答日',
        dataIndex: 'testDate',
        key: 'testDate',
      },
    ];
    if (userInfo.role === 9) {
      columns.push({
        title: 'メンバー調整',
        key: 'key',
        render: (record) => (
          <span>
            <Button
              type="primary"
              size="small"
              disabled={record.status}
              onClick={(e) => showConfirm(record, e)}
            >
              メンバーから外す
            </Button>
          </span>
        ),
      });
    }
    const data = makeTeam(user);

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        rowClassName={(record) => (record.status ? 'sub360-ok' : 'sub360')}
      />
    );
  };

  // //チケット削除
  // const surveyResultHandler = async (tokenInfo, e) => {
  //   // console.log(tokenInfo);

  //   try {
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const showConfirm = async (userInfo, e) => {
    // console.log(userInfo);
    confirm({
      title: 'メンバーを外します',
      content:
        '360診断メンバーを外しますと発行済みの診断チケットも一緒にキャンセルされます',
      async onOk() {
        const result = await memberDeleteHandler(userInfo, e);
        if (result) {
          message.success({
            content: 'メンバーを外しました。ホームに戻ります。',
            key: 'member',
            duration: 2,
          });
          setTimeout(() => {
            props.history.push('/');
          }, 1000);
        } else {
          message.error({
            content: 'メンバー調整に失敗しました。',
            key: 'member',
            duration: 2,
          });
          throw new Error('メンバー調整に失敗しました。');
        }
      },
      async onCancel() {
        message.success({
          content: 'メンバー調整を取り消しました。',
          key: 'member',
          duration: 2,
        });
      },
    });
  };
  //チケット削除
  const memberDeleteHandler = async (userInfo, e) => {
    // console.log(userInfo);

    try {
      const info = {
        teamId: userInfo.teamId,
        userId: userInfo.userId,
        relationName: userInfo.relationName,
        seasonId: selSurveySeasonId,
        groupCode: selGroupCode,
        tokenId: userInfo.tokenId,
      };
      // console.log({ info });
      const res = await Axios.patch('/api/relation360/delmember', info);
      if (res.data.success) {
        // console.log(res.data);
        return res;
      } else {
        message.error({
          content: 'メンバー削除に失敗しました。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('メンバー削除に失敗しました。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showConfirm2 = async (userInfo, type) => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        // console.log({ userInfo });
        let tokenI = userInfo.survey_token.find(
          (item) =>
            item.relation === 1 &&
            item.survey_season_id === selSurveySeasonId &&
            item.survey_code === '40010'
        );
        tokenI.type = type;
        tokenI.group_name = selGroupInfo.group_name;
        tokenI.training_start_date = selSurveySeason.training_start_date;
        tokenI.training_end_date = selSurveySeason.training_end_date;
        tokenI.survey_start_date = selSurveySeason.survey_start_date;
        tokenI.survey_end_date = selSurveySeason.survey_end_date;
        tokenI.user_name = `${userInfo.lastname} ${userInfo.name}`;
        if (userInfo.lastSeason) {
          tokenI.lastSeasonTokenInfo = userInfo.lastSeasonTokenInfo;
          tokenI.lastSurveySeason = userInfo.lastSurveySeason;
        }
        let dep = '';
        if (userInfo.department) dep = userInfo.department;
        if (userInfo.department2) dep = dep + ' ' + userInfo.department2;
        if (userInfo.department3) dep = dep + ' ' + userInfo.department3;
        if (userInfo.department4) dep = dep + ' ' + userInfo.department4;
        if (userInfo.department5) dep = dep + ' ' + userInfo.department5;
        tokenI.department = dep;
        tokenI.tName = selSurveySeason.t_name;
        tokenI.name_360 = selSurveySeason.name_360;
        tokenI.relationInfo = selSurveySeason.relation_360.find(
          (item) => item.user_id === userInfo._id
        );
        tokenI.type_360 = selSurveySeason.question_set[0].type_360;
        // console.log({ tokenI });
        const result = await Axios.post(
          '/api/dbModel100/makeReport40010',
          tokenI
        );
        // console.log({ result });
        if (result.data.success) {
          // if (0) {
          // message.success('結果生成が完了しました。マイ・サーベイに戻ります。');
          // props.history.push('/mysurvey');
          // const url = `${config.S3_URL}/`;
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Text>■絞り込み｜</Text>
          {(userInfo.role === 9 || userInfo.role === 2) && (
            <>
              <Text>企業選択</Text>
              <Select
                showSearch
                style={{
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  width: 200,
                }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeGroup}
                value={selGroupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={
                  userInfo.role === 3 ||
                  userInfo.role === 5 ||
                  userInfo.role === 6
                }
              >
                {groups.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.group_code} key={element._id}>
                      {element.group_name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
          {selGroupInfo.survey_season && (
            <Fragment>
              サーベイシーズン選択:
              <Select
                showSearch
                style={{
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  width: 400,
                }}
                placeholder="シーズン選択"
                optionFilterProp="items"
                onChange={onChangeSeasonFilter}
                value={selSurveySeasonId}
                defaultValue={selGroupInfo.survey_season[0].season_name}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {selGroupInfo.survey_season.map((element) => {
                  if (element.survey_code === '40010') {
                    //360診断のみシーズン選択可能
                    return (
                      <Option value={element._id} key={element._id}>
                        {element.season_name}
                      </Option>
                    );
                  }
                })}
              </Select>
            </Fragment>
          )}
          <br />
        </div>
        <Table
          dataSource={gUsers}
          size="middle"
          rowKey="_id"
          rowClassName={(record) =>
            record.totalStatus ? 'main360-ok' : 'main360'
          }
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ['0'],
          }}
          bordered
        >
          <Column
            title="名前"
            dataIndex="fullName"
            key="fullName"
            aligh="right"
          />
          <Column title="メール" dataIndex="email" key="email" aligh="center" />
          <Column
            title="会社"
            dataIndex="company"
            key="company"
            aligh="center"
          />
          <Column
            title="部署"
            dataIndex="department"
            key="department"
            aligh="center"
          />
          <Column
            title="回答状態"
            key="status"
            render={(record) =>
              record.status ? (
                <span>
                  <Badge status="success" />
                  回答済
                </span>
              ) : (
                <span>
                  <Badge status="error" />
                  未回答
                </span>
              )
            }
          />
          <Column
            title="回答日"
            dataIndex="testDate"
            key="testDate"
            aligh="center"
          />
          <Column
            title="回答済 / 全体 ： 基準"
            dataIndex="cnt"
            key="cnt"
            aligh="center"
          />
          <Column
            title="診断結果ダウンロード"
            key="result"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => showConfirm2(record, 1)}
                    disabled={!record.totalStatus}
                  >
                    個人用
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => showConfirm2(record, 2)}
                    disabled={!record.totalStatus}
                  >
                    共有用
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => showConfirm2(record, 3)}
                    disabled={!record.totalStatus || !record.lastSeason}
                  >
                    比較表
                  </Button>
                </Space>
              </span>
            )}
          />
        </Table>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
}

export default Status360Page;
