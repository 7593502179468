//ユーザーCSVアップロードページ
import React, { Fragment, useState, useRef, useEffect } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Tag,
  theme,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import Axios from 'axios';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function VideoEditPage(props) {
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [videoCategory, setVideoCategory] = useState([]); //グループ名とグループコード
  const [instructor, setInstructor] = useState([]); //グループにあるサーベイシーズン
  const [value, setValue] = useState('');
  const { user } = props;
  const vdInfo = props.location.state;

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        // const [vc, inst] = await Promise.all([
        //   Axios.get('/api/videoCategory/'),
        //   Axios.get('/api/instructor/'),
        // ]);
        const vc = await Axios.get('/api/videoCategory/');
        const inst = await Axios.get('/api/instructor/');
        // console.log(vc.data.videoCategory);
        // console.log(inst.data.instructor);
        if (vc.data.success && inst.data.success) {
          setVideoCategory(vc.data.videoCategory);
          setInstructor(inst.data.instructor);
        } else {
          throw new Error('ビデオカテゴリ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (!user.userData) return null;
    readDB();
    // console.log({ vdInfo });
    setValue({
      videoName: vdInfo.title,
      videoCategory: vdInfo.category_code_id._id,
      duration_h: vdInfo.duration_h,
      duration_m: vdInfo.duration_m,
      duration_s: vdInfo.duration_s,
      instructor: vdInfo.instructor._id,
      videoExplanation: vdInfo.video_explanation,
      videoDescription: vdInfo.video_description,
      thumbnail: vdInfo.thumbnail[0],
    });
    setTags(vdInfo.tag);

    if (inputVisible) {
      inputRef.current.focus();
    }
  }, [user]);

  const onSubmit = async (event) => {
    event.preventDefault();
    // console.log(event);
    // let userData = []; //最終分類用
    try {
      if (!value.instructor || !value.videoName || !value.videoCategory)
        throw new Error('必須項目が入力されていません');
      message.loading({
        content: '動画情報保存中...',
        key: 'csvupload',
        duration: 20,
      });
      let sendData = [];
      sendData = {
        title: value.videoName,
        tag: tags,
        category_code_id: value.videoCategory,
        // category_code: videoCategory.find(
        //   (item) => item._id === value.videoCategory
        // ).category_code,
        video_description: value.videoDescription,
        video_explanation: value.videoExplanation,
        instructor: value.instructor,
        duration_h: value.duration_h,
        duration_m: value.duration_m,
        duration_s: value.duration_s,
      };
      // console.log({ thumbnailList });
      // setModalVisible(true);
      // const res = await vimeoUpload(sendData);
      const res = await Axios.patch(`/api/video/${vdInfo._id}`, sendData);

      if (res.data.success) {
        message.success({
          content: '動画情報を保存しました。',
          key: 'csvupload',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/videomgr');
        }, 1000);
      } else {
        throw new Error('動画アップロードに失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      message.error({
        content: error.message,
        key: 'csvupload',
        duration: 3,
      });
    }
  };

  const onChangeVideoName = (e) => {
    setValue({ ...value, videoName: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeDuration_h = (e) => {
    setValue({ ...value, duration_h: e.currentTarget.value });
  };
  const onChangeDuration_m = (e) => {
    setValue({ ...value, duration_m: e.currentTarget.value });
  };
  const onChangeDuration_s = (e) => {
    setValue({ ...value, duration_s: e.currentTarget.value });
  };
  const onChangeExpl = (e) => {
    setValue({ ...value, videoExplanation: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeDesc = (e) => {
    setValue({ ...value, videoDescription: e.currentTarget.value });
    // console.log(value);
  };

  const onChangeVideoCategory = (v) => {
    setValue({ ...value, videoCategory: v });
  };

  const onChangeInstructor = (v) => {
    setValue({ ...value, instructor: v });
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    // console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    // console.log(e.target.value);
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags.map(forMap);
  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>動画情報編集</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />

          <div>
            <br />
            <Text strong>■ 動画名（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
            <Input
              placeholder="動画名（必須）"
              name="mt_name"
              allowClear
              onChange={onChangeVideoName}
              value={value['videoName']}
            />
          </div>
          <div>
            <br />
            <Text strong>■ サムネール</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <img
                style={{ width: '320px' }}
                alt="サムネール"
                border={'2'}
                src={value['thumbnail']}
              />
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 再生時間（必須）</Text>
            <br />
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="時間"
              name="duration_h"
              allowClear
              onChange={onChangeDuration_h}
              value={value['duration_h']}
            />
            時間　
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="分"
              name="duration_m"
              allowClear
              onChange={onChangeDuration_m}
              value={value['duration_m']}
            />
            分　
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="秒"
              name="duration_m"
              allowClear
              onChange={onChangeDuration_s}
              value={value['duration_s']}
            />
            秒
          </div>
          <div>
            <br />
            <Text strong>■ 動画カテゴリ選択（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="動画カテゴリ選択"
                optionFilterProp="items"
                onChange={onChangeVideoCategory}
                value={value['videoCategory']}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {videoCategory.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.category_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 講師選択（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="講師選択"
                optionFilterProp="items"
                onChange={onChangeInstructor}
                value={value['instructor']}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {instructor.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 動画タイトル：ユーザ向け（表示用）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextArea
                rows={3}
                onChange={onChangeExpl}
                value={value['videoExplanation']}
              />
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 動画説明：管理向け</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextArea
                rows={3}
                onChange={onChangeDesc}
                value={value['videoDescription']}
              />
            </div>
          </div>

          <div>
            <br />
            <Text strong>■ Tag記入</Text>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
            <div
              style={{
                marginBottom: 16,
              }}
            >
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                }}
                onEnd={(e) => {
                  if (e.type === 'appear' || e.type === 'enter') {
                    e.target.style = 'display: inline-block';
                  }
                }}
                leave={{
                  opacity: 0,
                  width: 0,
                  scale: 0,
                  duration: 200,
                }}
                appear={false}
              >
                {tagChild}
              </TweenOneGroup>
            </div>
            <div>
              {inputVisible ? (
                <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  style={{
                    width: 78,
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag onClick={showInput} style={tagPlusStyle}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </div>
          </div>

          <Button
            type="primary"
            onClick={onSubmit}
            disabled={
              value['videoName'] === '' || value['videoCategory'] === ''
            }
            size="large"
            style={{
              marginTop: 16,
            }}
          >
            動画情報保存
          </Button>
        </Form>
      </div>
    </Fragment>
  );
}

export default VideoEditPage;
