import React, { useState, useEffect, Fragment, useContext } from 'react';
import { UserContext } from '../TakeSurveyPage';
import { Card, Typography, Radio } from 'antd';
import Axios from 'axios';

const { Title, Text } = Typography;

function ContentsPage2(props) {
  //   const question = [];
  const [question, setQuestion] = useState([]);
  const { answer, setAnswer } = useContext(UserContext);
  const { surveyCode, section } = props;
  // console.log(props);

  useEffect(() => {
    const readDB = async () => {
      const res = await Axios.get(`/api/question/${surveyCode}/${section}`);
      setQuestion(res.data.question);
    };

    readDB();
    // console.log(question);
    return () => {};
  }, [props]);
  //const [answer, setAnswer] = useState({});
  //   const [answer2, setAnswer2] = useState();
  const onChange = (e) => {
    e.preventDefault();
    // ans[e.target.name] = e.target.value;
    setAnswer({ ...answer, [e.target.name]: e.target.value });
    // console.log(e);
    // console.log(question);
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <Card
        title="人事評価力診断"
        style={{
          margin: '0px',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        headStyle={{
          color: 'white',
          backgroundColor: 'rgba(83, 131, 236, 1)',
          border: 1,
        }}
      >
        <Title level={4}>{props.title}にお答えください。</Title>
        <Text>
          こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
          <br />
          {props.choice}でお答えください。
        </Text>
      </Card>

      {question.map((element, index) => {
        return (
          <Fragment key={index}>
            <br />
            <Card
              style={
                answer[element.no] === 1 ||
                answer[element.no] === 2 ||
                answer[element.no] === 3
                  ? {
                      margin: '0px',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      backgroundColor: 'rgba(216, 216, 203, 0.3)',
                    }
                  : {
                      margin: '0px',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      backgroundColor: 'white',
                    }
              }
            >
              {element.no}. {element.question}
              <br />
              <br />
              <Radio.Group
                name={element.no.toString()}
                onChange={onChange}
                value={answer[element.no]}
              >
                <Radio value={1}>その通りである</Radio>
                <Radio value={2}>その通りではない</Radio>
                {element.choice === 3 && (
                  <Radio value={3}>どちらともいえない</Radio>
                )}
              </Radio.Group>
            </Card>
          </Fragment>
        );
      })}
    </div>
  );
}

export default ContentsPage2;
