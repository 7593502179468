//9:管理者９が最高管理者
module.exports = {
  surveyStatus: {
    0: '実施前',
    1: '実施中',
    5: '実施済み',
    7: '期限切れ',
    9: '実施無効',
  },
};
