import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Popover,
} from 'antd';
import Axios from 'axios';
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const MakeGroupPage = (props) => {
  const [value, setValue] = useState('');
  const [mode, setMode] = useState('new');
  const gInfo = props.location.state;
  const { user } = props;

  useEffect(() => {
    if (!user.userData) return null;
    // console.log({ user });
    if (gInfo.body) {
      setMode('edit');
      setValue({
        group_code: gInfo.group_code,
        group_name: gInfo.group_name,
        group_description: gInfo.group_description,
      });
    }
  }, [user]);

  const onChangeGroupCode = (e) => {
    setValue({ ...value, group_code: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeGroupName = (e) => {
    setValue({ ...value, group_name: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeGroupDescription = (e) => {
    setValue({ ...value, group_description: e.currentTarget.value });
    // console.log(value);
  };

  const onSubmit = async () => {
    try {
      let res = {};
      // console.log({ mode });
      if (mode === 'edit') {
        res = await Axios.put('/api/group', value);
      } else {
        res = await Axios.post('/api/group', value);
      }
      // console.log({ res });
      if (res.data.success) {
        message.success({
          content: 'グループ登録完了！',
          key: 'submit',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/grouplist');
        }, 1000);
      } else {
        throw new Error('グループ登録に失敗しました。');
      }
    } catch (error) {
      message.error({
        content: 'グループ登録に失敗しました。',
        key: 'submit',
        duration: 2,
      });
      console.log({ error });
    }
  };

  const onCancel = async () => {
    props.history.push('/grouplist');
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '600px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>グループ作成</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Text strong>■ グループコード：英数大文字（必須）</Text>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
          ></div>
          <Input
            placeholder="グループコード（必須）"
            name="group_code"
            allowClear
            onChange={onChangeGroupCode}
            value={value['group_code']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ グループ名：社名（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="グループ名（社名）"
            name="group_name"
            allowClear
            onChange={onChangeGroupName}
            value={value['group_name']}
          />
          <br />
          <br />
          <div>
            <Text strong>■ グループ説明</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
          </div>
          <Input
            placeholder="グループ説明"
            name="group_description"
            allowClear
            onChange={onChangeGroupDescription}
            value={value['group_description']}
          />
          <br />
          <br />
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={false}
          >
            保存
          </Button>
          　
          <Button
            type="primary"
            size="large"
            onClick={onCancel}
            disabled={false}
          >
            キャンセル
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default MakeGroupPage;
